import React from 'react';
import MuiTextField from '@mui/material/TextField';
import NumberFormat, { NumberFormatProps, NumberFormatValues } from 'react-number-format';
import { useController, UseControllerProps } from 'react-hook-form';
import { InputBaseComponentProps } from '@mui/material/InputBase/InputBase';
import { testing } from '@workflow-nx/utils';

type NumberFormatCustomProps = NumberFormatProps & {
  decimalPlaces?: number;
  inputRef?: any;
};

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(
  props: NumberFormatCustomProps,
  ref: React.Ref<any>,
) {
  return (
    <NumberFormat
      {...props}
      getInputRef={ref}
      onValueChange={(values) => {
        props?.name &&
          props.onChange &&
          props.onChange({
            // @ts-ignore
            target: {
              name: props.name,
              value: values.value,
            },
          });
      }}
    />
  );
});

interface NumberTextFieldRHFProps {
  name: string;
  label?: string;
  decimalPlaces?: number;
  fullWidth?: boolean;
  disabled?: boolean;
  variant?: 'standard' | 'filled' | 'outlined' | undefined;
  isAllowed?: (values: NumberFormatValues) => boolean;
  allowNegative?: boolean;
  shrink?: boolean;
  suffix?: string;
  control: UseControllerProps['control'];
}

export function NumberTextFieldRHF({
  decimalPlaces = 0,
  disabled,
  fullWidth = true,
  label,
  name,
  variant,
  isAllowed,
  allowNegative,
  shrink,
  suffix,
  control,
}: NumberTextFieldRHFProps) {
  const {
    field: { value, onBlur, onChange, ref },
    fieldState: { isTouched, error },
  } = useController({
    name,
    control,
  } as UseControllerProps);

  return (
    <MuiTextField
      error={Boolean(isTouched && error)}
      data-test-id={testing.toKebabCase(label ?? name) + '-number-field'}
      fullWidth={fullWidth}
      label={label}
      name={name}
      type="text"
      onBlur={onBlur}
      onChange={(e) => {
        onChange(e);
      }}
      value={value}
      variant={variant ?? 'outlined'}
      disabled={disabled}
      size={'small'}
      InputLabelProps={{ shrink }}
      inputProps={{
        isAllowed,
        suffix,
        decimalPlaces,
        allowNegative,
      }}
      InputProps={{
        inputComponent: NumberFormatCustom as React.ElementType<InputBaseComponentProps>,
        inputRef: ref,
      }}
    />
  );
}

import React, { useCallback, useEffect, useState } from 'react';
import CustomDialog from './CustomDialog';
import { useMutation } from '@apollo/client';
import { CREATE_ASSET_DOWNLOAD_URL } from '../gql';
import { IAsset } from '@workflow-nx/common';
import { Box, LinearProgress, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Img } from 'react-image';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: 5,
    '& img': {
      width: '100%',
      height: 400,
      background: 'transparent',
      objectFit: 'contain',
    },
  },
}));

export function Tem13ImageViewDialog(props: {
  open: boolean;
  asset?: IAsset;
  inputAssetFile?: File | null;
  onClose: () => void;
}) {
  const styles = useStyles();
  const [createAssetDownloadUrl] = useMutation(CREATE_ASSET_DOWNLOAD_URL);
  const [loading, setLoading] = useState<boolean>(false);
  const [assetUrl, setAssetUrl] = useState<string>();

  const handleLoad = useCallback(
    async (asset: IAsset) => {
      try {
        setLoading(true);

        const { data } = await createAssetDownloadUrl({
          variables: {
            assetId: asset.assetId,
          },
        });

        const signedUrl = data.createAssetDownloadUrl.signedUrl;
        setAssetUrl(signedUrl);
      } finally {
        setLoading(false);
      }
    },
    [createAssetDownloadUrl],
  );

  const getInputAssetImageUrl = useCallback((inputAssetFile: File) => {
    try {
      setLoading(true);
      if (inputAssetFile) {
        const inputAssetFileUrl = URL.createObjectURL(inputAssetFile);
        setAssetUrl(inputAssetFileUrl);
      }
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (props.open && props?.inputAssetFile) {
      getInputAssetImageUrl(props.inputAssetFile);
    } else if (props.open && props.asset) {
      handleLoad(props.asset);
    }
  }, [props.asset, props.open, handleLoad, getInputAssetImageUrl, props?.inputAssetFile]);

  return (
    <CustomDialog
      maxWidth={'sm'}
      open={props.open}
      title={`Viewing Asset ${props?.asset?.assetType}`}
      onClose={() => {
        props.onClose();
      }}
      positiveActionButtons={[]}
    >
      {props.open && assetUrl ? (
        <>
          <Box
            className={styles.root}
            display={loading ? 'none' : 'flex'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <Img src={assetUrl} key={assetUrl} />
          </Box>
          {loading ? (
            <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={400}>
              <Box width={'100%'} textAlign={'center'}>
                <Typography variant={'body1'}>
                  Loading {1} of {1}
                </Typography>
                <LinearProgress variant={'indeterminate'} />
              </Box>
            </Box>
          ) : null}
        </>
      ) : null}
    </CustomDialog>
  );
}

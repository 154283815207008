import React from 'react';
import { Table, TableBody, TableHead, TableRow } from '@mui/material';
import { CaseReportCorrectionPlanRuleType } from './Tem13FillDialog.reducer';
import { TableHeadCell } from '../../../../../components/TableHeadCell';
import { CaseReportCorrectionPlanRuleTableRow } from './CaseReportCorrectionPlanRuleTableRow';

interface CaseReportCorrectionPlanRulesTableProps {
  caseReportCorrectionPlanRules: CaseReportCorrectionPlanRuleType[];
  disabled: boolean;
}

export function CaseReportCorrectionPlanRuleEditTable({
  caseReportCorrectionPlanRules,
  disabled,
}: CaseReportCorrectionPlanRulesTableProps) {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableHeadCell width={300}>Rule</TableHeadCell>
          <TableHeadCell>Plan Value</TableHeadCell>
          <TableHeadCell>&lt;&nbsp;Preop</TableHeadCell>
          <TableHeadCell>&lt;&nbsp;Min</TableHeadCell>
          <TableHeadCell>&gt;&nbsp;Max</TableHeadCell>
          <TableHeadCell>Flagged</TableHeadCell>
          <TableHeadCell width={350}>Comments</TableHeadCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {caseReportCorrectionPlanRules?.map(
          (
            caseReportCorrectionPlanRule: CaseReportCorrectionPlanRuleType,
            caseReportCorrectionPlanRuleIndex: number,
          ) => {
            return (
              <CaseReportCorrectionPlanRuleTableRow
                key={`${caseReportCorrectionPlanRuleIndex}`}
                correctionPlanRuleType={caseReportCorrectionPlanRule.ruleType}
                planValue={caseReportCorrectionPlanRule?.planValue}
                level={caseReportCorrectionPlanRule?.level}
                condition={caseReportCorrectionPlanRule?.condition}
                preOp={caseReportCorrectionPlanRule?.preOp}
                min={caseReportCorrectionPlanRule?.min}
                max={caseReportCorrectionPlanRule?.max}
                flagged={caseReportCorrectionPlanRule?.flagged}
                correctionPlanRuleIndex={caseReportCorrectionPlanRuleIndex}
                disabled={disabled}
              />
            );
          },
        )}
      </TableBody>
    </Table>
  );
}

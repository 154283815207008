import {
  IUser,
  ICase,
  IAsset,
  IPlan,
  Permission,
  UserRoleType,
  CaseReportStatusType,
  Tem13Error,
  AssetType,
  caseUtils,
  MeasurementsVersionType,
  format,
  CaseRiskAssessmentType,
  MedicalReviewStatusType,
  LordosisDifferenceRationaleType,
} from '@workflow-nx/common';
import { useSnackbar } from 'notistack';
import React, { useEffect, useReducer, useState, useCallback } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  FIND_CASE_REPORT,
  FIND_USERS,
  UPSERT_CASE_REPORT,
  UPDATE_PATIENT_RECORD,
  DELETE_ASSET,
  CREATE_ASSET_DOWNLOAD_URL,
  SEND_MEDICAL_REVIEW_REQUEST,
  OVERRIDE_MEDICAL_REVIEW,
  SEND_CASE_REPORT_REVIEW_REQUEST,
  APPROVE_MEDICAL_REVIEW,
  REJECT_MEDICAL_REVIEW,
  APPROVE_CASE_REPORT_REVIEW,
  REJECT_CASE_REPORT_REVIEW,
} from '../../../../../gql';

import { Resolver, SubmitHandler, useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@mui/material';
import CustomDialog from '../../../../../components/CustomDialog';
import { ProgressButton } from '@workflow-nx/ui';
import { useConfirm } from 'material-ui-confirm';
import useAuth from '../../../../../hooks/useAuth';
import * as Yup from 'yup';
import * as tem13Utils from '../../../../../utils/tem13';
import * as FileSaver from 'file-saver';
import { Tem13FillForm } from './Tem13FillForm';
import { file, tem13Pdf } from '@workflow-nx/utils';
import useCreateAndUploadAsset from '../../../../../hooks/useCreateAndUploadAsset';
import { Alert } from '@mui/material';
import { DateTime } from 'luxon';
import _ from 'lodash';

import {
  Tem13FillDialogReducer,
  Tem13FillDialogStateType,
  CasePathologyType,
  CaseReportCorrectionPlanRuleType,
  EncodedAssetLevelType,
  CaseEncodedToBase64ImageType,
  SubmitHandlerWithAction,
  FormActionType,
} from './Tem13FillDialog.reducer';
import { MedicalReviewForm } from './MedicalReviewForm';
import { CaseReportReviewForm } from './CaseReportReviewForm';

const convertNullOrNumberString = (val: any, defaultValue?: null | undefined) => {
  if (_.isNull(val)) return null;

  return val ? Number(val) : defaultValue;
};

export function Tem13FillDialog({
  surgeonUser,
  activeCase,
  plan,
  onClose,
  open,
}: {
  surgeonUser: IUser;
  activeCase: ICase;
  plan?: IPlan;
  onClose: (shouldUpdate: boolean) => void;
  open: boolean;
}) {
  const { hasRole, hasPermission, user: currentUser } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();
  const [medicalReviewLock, setMedicalReviewLock] = useState(false);
  const [medicalReviewLockExpired, setMedicalReviewLockExpired] = useState(false);
  const [upsertCaseReport] = useMutation(UPSERT_CASE_REPORT);
  const [updatePatientRecord] = useMutation(UPDATE_PATIENT_RECORD);
  const [sendMedicalReviewRequest] = useMutation(SEND_MEDICAL_REVIEW_REQUEST);
  const [overrideMedicalReview] = useMutation(OVERRIDE_MEDICAL_REVIEW);
  const [sendCaseReportReviewRequest] = useMutation(SEND_CASE_REPORT_REVIEW_REQUEST);
  const [approveMedicalReview] = useMutation(APPROVE_MEDICAL_REVIEW);
  const [rejectMedicalReview] = useMutation(REJECT_MEDICAL_REVIEW);
  const [approveCaseReportReview] = useMutation(APPROVE_CASE_REPORT_REVIEW);
  const [rejectCaseReportReview] = useMutation(REJECT_CASE_REPORT_REVIEW);
  const [createAssetDownloadUrl] = useMutation(CREATE_ASSET_DOWNLOAD_URL);
  const { createAndUploadAsset } = useCreateAndUploadAsset();
  const [deleteAsset] = useMutation(DELETE_ASSET);
  const [tem13Users, setTem13Users] = useState<IUser[]>([]);
  const [medicalReviewUser, setMedicalReviewUser] = useState<IUser>();
  const [uploadingImage, setUploadingImage] = useState(false);
  const [activeButton, setActiveButton] = useState<FormActionType | null>(null);

  const validCaseLevels = caseUtils.getValidCaseLevelsWithPartTypes(
    activeCase.levels,
  ) as tem13Utils.ILevelsHeaderData[];

  const [findCaseReport, { loading }] = useLazyQuery(FIND_CASE_REPORT, {
    variables: {
      caseId: activeCase?.caseId,
      patientId: activeCase?.patient?.patientId,
      planId: plan?.planId,
      userId: surgeonUser?.userId,
      assetTypeFilter: tem13Utils.getAllTem13AssetTypes(validCaseLevels),
      deletedAt: null,
      measurementsVersion: MeasurementsVersionType.Version2,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      dispatch({ type: 'INIT', data });
    },
  });

  useQuery(FIND_USERS, {
    fetchPolicy: 'network-only',
    variables: {
      roleFilter: [UserRoleType.CaseEngineer, UserRoleType.CaseAdmin, UserRoleType.Clinical],
    },
    onCompleted: (data) => {
      const users = data?.users?.users ?? [];
      setTem13Users(users);

      const foundMedicalReviewUser = users.find(
        (user: IUser) =>
          user.firstName === 'Rodrigo' &&
          user.lastName === 'Nicolau' &&
          [UserRoleType.Clinical].includes(user.role),
      );
      if (foundMedicalReviewUser) {
        setMedicalReviewUser(foundMedicalReviewUser);
      }
    },
  });

  const [state, dispatch] = useReducer(Tem13FillDialogReducer(activeCase, plan), {
    tem13CaseData: undefined,
    isStateReady: false,
    caseReportVertebraePreEditImage: undefined,
    caseReportVertebraePostEditImage: undefined,
    caseReportImplantPreEditImage: undefined,
    caseReportImplantPostEditImage: undefined,
    caseReportStandingXrayLateralMeasured: undefined,
    assets: [],
    caseReportId: 0,
    caseId: activeCase?.caseId,
    status: CaseReportStatusType.InProgress,
    isComplete: false,
    riskAssessment: undefined,
    correctionGoalSagittal: undefined,
    correctionGoalSagittalOther: undefined,
    correctionGoalCoronal: undefined,
    correctionGoalCoronalOther: undefined,
    correctionGoalDiscHeightOnly: false,
    correctionGoalNote: undefined,
    segmentationAssetId: undefined,
    segmentationSliceThickness: undefined,
    isSegmentationPassed: true,
    segmentationImagingAssessment: undefined,
    segmentationImageDate: undefined,
    segmentedByQualifiedOperator: false,
    segmentationPerformedByUser: undefined,
    segmentationPerformedBy: undefined,
    segmentationReviewedByUser: undefined,
    segmentationReviewedBy: undefined,
    segmentationType: undefined,
    reportReviewRequestedAt: undefined,
    reportReviewedByUser: undefined,
    reportReviewedBy: '',
    reportReviewedAt: undefined,
    reportReviewNote: undefined,
    measurementLordosisDifference: undefined,
    measurementLordosisDifferenceRationale: [],
    measurementLordosisDifferenceRationaleNote: undefined,
    measurementNote: undefined,
    medicalReviewRequestedAt: undefined,
    medicalReviewStatus: undefined,
    medicalReviewedAt: undefined,
    medicalReviewedByUser: undefined,
    medicalReviewedBy: undefined,
    medicalReviewNote: undefined,
    planFeedback: [],
    planFeedbackOther: undefined,
    caseReportStatements: [],
    casePathologies: [],
    caseReportCorrectionPlanRules: [],
    patientRecord: {
      patientId: activeCase?.patient?.patientId ?? undefined,
      pelvicIncidence: null,
      lumbarLordosis: undefined,
      lumbarCoronalCobb: undefined,
      sagittalVerticalAxis: undefined,
      l4S1LordoticDistribution: undefined,
      globalTilt: undefined,
      pelvicTilt: undefined,
      sacralSlope: undefined,
      c7SagittalVerticalLine: undefined,
    },
    levelAssets: [],
    deletedAssetIds: [],
    preOp: undefined,
    plan: undefined,
    preferences: undefined,
    caseReportAlignmentGoalTypes: [],
    proposedLumbarMeasurements: undefined,
    caseEncodedToBase64Images: undefined,
  });

  const caseReportStatementSchema = Yup.object().shape({
    response: Yup.bool().required('Response is Required'),
    note: Yup.string().when('response', {
      is: false,
      then: (schema) => schema.required('Note is Required'),
    }),
  });
  const casePathologySchema = Yup.object().shape({
    pathologyType: Yup.string(),
    pathologyNote: Yup.string().nullable(),
  });

  const patientRecordSchema = Yup.object().shape({
    pelvicIncidence: Yup.number()
      .transform((value, originalValue) => (originalValue === '' ? null : value))
      .nullable()
      .typeError('Pelvic Incidence must be a number')
      .test(
        'range',
        'Pelvic Incidence must be between 1° and 180°',
        (value) => value === null || (value !== undefined && value >= 1 && value <= 180),
      ),
    lumbarLordosis: Yup.number()
      .transform((value, originalValue) => (originalValue === '' ? null : value))
      .nullable()
      .typeError('Lumbar Lordosis must be a number'),
    lumbarCoronalCobb: Yup.number()
      .transform((value, originalValue) => (originalValue === '' ? null : value))
      .nullable()
      .typeError('Lumbar Coronal Angle must be a number'),
    sagittalVerticalAxis: Yup.number()
      .transform((value, originalValue) => (originalValue === '' ? null : value))
      .nullable()
      .typeError('Sagittal Vertical Axis must be a number'),
    l4S1LordoticDistribution: Yup.number()
      .transform((value, originalValue) => (originalValue === '' ? null : value))
      .nullable()
      .typeError('L4S1 Lordotic Distribution must be a number'),
    globalTilt: Yup.number()
      .transform((value, originalValue) => (originalValue === '' ? null : value))
      .nullable()
      .typeError('Global Tilt must be a number'),
    pelvicTilt: Yup.number()
      .transform((value, originalValue) => (originalValue === '' ? null : value))
      .nullable()
      .typeError('Pelvic Tilt must be a number'),
    sacralSlope: Yup.number()
      .transform((value, originalValue) => (originalValue === '' ? null : value))
      .nullable()
      .typeError('Sacral Slope must be a number'),
    c7SagittalVerticalLine: Yup.number()
      .transform((value, originalValue) => (originalValue === '' ? null : value))
      .nullable()
      .typeError('c7 Sagittal Vertical Line must be a number'),
  });

  const tem13FillDialogSchema = Yup.object().shape({
    segmentationImagingAssessment: Yup.string().required(
      'Segmentation Imaging Assessment Required',
    ),
    segmentationSliceThickness: Yup.string().required('Segmentation Slice Thickness Required'),
    segmentedByQualifiedOperator: Yup.bool().required('segmented By Qualified Operator Required'),
    riskAssessment: Yup.string(),
    reportReviewedBy: Yup.string().when('riskAssessment', {
      is: 'CASE_REVIEW_QA_REVIEW',
      then: (schema) => schema.required('Case Report Reviewer Required'),
    }),
    isSegmentationPassed: Yup.bool(),
    segmentationPerformedBy: Yup.string().when('segmentedByQualifiedOperator', {
      is: false,
      then: (schema) => schema.required('Segmentation Performed By Required'),
    }),
    segmentationReviewedBy: Yup.string().when('segmentedByQualifiedOperator', {
      is: false,
      then: (schema) => schema.required('Segmentation Reviewed By Required'),
    }),
    caseReportStandingXrayLateralMeasured: Yup.mixed<File>()
      .test(
        'hasCaseReportStandingXrayLateralMeasured',
        'Standing Xray Lateral Measured Required',
        (value) => checkFileExists(value, AssetType.CaseReportStandingXrayLateralMeasured),
      )
      .nullable(),
    patientRecord: patientRecordSchema,
    measurementLordosisDifference: Yup.number(),
    measurementLordosisDifferenceRationale: Yup.array()
      .of(Yup.string())
      .when('measurementLordosisDifference', {
        is: (value: number) => value > 10,
        then: (schema) =>
          schema
            .min(1, 'At least one rationale difference reason must be selected')
            .required('Field is required'),
        otherwise: (schema) => schema.notRequired(),
      }),
    measurementLordosisDifferenceRationaleNote: Yup.string().when(
      'measurementLordosisDifferenceRationale',
      {
        is: (rationaleArray: string[]) =>
          rationaleArray && rationaleArray.includes(LordosisDifferenceRationaleType.Other),
        then: (schema) => schema.required('Rationale note is required when "Other" is selected'),
        otherwise: (schema) => schema.notRequired(),
      },
    ),
    measurementNote: Yup.string(),
    correctionGoalNote: Yup.string(),
    correctionGoalSagittal: Yup.string().required('Sagittal Correction Required'),
    correctionGoalSagittalOther: Yup.string().when('correctionGoalSagittal', {
      is: 'OTHER',
      then: (schema) => schema.required('Sagittal Correction Other Required'),
    }),
    correctionGoalCoronal: Yup.string().required('Coronal Correction Required'),
    correctionGoalCoronalOther: Yup.string().when('correctionGoalCoronal', {
      is: 'OTHER',
      then: (schema) => schema.required('Coronal Correction Other Required'),
    }),
    planFeedback: Yup.array()
      .of(Yup.string())
      .min(1, 'At least one Feedback must be selected')
      .required('Plan Feedback required'),
    planFeedbackOther: Yup.string().when('planFeedback', {
      is: (feedbackArray: string) => feedbackArray && feedbackArray.includes('OTHER'),
      then: (schema) => schema.required('Plan Feedback Other Required'),
    }),

    caseReportStatements: Yup.array().of(caseReportStatementSchema),
    casePathologies: Yup.array().of(casePathologySchema),
  });
  const tem13Errors: Tem13Error[] = [];

  const methods = useForm<Tem13FillDialogStateType>({
    defaultValues: state,
    values: state, // don't delete
    resolver: yupResolver(tem13FillDialogSchema) as unknown as Resolver<Tem13FillDialogStateType>,
  });

  const dirtyFields = methods.formState.dirtyFields;
  const fieldsToCheck = [
    'riskAssessment',
    'reportReviewedBy',
    'correctionGoalSagittal',
    'correctionGoalSagittalOther',
    'correctionGoalCoronal',
    'correctionGoalCoronalOther',
    'correctionGoalDiscHeightOnly',
    'correctionGoalNote',
    'segmentationSliceThickness',
    'isSegmentationPassed',
    'segmentationImagingAssessment',
    'segmentedByQualifiedOperator',
    'segmentationPerformedBy',
    'segmentationReviewedBy',
    'measurementLordosisDifferenceRationale',
    'measurementLordosisDifferenceRationaleNote',
    'measurementNote',
    'planFeedback',
    'planFeedbackOther',
    'caseReportStatements',
    'casePathologies',
    'caseReportCorrectionPlanRules',
    'patientRecord',
  ];

  const riskAssessment = methods.getValues('riskAssessment');
  const reportStatus = methods.getValues('status');
  const tem13Assets = (methods.getValues('assets') ?? []) as unknown as IAsset[];

  function getAsset(assetType: AssetType) {
    return tem13Assets.find((tem13Asset) => tem13Asset.assetType === assetType);
  }

  function checkFileExists(value: unknown, assetType: AssetType) {
    return !!value || !!getAsset(assetType);
  }

  const canEditCaseReport = !!hasPermission?.([
    Permission.ManageCase,
    Permission.EditCase,
    Permission.ManagePostOpAnalysis,
  ]);
  const canEditAndApproveCaseReport =
    tem13Utils.isValidDesignEngineerUser(activeCase?.assignedUser?.userId, currentUser) &&
    canEditCaseReport;

  const hasFlaggedGuardrails = tem13Utils.checkGuardrailsFlagged(
    state?.caseReportCorrectionPlanRules,
  );

  const isMedicalReviewFormReadyToReview = !!(
    hasFlaggedGuardrails &&
    state.medicalReviewRequestedAt &&
    [MedicalReviewStatusType.Pending, MedicalReviewStatusType.Rejected].includes(
      state.medicalReviewStatus as MedicalReviewStatusType,
    ) &&
    currentUser?.userId === state.medicalReviewedByUser?.userId
  );

  const isCaseReportReadyToReview = !!(
    state.riskAssessment === CaseRiskAssessmentType.CaseReviewQaReview &&
    state.reportReviewRequestedAt &&
    [CaseReportStatusType.InReview, CaseReportStatusType.Rejected].includes(state.status) &&
    currentUser?.userId === state.reportReviewedByUser?.userId
  );

  const isCaseReportReadyToComplete = !!(
    state.riskAssessment === CaseRiskAssessmentType.QaReviewOnly &&
    !state.reportReviewRequestedAt &&
    ![CaseReportStatusType.Approved].includes(state.status) &&
    currentUser?.userId === activeCase?.assignedUser?.userId
  );

  const selfApproveCaseReportEnabled = !!(
    state.riskAssessment === CaseRiskAssessmentType.QaReviewOnly &&
    canEditAndApproveCaseReport &&
    !state.reportReviewRequestedAt &&
    ![CaseReportStatusType.Approved].includes(state.status)
  );

  const sendMedicalReviewEnabled = !!(
    hasFlaggedGuardrails &&
    canEditAndApproveCaseReport &&
    (!state.medicalReviewRequestedAt ||
      [MedicalReviewStatusType.Overridden, MedicalReviewStatusType.Rejected].includes(
        state.medicalReviewStatus as MedicalReviewStatusType,
      ))
  );
  const OverrideMedicalReviewEnabled = !!(
    hasFlaggedGuardrails &&
    canEditAndApproveCaseReport &&
    state.medicalReviewRequestedAt &&
    [MedicalReviewStatusType.Pending, MedicalReviewStatusType.Rejected].includes(
      state.medicalReviewStatus as MedicalReviewStatusType,
    )
  );
  const sendCaseReportReviewEnabled = !!(
    state.riskAssessment === CaseRiskAssessmentType.CaseReviewQaReview &&
    canEditAndApproveCaseReport &&
    !state.reportReviewRequestedAt
  );

  function getMedicalReviewExpiryTime(medicalReviewRequestedAt: Date): Date | string {
    return new Date(
      DateTime.fromJSDate(new Date(medicalReviewRequestedAt)).plus({ hours: 3 }).toJSDate(),
    );
  }

  // Wrapper function for handleSubmit
  const handleFormSubmit = (formActionType: FormActionType) => {
    return (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      methods.handleSubmit((data) => handleSubmitWithAction(data, formActionType))();
    };
  };

  const handleSubmitWithAction: SubmitHandlerWithAction<Tem13FillDialogStateType> = async (
    data,
    formActionType,
  ) => {
    setActiveButton(formActionType);
    try {
      let title = 'Save TEM-013 Case Report?';
      let description = 'Are you sure you want to save TEM-013 case report?';

      if (formActionType === FormActionType.SendMedicalReviewRequest) {
        title = 'Send Medical Review Request?';
        description = 'This will send medical review request to Medical Affairs Team. Continue?';
      } else if (formActionType === FormActionType.SendCaseReportReviewRequest) {
        title = 'Send Case Report Review Request?';
        description = 'This will send case report review request. Continue?';
      } else if (formActionType === FormActionType.ApproveCaseReportReview) {
        title = 'Approve Case Report?';
        description = 'Are you sure you want to approve case report?';
      }

      await confirm({
        title: title,
        description: description,
      });

      const caseId = data.caseId;
      const planId = data?.tem13CaseData?.planId ?? 0;

      const casePathologies = data?.casePathologies
        .filter((element: CasePathologyType) => element.pathologySelected === true)
        .map((pathology: CasePathologyType) => ({
          pathologyType: pathology.pathologyType,
          pathologyNote: pathology.pathologyNote,
        }));

      const caseReportCorrectionPlanRules = data?.caseReportCorrectionPlanRules.map(
        (caseReportCorrectionPlanRule: CaseReportCorrectionPlanRuleType) => {
          return {
            ruleType: caseReportCorrectionPlanRule.ruleType,
            level: caseReportCorrectionPlanRule?.level
              ? caseReportCorrectionPlanRule.level
              : undefined,
            planValue: Number(caseReportCorrectionPlanRule?.planValue),
            flagged: caseReportCorrectionPlanRule?.flagged,
            note: caseReportCorrectionPlanRule?.note,
          };
        },
      );

      const caseReviewQaReview = data?.riskAssessment === CaseRiskAssessmentType.CaseReviewQaReview;

      let status = CaseReportStatusType.InProgress;
      let reportReviewedBy = data?.reportReviewedBy ? Number(data?.reportReviewedBy) : null;
      let reportReviewedAt = null;

      if (formActionType === FormActionType.ApproveCaseReportReview) {
        status = CaseReportStatusType.Approved;
        reportReviewedBy = activeCase.assignedUser.userId;
        reportReviewedAt = new Date().toISOString();
      } else if (formActionType === FormActionType.SendCaseReportReviewRequest) {
        status = CaseReportStatusType.InReview;
      } else if (formActionType === FormActionType.SendMedicalReviewRequest) {
        status = CaseReportStatusType.InMedicalReview;
      }
      await upsertCaseReport({
        variables: {
          caseId: caseId,
          status: status,
          isComplete: data?.isComplete,
          riskAssessment: data?.riskAssessment,
          correctionGoalSagittal: data?.correctionGoalSagittal,
          correctionGoalSagittalOther: data?.correctionGoalSagittalOther,
          correctionGoalCoronal: data?.correctionGoalCoronal,
          correctionGoalCoronalOther: data?.correctionGoalCoronalOther,
          correctionGoalDiscHeightOnly: data?.correctionGoalDiscHeightOnly,
          correctionGoalNote: data?.correctionGoalNote,
          segmentationAssetId: convertNullOrNumberString(data?.segmentationAssetId),
          segmentationSliceThickness: data?.segmentationSliceThickness,
          isSegmentationPassed: data?.isSegmentationPassed,
          segmentationImagingAssessment: data?.segmentationImagingAssessment,
          segmentationImageDate: data?.segmentationImageDate,
          segmentedByQualifiedOperator: data?.segmentedByQualifiedOperator,
          segmentationPerformedBy: convertNullOrNumberString(data?.segmentationPerformedBy),
          segmentationReviewedBy: convertNullOrNumberString(data?.segmentationReviewedBy),
          segmentationType: data?.segmentationType,
          reportReviewedBy: convertNullOrNumberString(reportReviewedBy),
          reportReviewedAt: reportReviewedAt,
          measurementLordosisDifference: data?.measurementLordosisDifference,
          measurementLordosisDifferenceRationale: data?.measurementLordosisDifferenceRationale,
          measurementLordosisDifferenceRationaleNote:
            data?.measurementLordosisDifferenceRationaleNote,
          measurementNote: data?.measurementNote,
          planFeedback: data?.planFeedback,
          planFeedbackOther: data?.planFeedbackOther,
          casePathologies: casePathologies,
          caseReportStatements: data?.caseReportStatements,
          caseReportCorrectionPlanRules: caseReportCorrectionPlanRules,
        },
      });

      await updatePatientRecord({
        variables: {
          patientId: data?.patientRecord?.patientId,
          pelvicIncidence:
            data?.patientRecord?.pelvicIncidence != null &&
            data?.patientRecord?.pelvicIncidence?.toString() !== ''
              ? Number(data?.patientRecord?.pelvicIncidence)
              : null,
          lumbarLordosis:
            data?.patientRecord?.lumbarLordosis != null &&
            data?.patientRecord?.lumbarLordosis?.toString() !== ''
              ? Number(data?.patientRecord?.lumbarLordosis)
              : null,
          lumbarCoronalCobb:
            data?.patientRecord?.lumbarCoronalCobb != null &&
            data?.patientRecord?.lumbarCoronalCobb?.toString() !== ''
              ? Number(data?.patientRecord?.lumbarCoronalCobb)
              : null,
          sagittalVerticalAxis:
            data?.patientRecord?.sagittalVerticalAxis != null &&
            data?.patientRecord?.sagittalVerticalAxis?.toString() !== ''
              ? Number(data?.patientRecord?.sagittalVerticalAxis)
              : null,
          l4S1LordoticDistribution:
            data?.patientRecord?.l4S1LordoticDistribution != null &&
            data?.patientRecord?.l4S1LordoticDistribution?.toString() !== ''
              ? Number(data?.patientRecord?.l4S1LordoticDistribution)
              : null,
          globalTilt:
            data?.patientRecord?.globalTilt != null &&
            data?.patientRecord?.globalTilt?.toString() !== ''
              ? Number(data?.patientRecord?.globalTilt)
              : null,
          pelvicTilt:
            data?.patientRecord?.pelvicTilt != null &&
            data?.patientRecord?.pelvicTilt?.toString() !== ''
              ? Number(data?.patientRecord?.pelvicTilt)
              : null,
          sacralSlope:
            data?.patientRecord?.sacralSlope != null &&
            data?.patientRecord?.sacralSlope?.toString() !== ''
              ? Number(data?.patientRecord?.sacralSlope)
              : null,
          c7SagittalVerticalLine:
            data?.patientRecord?.c7SagittalVerticalLine != null &&
            data?.patientRecord?.c7SagittalVerticalLine?.toString() !== ''
              ? Number(data?.patientRecord?.c7SagittalVerticalLine)
              : null,
        },
      });

      for (const deleteAssetId of data?.deletedAssetIds ?? []) {
        await deleteAsset({
          variables: {
            assetId: deleteAssetId,
          },
        });
      }

      setUploadingImage(true);
      if (data?.caseReportStandingXrayLateralMeasured) {
        await createAndUploadAsset(
          data.caseReportStandingXrayLateralMeasured,
          AssetType.CaseReportStandingXrayLateralMeasured,
          caseId,
          planId,
        );
      }

      if (data?.caseReportVertebraePreEditImage) {
        await createAndUploadAsset(
          data.caseReportVertebraePreEditImage,
          AssetType.CaseReportVertebraePreEditImage,
          caseId,
          planId,
        );
      }
      if (data?.caseReportVertebraePostEditImage) {
        await createAndUploadAsset(
          data.caseReportVertebraePostEditImage,
          AssetType.CaseReportVertebraePostEditImage,
          caseId,
          planId,
        );
      }
      if (data?.caseReportImplantPreEditImage) {
        await createAndUploadAsset(
          data.caseReportImplantPreEditImage,
          AssetType.CaseReportImplantPreEditImage,
          caseId,
          planId,
        );
      }
      if (data?.caseReportImplantPostEditImage) {
        await createAndUploadAsset(
          data.caseReportImplantPostEditImage,
          AssetType.CaseReportImplantPostEditImage,
          caseId,
          planId,
        );
      }

      if (data?.levelAssets) {
        for (const levelAsset of data.levelAssets) {
          for (const inputAsset of levelAsset.inputAssets) {
            if (inputAsset?.inputAsset) {
              await createAndUploadAsset(
                inputAsset.inputAsset,
                inputAsset.assetType,
                caseId,
                planId,
              );
            }
          }
        }
      }

      if (formActionType === FormActionType.SendMedicalReviewRequest) {
        await sendMedicalReviewRequest({
          variables: {
            caseId: activeCase?.caseId,
            userId: medicalReviewUser?.userId,
          },
        });
      }

      if (formActionType === FormActionType.SendCaseReportReviewRequest && caseReviewQaReview) {
        await sendCaseReportReviewRequest({
          variables: {
            caseId: activeCase?.caseId,
            userId: data?.reportReviewedBy ? Number(data?.reportReviewedBy) : undefined,
          },
        });
      }

      enqueueSnackbar('TEM-013 case report saved', {
        variant: 'success',
      });
      findCaseReport();
      //onClose(true);
    } catch (err) {
      if (!err) {
        return;
      }
      console.error(err);
      enqueueSnackbar('An error occurred updating the TEM-013 case report', {
        variant: 'error',
      });
    } finally {
      methods.reset();
      setUploadingImage(false);
      setActiveButton(null);
    }
  };

  const handleMedicalReviewSubmit = async (actionType: FormActionType, formData: any) => {
    try {
      await confirm({
        title: `${
          actionType === FormActionType.ApproveMedicalReview ? 'Approve' : 'Reject'
        } Medical Review?`,
        description: (
          <>
            Are you sure you want to{' '}
            {actionType === FormActionType.ApproveMedicalReview ? 'approve' : 'reject'} Medical
            Review? Please confirm
          </>
        ),
      });

      if (actionType === FormActionType.ApproveMedicalReview) {
        await approveMedicalReview({
          variables: {
            caseId: activeCase?.caseId,
            medicalReviewNote: formData?.medicalReviewNote,
          },
        });
      } else if (actionType === FormActionType.RejectMedicalReview) {
        await rejectMedicalReview({
          variables: {
            caseId: activeCase?.caseId,
            medicalReviewNote: formData?.medicalReviewNote,
          },
        });
      }

      enqueueSnackbar(
        `Medical Review has been ${
          actionType === FormActionType.ApproveMedicalReview ? 'approved' : 'rejected'
        }`,
        {
          variant: 'success',
        },
      );
      findCaseReport();
    } catch (e) {
      if (!e) {
        return;
      }
      console.error(e);
      enqueueSnackbar(
        `An error occurred ${
          actionType === FormActionType.ApproveMedicalReview ? 'approving' : 'rejecting'
        } the medical review`,
        { variant: 'error' },
      );
    }
  };

  const handleCaseReportReviewSubmit = async (actionType: FormActionType, formData: any) => {
    try {
      await confirm({
        title: `${
          actionType === FormActionType.ApproveCaseReportReview ? 'Approve' : 'Reject'
        } Case Report Review?`,
        description: (
          <>
            Are you sure you want to{' '}
            {actionType === FormActionType.ApproveCaseReportReview ? 'approve' : 'reject'} Case
            Report Review? Please confirm
          </>
        ),
      });

      if (actionType === FormActionType.ApproveCaseReportReview) {
        await approveCaseReportReview({
          variables: {
            caseId: activeCase?.caseId,
            reportReviewNote: formData?.reportReviewNote,
          },
        });
      } else if (actionType === FormActionType.RejectCaseReportReview) {
        await rejectCaseReportReview({
          variables: {
            caseId: activeCase?.caseId,
            reportReviewNote: formData?.reportReviewNote,
          },
        });
      }

      enqueueSnackbar(
        `Case Report has been ${
          actionType === FormActionType.ApproveCaseReportReview ? 'approved' : 'rejected'
        }`,
        {
          variant: 'success',
        },
      );
      findCaseReport();
    } catch (e) {
      if (!e) {
        return;
      }
      console.error(e);
      enqueueSnackbar(
        `An error occurred ${
          actionType === FormActionType.ApproveCaseReportReview ? 'approving' : 'rejecting'
        } the case report review`,
        { variant: 'error' },
      );
    }
  };

  const handleOverrideMedicalReview = async () => {
    setActiveButton(FormActionType.OverrideMedicalReview);
    try {
      await confirm({
        title: `Override Medical Review?`,
        description: <>This will override medical review. Continue?</>,
      });

      await overrideMedicalReview({
        variables: {
          caseId: activeCase?.caseId,
          userId: activeCase?.assignedUser?.userId,
        },
      });

      enqueueSnackbar(`The medical review has been overridden`, { variant: 'success' });
      findCaseReport();
    } catch (e) {
      if (!e) {
        return;
      }
      console.error(e);
      enqueueSnackbar('An error occurred overriding medical review', { variant: 'error' });
    } finally {
      setActiveButton(null);
    }
  };

  const handleClose = async () => {
    const hasDirtyFields = fieldsToCheck.some(
      (field) => dirtyFields[field as keyof typeof dirtyFields],
    );

    if (hasDirtyFields) {
      try {
        await confirm({
          title: 'Close TEM-013 dialog?',
          description: 'You have unsaved changes. Are you sure you want to close without saving?',
        });
        methods.reset();
        onClose(false);
      } catch {
        // Do nothing if the user cancels the confirmation dialog
      }
    } else {
      onClose(false);
    }
  };

  const encodeAssetToBase64 = useCallback(
    async (assetId: number) => {
      let encodedImage = '';
      try {
        const { data } = await createAssetDownloadUrl({
          variables: {
            assetId: assetId,
          },
        });

        const signedUrl = data.createAssetDownloadUrl.signedUrl;
        const response = await file.downloadFile(signedUrl);
        if (response.status === 200) {
          encodedImage = await file.encodeBlobToBase64(response.data);
        }
      } catch (error) {
        console.error('Error loading asset:', error);
      }
      return encodedImage;
    },
    [createAssetDownloadUrl],
  );

  const encodeFileToBase64 = async (inputAssetFile: File): Promise<string> => {
    return await file.encodeBlobToBase64(inputAssetFile);
  };

  const createEncodedImages = async (caseReportData: Tem13FillDialogStateType) => {
    const assets = caseReportData.assets ?? [];
    const levelAssets = caseReportData.levelAssets ?? [];

    const caseReportVertebraePreEditImage = caseReportData.caseReportVertebraePreEditImage;
    const caseReportVertebraePostEditImage = caseReportData.caseReportVertebraePostEditImage;
    const caseReportImplantPreEditImage = caseReportData.caseReportImplantPreEditImage;
    const caseReportImplantPostEditImage = caseReportData.caseReportImplantPostEditImage;
    const caseReportStandingXrayLateralMeasured =
      caseReportData.caseReportStandingXrayLateralMeasured;

    const findAsset = (assetType: AssetType) =>
      assets.find((asset) => asset.assetType === assetType);

    const encodedVertebraePreEditImage = await (async () => {
      const asset = findAsset(AssetType.CaseReportVertebraePreEditImage);
      if (asset) return encodeAssetToBase64(asset.assetId);
      if (caseReportVertebraePreEditImage)
        return encodeFileToBase64(caseReportVertebraePreEditImage);
      return '';
    })();

    const encodedVertebraePostEditImage = await (async () => {
      const asset = findAsset(AssetType.CaseReportVertebraePostEditImage);
      if (asset) return encodeAssetToBase64(asset.assetId);
      if (caseReportVertebraePostEditImage)
        return encodeFileToBase64(caseReportVertebraePostEditImage);
      return '';
    })();

    const encodedImplantPreEditImage = await (async () => {
      const asset = findAsset(AssetType.CaseReportImplantPreEditImage);
      if (asset) return encodeAssetToBase64(asset.assetId);
      if (caseReportImplantPreEditImage) return encodeFileToBase64(caseReportImplantPreEditImage);
      return '';
    })();

    const encodedImplantPostEditImage = await (async () => {
      const asset = findAsset(AssetType.CaseReportImplantPostEditImage);
      if (asset) return encodeAssetToBase64(asset.assetId);
      if (caseReportImplantPostEditImage) return encodeFileToBase64(caseReportImplantPostEditImage);
      return '';
    })();

    const encodedStandingXrayLateralMeasured = await (async () => {
      const asset = findAsset(AssetType.CaseReportStandingXrayLateralMeasured);
      if (asset) return encodeAssetToBase64(asset.assetId);
      if (caseReportStandingXrayLateralMeasured)
        return encodeFileToBase64(caseReportStandingXrayLateralMeasured);
      return '';
    })();

    const encodedAssetLevels: EncodedAssetLevelType[] = await Promise.all(
      levelAssets.map(async (levelAsset) => {
        return Promise.all(
          levelAsset.inputAssets.map(async (inputAsset) => {
            const asset = findAsset(inputAsset.assetType as AssetType);
            let encodedImage = '';

            if (asset) {
              encodedImage = await encodeAssetToBase64(asset.assetId);
            } else if (inputAsset.inputAsset) {
              encodedImage = await encodeFileToBase64(inputAsset.inputAsset);
            }

            return {
              level: inputAsset.level,
              implantType: levelAsset.implantType,
              assetType: inputAsset.assetType,
              encodedImage: encodedImage || '',
            };
          }),
        );
      }),
    ).then((results) => results.flat());

    const encodedImages: CaseEncodedToBase64ImageType = {
      encodedVertebraePreEditImage,
      encodedVertebraePostEditImage,
      encodedImplantPreEditImage,
      encodedImplantPostEditImage,
      encodedStandingXrayLateralMeasured,
      encodedAssetLevels,
    };

    return encodedImages;
  };

  const handleCreateTem13PDF: SubmitHandler<Tem13FillDialogStateType> = async (caseReportData) => {
    setActiveButton(FormActionType.PreviewTem13Pdf);
    try {
      await confirm({
        title: 'Save TEM-013 Case Report?',
        description: 'Are you sure you want to save TEM-013 case report?',
      });

      const caseEncodedToBase64Images = await createEncodedImages(caseReportData);
      const updatedCaseReportData: Tem13FillDialogStateType = JSON.parse(
        JSON.stringify(caseReportData),
      );
      updatedCaseReportData.caseEncodedToBase64Images = caseEncodedToBase64Images;

      const temp13Pdf = await tem13Pdf.tem13Report(updatedCaseReportData);
      FileSaver.saveAs(temp13Pdf, `${activeCase.number} Tem13.pdf`);

      enqueueSnackbar('TEM-013 case report saved', {
        variant: 'success',
      });
      //onClose(true);
    } catch (err) {
      if (!err) {
        return;
      }
      console.error(err);
      enqueueSnackbar('An error occurred creating the TEM-013 case report pdf', {
        variant: 'error',
      });
    } finally {
      setActiveButton(null);
    }
  };

  useEffect(() => {
    if (open) {
      findCaseReport();
    }
  }, [open, findCaseReport]);

  useEffect(() => {
    if (
      state.medicalReviewRequestedAt &&
      (state.medicalReviewStatus === MedicalReviewStatusType.Pending ||
        state.medicalReviewStatus === MedicalReviewStatusType.Rejected)
    ) {
      const medicalReviewExpiryTime = getMedicalReviewExpiryTime(state.medicalReviewRequestedAt);
      const checkMedicalReviewLock = () => {
        const currentTime = new Date();
        setMedicalReviewLock(currentTime < medicalReviewExpiryTime);
        setMedicalReviewLockExpired(currentTime >= medicalReviewExpiryTime);
      };

      checkMedicalReviewLock();

      const interval = setInterval(checkMedicalReviewLock, 60000);

      // Cleanup the interval
      return () => clearInterval(interval);
    }

    if (
      state.medicalReviewRequestedAt &&
      state.medicalReviewStatus !== MedicalReviewStatusType.Pending &&
      state.medicalReviewStatus !== MedicalReviewStatusType.Rejected
    ) {
      setMedicalReviewLock(false);
      setMedicalReviewLockExpired(false);
    }
  }, [state.medicalReviewRequestedAt, state.medicalReviewStatus]);

  useEffect(() => {
    if (open && state.isStateReady) {
      methods.reset(state);
    }
  }, [open, state, methods]);

  return open && state.isStateReady ? (
    <Box m={1}>
      <FormProvider {...methods}>
        <form>
          <CustomDialog
            maxWidth={'lg'}
            open={open}
            title={`TEM-013`}
            onClose={handleClose}
            positiveActionButtons={
              canEditCaseReport
                ? [
                    <ProgressButton
                      variant={'outlined'}
                      disabled={methods.formState.isSubmitting}
                      onClick={(evt) => methods.handleSubmit(handleCreateTem13PDF)(evt)}
                      label={
                        state?.status !== CaseReportStatusType.Approved
                          ? 'Preview TEM-013 PDF'
                          : 'Download TEM-013 PDF'
                      }
                      loading={
                        methods.formState.isSubmitting &&
                        activeButton === FormActionType.PreviewTem13Pdf
                      }
                    />,
                    (!isCaseReportReadyToReview || !isMedicalReviewFormReadyToReview) &&
                      canEditAndApproveCaseReport && (
                        <ProgressButton
                          variant={'outlined'}
                          disabled={
                            methods.formState.isSubmitting ||
                            medicalReviewLock ||
                            [CaseReportStatusType.Approved, CaseReportStatusType.InReview].includes(
                              state?.status,
                            )
                          }
                          onClick={handleFormSubmit(FormActionType.SaveCaseReport)}
                          label={'Save'}
                          loading={
                            methods.formState.isSubmitting &&
                            activeButton === FormActionType.SaveCaseReport
                          }
                        />
                      ),
                  ]
                : []
            }
          >
            {open ? (
              <>
                {state?.medicalReviewStatus === MedicalReviewStatusType.Approved ? (
                  <Box mb={2}>
                    <Alert severity="success">
                      {format.formatName(state?.medicalReviewedByUser)} has given clinical approval
                      for the 'Medical Review' of TEM-013 on{' '}
                      {format.formatDate(state?.medicalReviewedAt as any)} at{' '}
                      {format.formatTime(state?.medicalReviewedAt as any)}.
                    </Alert>
                  </Box>
                ) : state?.medicalReviewStatus === MedicalReviewStatusType.Rejected ? (
                  <Box mb={2}>
                    <Alert severity="error">
                      {format.formatName(state?.medicalReviewedByUser)} has rejected the 'Medical
                      Review' of TEM-013 on {format.formatDate(state?.medicalReviewedAt as any)} at{' '}
                      {format.formatTime(state?.medicalReviewedAt as any)}.
                    </Alert>
                  </Box>
                ) : null}

                {state?.status === CaseReportStatusType.Approved ? (
                  <Box mb={2}>
                    <Alert severity="success">
                      {format.formatName(state?.reportReviewedByUser)} has given engineering
                      approval for the TEM-013 on{' '}
                      {format.formatDate(state?.reportReviewedAt as any)} at{' '}
                      {format.formatTime(state?.reportReviewedAt as any)}.
                    </Alert>
                  </Box>
                ) : state?.status === CaseReportStatusType.Rejected ? (
                  <Box mb={2}>
                    <Alert severity="error">
                      {format.formatName(state?.reportReviewedByUser)} has rejected the TEM-013 on{' '}
                      {format.formatDate(state?.reportReviewedAt as any)} at{' '}
                      {format.formatTime(state?.reportReviewedAt as any)}.
                    </Alert>
                  </Box>
                ) : null}

                {canEditCaseReport &&
                  state?.medicalReviewRequestedAt &&
                  ![
                    CaseReportStatusType.InReview,
                    CaseReportStatusType.Rejected,
                    CaseReportStatusType.Approved,
                  ].includes(state?.status) &&
                  (state?.medicalReviewStatus === MedicalReviewStatusType.Overridden ||
                    medicalReviewLock ||
                    medicalReviewLockExpired) && (
                    <Box mb={2}>
                      <Alert severity="warning">
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          width="100%"
                        >
                          <Box flex="1" pr={2}>
                            <Box>
                              The Medical Review status:{' '}
                              <strong>
                                {format.formatMedicalReviewStatusType(
                                  state?.medicalReviewStatus as MedicalReviewStatusType,
                                )}
                              </strong>
                            </Box>
                            {medicalReviewLock && OverrideMedicalReviewEnabled && (
                              <Box mt={2}>
                                The Medical Review process started at{' '}
                                {format.formatDateTime(state.medicalReviewRequestedAt)} and will
                                expire at{' '}
                                {format.formatDateTime(
                                  getMedicalReviewExpiryTime(state.medicalReviewRequestedAt),
                                )}
                                . If urgent access is needed, you can override it by clicking the
                                'Override Medical Review' button.
                              </Box>
                            )}
                            {medicalReviewLockExpired && OverrideMedicalReviewEnabled && (
                              <Box mt={2}>
                                The Medical Review was requested at{' '}
                                {format.formatDateTime(state.medicalReviewRequestedAt)} and expired
                                at{' '}
                                {format.formatDateTime(
                                  getMedicalReviewExpiryTime(state.medicalReviewRequestedAt),
                                )}
                                . Click the 'Override Medical Review' button to continue..
                              </Box>
                            )}
                          </Box>
                          {canEditCaseReport && OverrideMedicalReviewEnabled ? (
                            <Box
                              display="flex"
                              justifyContent="flex-end"
                              alignItems="center"
                              width="auto"
                            >
                              <ProgressButton
                                variant="contained"
                                disabled={methods.formState.isSubmitting}
                                onClick={handleOverrideMedicalReview}
                                label="Override Medical Review"
                                loading={activeButton === FormActionType.OverrideMedicalReview}
                              />
                            </Box>
                          ) : null}
                        </Box>
                      </Alert>
                    </Box>
                  )}
                <Tem13FillForm
                  activeCase={activeCase}
                  state={state}
                  surgeonUser={surgeonUser}
                  disabled={
                    methods.formState.isSubmitting ||
                    uploadingImage ||
                    medicalReviewLock ||
                    isMedicalReviewFormReadyToReview ||
                    isCaseReportReadyToReview ||
                    !canEditAndApproveCaseReport ||
                    [CaseReportStatusType.Approved, CaseReportStatusType.InReview].includes(
                      state?.status,
                    )
                  }
                  errors={tem13Errors}
                  loading={methods.formState.isSubmitting}
                  tem13Users={tem13Users}
                  handleFormSubmit={handleFormSubmit}
                  activeButton={activeButton}
                  selfApproveCaseReportEnabled={selfApproveCaseReportEnabled}
                  sendMedicalReviewEnabled={sendMedicalReviewEnabled}
                  sendCaseReportReviewEnabled={sendCaseReportReviewEnabled}
                />
              </>
            ) : null}
            {isMedicalReviewFormReadyToReview && (
              <MedicalReviewForm
                medicalReviewNote={state.medicalReviewNote}
                medicalReviewStatus={state.medicalReviewStatus}
                disabled={state?.status === CaseReportStatusType.Approved}
                onActionSubmit={handleMedicalReviewSubmit}
              />
            )}
            {isCaseReportReadyToReview && (
              <CaseReportReviewForm
                riskAssessment={riskAssessment}
                reportStatus={reportStatus}
                reportReviewNote={state.reportReviewNote}
                disabled={state?.status === CaseReportStatusType.Approved}
                onActionSubmit={handleCaseReportReviewSubmit}
              />
            )}
          </CustomDialog>
        </form>
      </FormProvider>
    </Box>
  ) : null;
}

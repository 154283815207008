import {
  AssetType,
  caseUtils,
  Form19Error,
  format,
  IAsset,
  ICase,
  IPlan,
  IPlanExcludedImplantSizes,
  LevelType,
  PartType,
  planUtils,
  InterbodyScrewLengthTypes,
  CaseRiskAssessmentType,
  CaseStageType,
} from '@workflow-nx/common';
import {
  Box,
  Skeleton,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import { FieldArray, FormikValues, useFormikContext } from 'formik';
import { Checkbox, IconFontButton, SelectField, TextField } from '@workflow-nx/ui';
import { TableHeadCell } from '../../../../components/TableHeadCell';
import { date } from '@workflow-nx/utils';

import RadioGroup from '../../../../components/RadioGroup';
import { faEye } from '@fortawesome/pro-light-svg-icons';
import { Form19ManualAttributeTableRow } from './Form19ManualAttributeTableRow';
import { Form19AngleTableRow } from './Form19AngleTableRow';
import { Form19NoteTableRow } from './Form19NoteTableRow';
import { Form19AdditionalImageTableRow } from './Form19AdditionalImageTableRow';
import { Form19RevisionTableRow } from './Form19RevisionTableRow';
import * as form19Func from '../../../../utils/form19';
import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { FIND_ASSETS } from '../../../../gql';
import { AssetMeshViewDialog } from '../../../../components/AssetMeshView/AssetMeshViewDialog';
import { Alert } from '@mui/material';
import { Form19PartAttributeTableRow } from './Form19PartAttributeTableRow';

export function Form19ManualEntryForm(props: {
  activeCase: ICase;
  disabled: boolean;
  errors: Form19Error[];
  loading: boolean;
  peerReviewers: { key: string; value: string }[];
  plan: IPlan;
  onRemove: (levelType: LevelType, assetId: number | undefined) => void;
  warningWhoCanApproveForm19: boolean;
  canQualityApproveForm19: boolean;
  validCaseExcludedImplants?: IPlanExcludedImplantSizes[];
}) {
  const theme = useTheme();
  const { values: formikValues, setFieldValue } = useFormikContext<FormikValues>();
  const [showAsset, setShowAsset] = useState(false);
  const [asset, setAsset] = useState<IAsset>();
  const [implantAssets, setImplantAssets] = useState<IAsset[]>([]);
  const caseLevelsTypes = caseUtils.getValidCaseLevelsTypes(props.activeCase.levels);
  const [disablePeerReviewer, setDisablePeerReviewer] = useState(false);
  const planApprovedAt = date.parseCalendarDateFromString(props?.plan?.approvedAt as string);
  const is11453ScrewAvailable = form19Func.check11453ScrewAvailable(
    planApprovedAt,
    formikValues?.productDates?.alifXRotationLockStartDate as Date,
  );

  const caseRiskAssessmentTypeOptions = [
    {
      key: CaseRiskAssessmentType.CaseReviewQaReview,
      value: format.formatCaseRiskAssessmentType(CaseRiskAssessmentType.CaseReviewQaReview),
    },
    {
      key: CaseRiskAssessmentType.QaReviewOnly,
      value: format.formatCaseRiskAssessmentType(CaseRiskAssessmentType.QaReviewOnly),
    },
  ];

  const { loading: assetsLoading } = useQuery(FIND_ASSETS, {
    variables: {
      caseId: props.activeCase.caseId,
      planId: props.plan.planId,
      assetTypeFilter: [...form19Func.getValidImplantAssets(caseLevelsTypes)],
      deletedAt: null,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setImplantAssets(data.assets);
    },
  });

  function findAsset(assetType: AssetType): IAsset | undefined {
    return implantAssets.find((asset: IAsset) => asset.assetType === assetType);
  }

  function findScrewLength(levelValues: any): InterbodyScrewLengthTypes | undefined {
    const caseImplantSpecification = levelValues.find(
      (element: any) => element.type === 'SCREW_LENGTH',
    );
    return caseImplantSpecification?.screwLength ?? undefined;
  }

  const loading = props.loading || assetsLoading;
  const disableRevisionFields = formikValues?.revision === 0;

  useEffect(() => {
    if (formikValues?.caseRiskAssessment === CaseRiskAssessmentType.QaReviewOnly) {
      setDisablePeerReviewer(true);
    } else {
      setDisablePeerReviewer(false);
    }
  }, [formikValues?.caseRiskAssessment]);

  return (
    <>
      <Box display={'flex'}>
        <Box width={'calc(100% - 400px)'}>
          <Box>
            <Box display={'flex'} my={2}>
              <Typography variant={'body1'}>
                Implant Plus Size Selected: <strong>&nbsp;+{formikValues?.plusLevelSize}mm</strong>
              </Typography>
            </Box>
            <Box display={'flex'} my={2}>
              <Checkbox
                name={'isImageSliceValid'}
                label={'Per WI-020, confirm all imaging requirements have been met'}
                disabled={props.disabled}
              />
            </Box>
            <Box display={'flex'} my={2}>
              <RadioGroup
                name={'caseRiskAssessment'}
                label={''}
                hideLabel={false}
                disabled={
                  props.disabled ||
                  (formikValues?.initialCaseRiskAssessment ===
                    CaseRiskAssessmentType.CaseReviewQaReview &&
                    formikValues?.caseRiskAssessment === CaseRiskAssessmentType.CaseReviewQaReview)
                }
                row={true}
                options={caseRiskAssessmentTypeOptions}
                onChange={(event) => {
                  const riskAssessment = event.target.value;
                  if (riskAssessment === CaseRiskAssessmentType.QaReviewOnly) {
                    setFieldValue('peerReviewerId', '');
                    setFieldValue('caseRiskAssessment', CaseRiskAssessmentType.QaReviewOnly);
                    setDisablePeerReviewer(true);
                  } else {
                    setFieldValue('caseRiskAssessment', CaseRiskAssessmentType.CaseReviewQaReview);
                    setDisablePeerReviewer(false);
                  }
                }}
              />
            </Box>
            <Box display={'flex'} my={4}>
              <SelectField
                name={'peerReviewerId'}
                label={'Select Peer Reviewer'}
                hideNone={false}
                menuItems={props.peerReviewers}
                disabled={props.disabled || disablePeerReviewer}
              />
            </Box>

            <Box display={'flex'} justifyContent={'space-between'} my={2}>
              <Box display={'flex'} width={160}>
                <TextField
                  name={'revision'}
                  label={'Form-019 Revision#'}
                  multiline={false}
                  disabled={true}
                  shrink={true}
                />
              </Box>
              <Box display={'flex'} width={600}>
                <TextField
                  name={'description'}
                  label={'Form-019 Revision Description'}
                  fullWidth={true}
                  multiline={false}
                  disabled={props.disabled || disableRevisionFields}
                  shrink={true}
                />
              </Box>
            </Box>
            {formikValues?.levels?.length ? (
              <>
                <Box my={3} />
                <FieldArray name="levels">
                  {() => {
                    return (
                      <Box>
                        {formikValues?.levels?.map((level: any, levelIndex: number) => {
                          const isPlusImplantExcluded =
                            planUtils.checkPlusSizeImplantExcludedForLevel(
                              level.level.levelType,
                              props.validCaseExcludedImplants,
                            );

                          return (
                            <Box mb={4}>
                              <Typography variant={'h4'}>
                                {format.formatLevelType(level.level.levelType)} &mdash;{' '}
                                {format.formatPartType(level.level.partType)}
                                {isPlusImplantExcluded ? ' — Plus Implant Excluded' : ''}
                              </Typography>
                              <Typography variant={'h6'}>
                                {[PartType.ALIF_X_TWO_UP, PartType.ALIF_X_TWO_DOWN].includes(
                                  level.level?.partType,
                                )
                                  ? format.formatInterbodyScrewLengthTypes(
                                      findScrewLength(level.values),
                                      is11453ScrewAvailable,
                                    )
                                  : null}
                              </Typography>
                              <FieldArray name="values">
                                {() => {
                                  return (
                                    <Table>
                                      <TableHead>
                                        <TableRow>
                                          <TableHeadCell>Attribute</TableHeadCell>
                                          <TableHeadCell>
                                            Minus
                                            <IconFontButton
                                              onClick={() => {
                                                setAsset(
                                                  findAsset(
                                                    (level.level.levelType + '_MINUS') as AssetType,
                                                  ),
                                                );
                                                setShowAsset(true);
                                              }}
                                              icon={faEye}
                                            />
                                          </TableHeadCell>
                                          <TableHeadCell>
                                            Plan
                                            <IconFontButton
                                              onClick={() => {
                                                setAsset(
                                                  findAsset(
                                                    (level.level.levelType + '') as AssetType,
                                                  ),
                                                );
                                                setShowAsset(true);
                                              }}
                                              icon={faEye}
                                            />
                                          </TableHeadCell>
                                          <TableHeadCell>
                                            Plus
                                            <IconFontButton
                                              onClick={() => {
                                                setAsset(
                                                  findAsset(
                                                    (level.level.levelType + '_PLUS') as AssetType,
                                                  ),
                                                );
                                                setShowAsset(true);
                                              }}
                                              icon={faEye}
                                            />
                                          </TableHeadCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {level.values.map((value: any, valueIndex: number) => {
                                          return (
                                            <>
                                              {value.type === 'MANUAL_ATTRIBUTE' ? (
                                                <Form19ManualAttributeTableRow
                                                  key={`${levelIndex}.${valueIndex}`}
                                                  value={value}
                                                  valueIndex={valueIndex}
                                                  levelIndex={levelIndex}
                                                  disabled={props.disabled}
                                                  partType={level.level.partType}
                                                />
                                              ) : null}
                                              {value.type === 'PART_ATTRIBUTE' ? (
                                                <Form19PartAttributeTableRow
                                                  key={`${levelIndex}.${valueIndex}`}
                                                  value={value}
                                                  valueIndex={valueIndex}
                                                  levelIndex={levelIndex}
                                                  disabled={props.disabled}
                                                />
                                              ) : null}
                                              {value.type === 'ANGLE' ? (
                                                <Form19AngleTableRow
                                                  key={`${levelIndex}.${valueIndex}`}
                                                  value={value}
                                                  levelIndex={levelIndex}
                                                  valueIndex={valueIndex}
                                                  disabled={props.disabled}
                                                />
                                              ) : null}
                                              {value.type === 'NOTE' ? (
                                                <Form19NoteTableRow
                                                  key={`${levelIndex}.${valueIndex}`}
                                                  value={value}
                                                  levelIndex={levelIndex}
                                                  valueIndex={valueIndex}
                                                  disabled={props.disabled}
                                                />
                                              ) : null}
                                              {value.type === 'REVISION' ? (
                                                <Form19RevisionTableRow
                                                  key={`${levelIndex}.${valueIndex}`}
                                                  value={value}
                                                  levelIndex={levelIndex}
                                                  valueIndex={valueIndex}
                                                  disabled={props.disabled || disableRevisionFields}
                                                />
                                              ) : null}
                                              {value.type === 'ADDITIONAL_IMAGE' ? (
                                                <Form19AdditionalImageTableRow
                                                  key={`${levelIndex}.${valueIndex}`}
                                                  value={value}
                                                  levelIndex={levelIndex}
                                                  valueIndex={valueIndex}
                                                  disabled={props.disabled}
                                                  onRemove={props.onRemove}
                                                  loading={props.loading}
                                                />
                                              ) : null}
                                            </>
                                          );
                                        })}
                                      </TableBody>
                                    </Table>
                                  );
                                }}
                              </FieldArray>
                            </Box>
                          );
                        })}
                      </Box>
                    );
                  }}
                </FieldArray>
              </>
            ) : null}
            {props.errors.length ? (
              <>
                <Box display={'flex'} my={2}>
                  <Checkbox
                    name={'isNonConformance'}
                    label={'Accept Form-019 with non-conformance'}
                    disabled={props.disabled}
                  />
                </Box>
                <Box display={'flex'} my={2}>
                  <TextField
                    name={'nonConformanceReason'}
                    label={'Non Conformance Acceptance Reason and Non Conformance #'}
                    multiline={true}
                    disabled={props.disabled}
                  />
                </Box>
              </>
            ) : null}
            {props.canQualityApproveForm19 &&
            props.activeCase.stage === CaseStageType.Production ? (
              <Box display={'flex'} my={2}>
                <TextField
                  name={'regenerateReason'}
                  label={'Form-19 Regenerate Reason'}
                  multiline={true}
                  disabled={false}
                  required={true}
                />
              </Box>
            ) : null}
          </Box>
        </Box>
        <Box width={400} mx={2}>
          <Typography>
            {props.errors.length ? (
              <strong>Issues</strong>
            ) : (
              <Alert severity={'success'}>No issues found</Alert>
            )}
          </Typography>
          {loading ? (
            <ul style={{ listStyle: 'none' }}>
              {props.errors.map((form19Error, index) => (
                <li key={index}>
                  <Typography
                    gutterBottom={true}
                    style={{
                      color: theme.palette.error.main,
                    }}
                    data-testid={form19Error.type}
                  >
                    {form19Func.getForm19ErrorDescription(form19Error)}
                  </Typography>
                </li>
              ))}
            </ul>
          ) : null}
          {loading && props.warningWhoCanApproveForm19 ? (
            <Box my={1}>
              <Typography>
                <Alert severity={'warning'}>
                  Only a designer, case admin or site admin can approve or un-approve this Form-19
                </Alert>
              </Typography>
            </Box>
          ) : null}
          {!loading ? (
            <ul style={{ listStyle: 'none' }}>
              <li>
                <Skeleton height={25} />
              </li>
              <li>
                <Skeleton height={25} />
              </li>
              <li>
                <Skeleton height={25} />
              </li>
            </ul>
          ) : null}
        </Box>
      </Box>
      <AssetMeshViewDialog asset={asset} open={showAsset} onClose={() => setShowAsset(false)} />
    </>
  );
}

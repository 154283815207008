import React from 'react';
//import { ImplantType } from '@workflow-nx/common';
import { Table, TableBody, TableHead, TableRow } from '@mui/material';
import { CasePathologyType } from './Tem13FillDialog.reducer';
import { TableHeadCell } from '../../../../../components/TableHeadCell';
import { CasePathologyTableRow } from './CasePathologyTableRow';

interface CasePathologyTableProps {
  casePathologies: CasePathologyType[];
  disabled: boolean;
}

export function CasePathologiesEditTable({ casePathologies, disabled }: CasePathologyTableProps) {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableHeadCell width={240}>Pathology</TableHeadCell>
          <TableHeadCell width={660}>Note</TableHeadCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {casePathologies?.map((casePathology: CasePathologyType, pathologyTypeIndex: number) => {
          return (
            <CasePathologyTableRow
              key={`${pathologyTypeIndex}`}
              pathologyType={casePathology?.pathologyType}
              pathologySelected={casePathology?.pathologySelected}
              pathologyTypeIndex={pathologyTypeIndex}
              disabled={disabled}
            />
          );
        })}
      </TableBody>
    </Table>
  );
}

import {
  ILevels,
  format,
  LevelType,
  AssetType,
  IAsset,
  ICase,
  ImplantType,
  PartType,
  ImplantOrientation,
  ICaseReportStatement,
  ICasePathology,
  PathologyType,
  StatementType,
  Statement,
  caseUtils,
  SagittalGoalType,
  CoronalGoalType,
  IPostOpAnalysis,
  CorrectionPlanRuleType,
  ICaseReportCorrectionPlanRule,
  ISurgeonPreferences,
  IPlan,
  CaseSpineProfile,
  IUser,
} from '@workflow-nx/common';
import {
  CaseReportStatementType,
  CasePathologyType,
  LevelAssets,
  InputAssetType,
  CaseReportCorrectionPlanRuleType,
  Tem13CaseData,
  LumbarMeasurementType,
  ProposedLumbarMeasurementType,
} from '../views/cases/CaseView/CasePlanningTab/Tem13FillDialog/Tem13FillDialog.reducer';
import { sortBy, toNumber } from 'lodash';
import { IMPLANT_TYPE_CUSTOM_SORT, MEASUREMENT_TYPE_CUSTOM_SORT } from './surgeonPreferences';

export const PATHOLOGY_TYPE_CUSTOM_SORT = [
  PathologyType.Osteomyelitis,
  PathologyType.DiscDegeneration,
  PathologyType.AdultDegenerativeScoliosis,
  PathologyType.AdjacentSegmentDisease,
  PathologyType.Spondylolisthesis,
  PathologyType.TransitionalAnatomy,
  PathologyType.ExtraVertebrae,
  PathologyType.EndplateDeformityAtTreatmentLevels,
  PathologyType.UniqueAnatomy,
  PathologyType.RevisionOfInterbodyImplant,
  PathologyType.RevisionOfOtherPriorHardware,
  PathologyType.Other,
];

export const STATEMENT_CUSTOM_SORT = [
  Statement.DiscHeightsReasonable,
  Statement.CageFootprintFit,
  Statement.VertebralBodiesEdited,
  Statement.TlifCKyphotic,
  Statement.AlifxScrewTrajectoriesAcceptable,
];

export interface ILevelsHeaderData {
  levelType: LevelType;
  partType: PartType;
  levelCode: string;
  implantLevelType: ImplantType;
  direction: string;
  implantNameDimFile: string;
  implantNamePart: string;
  orientation?: ImplantOrientation | null;
}

export type CaseReportAlignmentGoalType = {
  parameterType: 'LORDOTIC' | 'CORONAL';
  correctionParameter: string;
  alignmentGoal: string;
  targetFormula: string;
  calculation: string;
  correctionNeeded: string;
};

type MainSettings = {
  pelvicIncidenceMinusPlanLumbarLordosis: { max: number };
  planLumbarLordosis: { condition?: string; min: number; max: number };
  posteriorDiscHeight: { condition?: string; min: number; max: number };
};

type DiscLumbarLordosisSettings = {
  [key: string]: { min: number; max: number };
};

type CaseReportRulesMinMaxSettings = {
  main: MainSettings;
  discLumbarLordosis: DiscLumbarLordosisSettings;
};

export const CASE_REPORT_RULES_MIN_MAX_SETTINGS: CaseReportRulesMinMaxSettings = {
  main: {
    pelvicIncidenceMinusPlanLumbarLordosis: { max: 20 },
    planLumbarLordosis: { condition: '<PRE_OP', min: 40, max: 70 },
    posteriorDiscHeight: { condition: '<PRE_OP', min: 4, max: 10 },
  },
  discLumbarLordosis: {
    L1_L2: { min: 2, max: 10 },
    L2_L3: { min: 2, max: 10 },
    L3_L4: { min: 2, max: 10 },
    L4_L5: { min: 2, max: 15 },
    L5_S1: { min: 2, max: 20 },
    L5_L6: { min: 2, max: 20 },
    L6_S1: { min: 2, max: 20 },
    L4_S1: { min: 2, max: 20 },
  },
};

function checkPelvicIncidenceMinusPlanLumbarLordosisRule(
  pelvicIncidence?: number | null,
  planLumbarLordosis?: number,
  mainSettings?: MainSettings,
  note?: string,
): CaseReportCorrectionPlanRuleType {
  const max = mainSettings?.pelvicIncidenceMinusPlanLumbarLordosis?.max;
  const planValue =
    pelvicIncidence && planLumbarLordosis ? pelvicIncidence - planLumbarLordosis : 0;
  const flagged = !!(max && planValue > max);

  return {
    ruleType: CorrectionPlanRuleType.PelvicIncidenceMinusPlanLumbarLordosis,
    planValue,
    condition: undefined,
    preOp: undefined,
    min: undefined,
    max,
    flagged,
    note,
  };
}

function checkPlanLumbarLordosisRule(
  planLumbarLordosis: number,
  preOpLumbarLordosis: number,
  settings: { condition?: string; min: number; max: number },
  note?: string,
): CaseReportCorrectionPlanRuleType {
  const { min, max } = settings;
  const flagged =
    planLumbarLordosis < preOpLumbarLordosis ||
    planLumbarLordosis < min ||
    planLumbarLordosis > max;

  return {
    ruleType: CorrectionPlanRuleType.PlanLumbarLordosis,
    planValue: planLumbarLordosis,
    condition: settings.condition,
    preOp: preOpLumbarLordosis,
    min,
    max,
    flagged,
    note,
  };
}

function checkPosteriorDiscHeightRule(
  level: LevelType,
  mainSettings: MainSettings,
  preOp?: IPostOpAnalysis,
  plan?: IPostOpAnalysis,
  note?: string,
): CaseReportCorrectionPlanRuleType {
  const posteriorDiscHeightSettings = mainSettings.posteriorDiscHeight;
  const preOpSegmentalPosteriorHeight = preOp?.segmentalPosteriorHeight[level];
  const planSegmentalPosteriorHeight = plan?.segmentalPosteriorHeight[level];
  const flagged =
    planSegmentalPosteriorHeight < preOpSegmentalPosteriorHeight ||
    planSegmentalPosteriorHeight < posteriorDiscHeightSettings.min ||
    planSegmentalPosteriorHeight > posteriorDiscHeightSettings.max;

  return {
    ruleType: CorrectionPlanRuleType.PosteriorDiscHeight,
    level,
    planValue: planSegmentalPosteriorHeight,
    condition: posteriorDiscHeightSettings.condition,
    preOp: preOpSegmentalPosteriorHeight,
    min: posteriorDiscHeightSettings.min,
    max: posteriorDiscHeightSettings.max,
    flagged,
    note,
  };
}

function checkDiscLumbarLordosisRule(
  level: LevelType,
  discLumbarLordosis: DiscLumbarLordosisSettings,
  plan?: IPostOpAnalysis,
  note?: string,
): CaseReportCorrectionPlanRuleType {
  const levelLumbarLordosis = discLumbarLordosis[level];
  const planSegmentalLumbarLordosis = plan?.segmentalLumbarLordosis?.[level] ?? undefined;
  const flagged =
    planSegmentalLumbarLordosis < levelLumbarLordosis.min ||
    planSegmentalLumbarLordosis > levelLumbarLordosis.max;

  return {
    ruleType: CorrectionPlanRuleType.DiscLumbarLordosis,
    level,
    planValue: planSegmentalLumbarLordosis,
    condition: undefined,
    preOp: undefined,
    min: levelLumbarLordosis.min,
    max: levelLumbarLordosis.max,
    flagged,
    note,
  };
}

const getNoteForRuleType = (
  caseReportCorrectionPlanRules: ICaseReportCorrectionPlanRule[],
  ruleType: CorrectionPlanRuleType,
  level?: LevelType,
): string | undefined => {
  const rule = level
    ? caseReportCorrectionPlanRules.find(
        (rule) => rule.ruleType === ruleType && rule.level === level,
      )
    : caseReportCorrectionPlanRules.find((rule) => rule.ruleType === ruleType);
  return rule ? rule.note : '';
};

export function applyCorrectionPlanRules(
  levels: ILevels,
  pelvicIncidence?: number | null,
  preOp?: IPostOpAnalysis,
  plan?: IPostOpAnalysis,
  caseReportCorrectionPlanRules?: ICaseReportCorrectionPlanRule[],
): CaseReportCorrectionPlanRuleType[] {
  const correctionPlanResults: CaseReportCorrectionPlanRuleType[] = [];
  const mainSettings: MainSettings = CASE_REPORT_RULES_MIN_MAX_SETTINGS.main;
  const discLumbarLordosis: DiscLumbarLordosisSettings =
    CASE_REPORT_RULES_MIN_MAX_SETTINGS.discLumbarLordosis;

  const preOpLumbarLordosis = preOp?.lumbarLordosis ?? 0;
  const planLumbarLordosis = plan?.lumbarLordosis ?? 0;

  const pelvicIncidenceMinusPlanLumbarLordosisRuleNote = getNoteForRuleType(
    caseReportCorrectionPlanRules ?? [],
    CorrectionPlanRuleType.PelvicIncidenceMinusPlanLumbarLordosis,
  );
  correctionPlanResults.push(
    checkPelvicIncidenceMinusPlanLumbarLordosisRule(
      pelvicIncidence,
      planLumbarLordosis,
      mainSettings,
      pelvicIncidenceMinusPlanLumbarLordosisRuleNote,
    ),
  );

  const planLumbarLordosisRuleNote = getNoteForRuleType(
    caseReportCorrectionPlanRules ?? [],
    CorrectionPlanRuleType.PlanLumbarLordosis,
  );
  correctionPlanResults.push(
    checkPlanLumbarLordosisRule(
      planLumbarLordosis,
      preOpLumbarLordosis,
      mainSettings.planLumbarLordosis,
      planLumbarLordosisRuleNote,
    ),
  );

  const caseLevels = caseUtils.getValidCaseLevels(levels);

  caseLevels.forEach((level) => {
    const posteriorDiscHeightRuleNote = getNoteForRuleType(
      caseReportCorrectionPlanRules ?? [],
      CorrectionPlanRuleType.PosteriorDiscHeight,
      level,
    );

    correctionPlanResults.push(
      checkPosteriorDiscHeightRule(level, mainSettings, preOp, plan, posteriorDiscHeightRuleNote),
    );
  });

  caseLevels.forEach((level) => {
    const discLumbarLordosisRuleNote = getNoteForRuleType(
      caseReportCorrectionPlanRules ?? [],
      CorrectionPlanRuleType.DiscLumbarLordosis,
      level,
    );

    correctionPlanResults.push(
      checkDiscLumbarLordosisRule(level, discLumbarLordosis, plan, discLumbarLordosisRuleNote),
    );
  });

  return correctionPlanResults;
}

export const absoluteDifference = (
  a?: number | string,
  b?: number | string,
): number | undefined => {
  if (isNaN(toNumber(a)) || isNaN(toNumber(b)) || a === '' || b === '') {
    return undefined;
  } else {
    return Math.abs(toNumber(a) - toNumber(b));
  }
};

export const getCasePathologies = (casePathologies: ICasePathology[]): CasePathologyType[] => {
  const pathologies: CasePathologyType[] = [];
  const pathologyTypes = Object.values(PathologyType);

  pathologyTypes.forEach((pathologyType) => {
    const casePathology = casePathologies.find(
      (casePathology) => casePathology.pathologyType === pathologyType,
    );

    if (casePathology) {
      pathologies.push({
        pathologyType: casePathology.pathologyType,
        pathologySelected: true,
        pathologyNote: casePathology.pathologyNote || '',
      });
    } else {
      pathologies.push({
        pathologyType: pathologyType,
        pathologySelected: false,
        pathologyNote: '',
      });
    }
  });

  return sortBy(pathologies, (item) => PATHOLOGY_TYPE_CUSTOM_SORT.indexOf(item.pathologyType));
};

export const getCaseReportStatements = (
  caseReportStatements: ICaseReportStatement[],
): CaseReportStatementType[] => {
  const reportStatements: CaseReportStatementType[] = [];
  const statements = Object.values(Statement);

  statements.forEach((statement) => {
    const caseReportStatement = caseReportStatements.find(
      (caseReportStatement) => caseReportStatement.statement === statement,
    );

    if (caseReportStatement) {
      reportStatements.push({
        statementType: caseReportStatement.statementType,
        statement: caseReportStatement.statement,
        response: caseReportStatement.response,
        note: caseReportStatement.note || '',
      });
    } else {
      let statementType;
      if ([Statement.DiscHeightsReasonable].includes(statement)) {
        statementType = StatementType.CorrectionPlanning;
      } else {
        statementType = StatementType.ImplantDesign;
      }

      reportStatements.push({
        statementType: statementType,
        statement: statement,
        response: undefined,
        note: '',
      });
    }
  });

  return sortBy(reportStatements, (item) => STATEMENT_CUSTOM_SORT.indexOf(item.statement));
};

export function getAssetTypesByImplantAndLevelType(
  implantType: ImplantType,
  levelType: LevelType,
): AssetType[] {
  let assetsByImplantAndLevelType: AssetType[] = [];

  switch (implantType) {
    case ImplantType.ALIF:
    case ImplantType.LLIF:
    case ImplantType.TLIFO:
      assetsByImplantAndLevelType = [
        `CASE_REPORT_${levelType}_IMPLANT_APP_IMAGE`,
        `CASE_REPORT_${levelType}_IMPLANT_MINI_CHECK_TOP_IMAGE`,
        `CASE_REPORT_${levelType}_IMPLANT_MINI_CHECK_BOTTOM_IMAGE`,
      ] as AssetType[];
      break;

    case ImplantType.TLIFC:
      assetsByImplantAndLevelType = [
        `CASE_REPORT_${levelType}_IMPLANT_APP_IMAGE`,
        `CASE_REPORT_${levelType}_IMPLANT_POSITION_IMAGE`,
        `CASE_REPORT_${levelType}_IMPLANT_MINI_CHECK_TOP_IMAGE`,
        `CASE_REPORT_${levelType}_IMPLANT_MINI_CHECK_BOTTOM_IMAGE`,
      ] as AssetType[];
      break;

    case ImplantType.ALIFX:
      assetsByImplantAndLevelType = [
        `CASE_REPORT_${levelType}_IMPLANT_APP_IMAGE`,
        `CASE_REPORT_${levelType}_IMPLANT_MINI_CHECK_TOP_IMAGE`,
        `CASE_REPORT_${levelType}_IMPLANT_MINI_CHECK_BOTTOM_IMAGE`,
        `CASE_REPORT_${levelType}_IMPLANT_SCREW_VERIFICATION_IMAGE`,
        `CASE_REPORT_${levelType}_IMPLANT_SCREW_ANTERIOR_POSTION_IMAGE`,
        `CASE_REPORT_${levelType}_IMPLANT_SCREW_LATERAL_POSTION_IMAGE`,
      ] as AssetType[];
      break;
    default:
      break;
  }

  return assetsByImplantAndLevelType;
}

export const getCaseLevelTem13Assets = (activeCase: ICase, caseAssets: IAsset[]): LevelAssets[] => {
  const levelAssets: LevelAssets[] = [];

  const validCaseLevels = caseUtils.getValidCaseLevelsWithPartTypes(activeCase.levels);

  validCaseLevels.forEach((caseLevel) => {
    const inputAssetType: InputAssetType[] = [];
    const assetTypesByImplantAndLevelType = getAssetTypesByImplantAndLevelType(
      caseLevel.implantLevelType as ImplantType,
      caseLevel.levelType,
    );

    assetTypesByImplantAndLevelType.forEach((assetTypeByImplant) => {
      const asset = caseAssets.find((asset: IAsset) => asset.assetType === assetTypeByImplant);

      inputAssetType.push({
        level: caseLevel.levelType,
        inputAsset: asset ? undefined : null,
        deletedAssetId: 0,
        assetType: assetTypeByImplant,
        asset: asset ?? undefined,
      });
    });

    levelAssets.push({
      levelType: caseLevel.levelType,
      implantType: caseLevel.implantLevelType as ImplantType,
      inputAssets: inputAssetType,
    });
  });

  return levelAssets;
};

export function getAllTem13AssetTypes(caseLevelsInfo: ILevelsHeaderData[]): AssetType[] {
  const allTem13AssetTypes: AssetType[] = [
    AssetType.CaseReportVertebraePreEditImage,
    AssetType.CaseReportVertebraePostEditImage,
    AssetType.CaseReportImplantPreEditImage,
    AssetType.CaseReportImplantPostEditImage,
    AssetType.CaseReportStandingXrayLateralMeasured,
  ];

  for (const caseLevel of caseLevelsInfo) {
    const assetTypes = getAssetTypesByImplantAndLevelType(
      caseLevel.implantLevelType as ImplantType,
      caseLevel.levelType,
    );
    allTem13AssetTypes.push(...assetTypes);
  }

  return allTem13AssetTypes;
}

function getTargetLumbarLordosis(
  patientAge: number,
  pelvicIncidence: number,
  sagittalGoalType: SagittalGoalType,
) {
  if (!sagittalGoalType || (sagittalGoalType === SagittalGoalType.AgeAdjusted && !patientAge)) {
    return 0;
  }

  let targetLumbarLordosis;

  switch (sagittalGoalType) {
    case SagittalGoalType.AgeAdjusted:
      targetLumbarLordosis = pelvicIncidence - 3 - (patientAge - 55) / 2;
      break;
    case SagittalGoalType.MinimalMismatch:
      targetLumbarLordosis = pelvicIncidence;
      break;
    case SagittalGoalType.GapScore:
      targetLumbarLordosis = pelvicIncidence * 0.62 + 29;
      break;
    default:
      targetLumbarLordosis = 0;
  }

  return targetLumbarLordosis;
}

export function generateCaseAlignmentGoals(
  levels: ILevels,
  sagittalGoalType: SagittalGoalType,
  patientAge: number,
  pelvicIncidence: number,
  coronalGoalType?: CoronalGoalType,
  preOp?: IPostOpAnalysis,
): CaseReportAlignmentGoalType[] {
  const caseAlignmentGoals: CaseReportAlignmentGoalType[] = [];
  let targetFormula = '';
  let calculation = '';
  let correctionNeeded = '';
  let preOpCoronalAngle;
  let neededCoronalAngle = '';
  const visualCheck = 'Visual Check';

  const caseLevels = caseUtils.getValidCaseLevels(levels);

  if (sagittalGoalType) {
    const preOpLordosis = preOp?.lumbarLordosis ? preOp?.lumbarLordosis : 0;
    const targetLumbarLordosis = getTargetLumbarLordosis(
      patientAge,
      pelvicIncidence,
      sagittalGoalType,
    );
    const lordosisCorrection = targetLumbarLordosis - preOpLordosis;
    const sign = lordosisCorrection === 0 ? '' : lordosisCorrection > 0 ? '+' : '-';
    const lordosisCorrectionNeeded = `${sign}${Math.abs(lordosisCorrection).toFixed(2)}`;
    correctionNeeded = `Target LL ${targetLumbarLordosis.toFixed(
      2,
    )}° \nPre-op LL ${preOpLordosis.toFixed(2)}° \nNeeded ${lordosisCorrectionNeeded}°`;

    switch (sagittalGoalType) {
      case SagittalGoalType.AgeAdjusted:
        targetFormula = `LL = (PI - 3) - (age - 55)/2`;
        calculation = `LL = (${pelvicIncidence} - 3) - (${patientAge} - 55)/2`;
        break;
      case SagittalGoalType.MinimalMismatch:
        targetFormula = `LL = PI`;
        calculation = `LL = ${pelvicIncidence}`;
        break;
      case SagittalGoalType.GapScore:
        targetFormula = `LL = PI * 0.62 + 29`;
        calculation = `LL = ${pelvicIncidence} * 0.62 + 29`;
        break;
      case SagittalGoalType.AlternativeSupportivePlanningReference:
      case SagittalGoalType.Other:
        targetFormula = visualCheck;
        calculation = visualCheck;
        correctionNeeded = '-';
        break;
      default:
    }
    caseAlignmentGoals.push({
      parameterType: 'LORDOTIC',
      correctionParameter: 'Lordotic Correction',
      alignmentGoal: format.formatSagittalGoalType(sagittalGoalType),
      targetFormula: targetFormula,
      calculation: calculation,
      correctionNeeded: correctionNeeded,
    });
  }

  if (coronalGoalType) {
    switch (coronalGoalType) {
      case CoronalGoalType.SuperiorL1EndplateParallelToSacrum:
        preOpCoronalAngle = preOp?.lumbarCoronalAngulation ? preOp?.lumbarCoronalAngulation : 0;
        neededCoronalAngle =
          preOpCoronalAngle === 0
            ? `0`
            : preOpCoronalAngle > 0
            ? `-${preOpCoronalAngle.toFixed(2)}`
            : `+${Math.abs(preOpCoronalAngle).toFixed(2)}`;
        targetFormula = `Pre-Op Coronal Angle Total`;
        calculation = `Coronal Angle ${preOpCoronalAngle.toFixed(2)}`;
        correctionNeeded = `Needed ${neededCoronalAngle}°`;
        break;
      case CoronalGoalType.SuperiorL1EndplateParallelToFloor:
      case CoronalGoalType.SuperiorEndplateOfHighestTreatedLevelParallelToSacrum:
      case CoronalGoalType.Other:
        targetFormula = visualCheck;
        calculation = visualCheck;
        correctionNeeded = '-';
        break;
      case CoronalGoalType.ParallelDiscSpaceAtTreatmentLevels:
        caseLevels.forEach((level) => {
          const preOpSegmentalCoronalAngle = preOp?.segmentalCoronalAngle[level];
          const neededSegmentalCoronalAngle =
            preOpSegmentalCoronalAngle === 0
              ? `0`
              : preOpSegmentalCoronalAngle > 0
              ? `-${preOpSegmentalCoronalAngle.toFixed(2)}`
              : `+${Math.abs(preOpSegmentalCoronalAngle).toFixed(2)}`;
          targetFormula = `${format.formatLevelType(level)}`;
          calculation = `Pre-Op Segmental Coronal Angle ${preOpSegmentalCoronalAngle.toFixed(2)}`;
          correctionNeeded = `Needed ${neededSegmentalCoronalAngle}°`;

          caseAlignmentGoals.push({
            parameterType: 'CORONAL',
            correctionParameter: 'Coronal Correction',
            alignmentGoal: format.formatCoronalGoalType(coronalGoalType),
            targetFormula: targetFormula,
            calculation: calculation,
            correctionNeeded: correctionNeeded,
          });
        });
        break;
    }
    if (coronalGoalType !== CoronalGoalType.ParallelDiscSpaceAtTreatmentLevels) {
      caseAlignmentGoals.push({
        parameterType: 'CORONAL',
        correctionParameter: 'Coronal Correction',
        alignmentGoal: format.formatCoronalGoalType(coronalGoalType),
        targetFormula: targetFormula,
        calculation: calculation,
        correctionNeeded: correctionNeeded,
      });
    }
  }

  return caseAlignmentGoals;
}

export const getCaseLevelSurgeonPreferences = (
  caseLevels: ILevels,
  preferences: ISurgeonPreferences,
): ISurgeonPreferences => {
  const caseLevelsPreferences: ISurgeonPreferences = { ...preferences };
  const foundImplantTypes = caseUtils.getValidImplantTypes(caseLevels);

  const sortedPreferredProcedures = sortBy(preferences?.preferredProcedures, (item) =>
    IMPLANT_TYPE_CUSTOM_SORT.indexOf(item),
  );

  const sortedSurgeonPreferenceImplants = sortBy(
    preferences?.surgeonPreferenceImplants.filter((item) =>
      foundImplantTypes.includes(item.implantType),
    ),
    (item) => IMPLANT_TYPE_CUSTOM_SORT.indexOf(item.implantType),
  );

  const sortedSurgeonPreferenceImplantMeasurements = sortBy(
    preferences?.surgeonPreferenceImplantMeasurements.filter((item) =>
      foundImplantTypes.includes(item.implantType),
    ),
    (item) => [
      IMPLANT_TYPE_CUSTOM_SORT.indexOf(item.implantType),
      MEASUREMENT_TYPE_CUSTOM_SORT.indexOf(item.measurementType),
    ],
  );

  caseLevelsPreferences.preferredProcedures = sortedPreferredProcedures;
  caseLevelsPreferences.surgeonPreferenceImplants = sortedSurgeonPreferenceImplants;
  caseLevelsPreferences.surgeonPreferenceImplantMeasurements =
    sortedSurgeonPreferenceImplantMeasurements;

  return caseLevelsPreferences;
};

export function getTem13CaseData(activeCase: ICase, casePlan?: IPlan): Tem13CaseData {
  return {
    caseId: activeCase.caseId,
    caseNumber: activeCase.number,
    caseShortNumber: activeCase?.shortNumber,
    assignedUser: activeCase.assignedUser,
    fieldRepUser: activeCase.fieldRepUser,
    surgeonUser: activeCase.surgeonUser,
    caseLevels: activeCase.caseLevels,
    patientBirthDate: activeCase.patient.birthDate,
    patientGender: activeCase.patient.gender,
    mrn: activeCase?.patient?.mrn ? activeCase?.patient?.mrn : undefined,
    planId: casePlan?.planId ? casePlan?.planId : 0,
    planName: casePlan?.name ? casePlan?.name : '',
    planDescription: casePlan?.description ? casePlan?.description : '',
    plusLevelSize: casePlan?.plusLevelSize ? casePlan?.plusLevelSize : 0,
    caseSpineProfile: activeCase.spineProfile,
    caseSpineType: activeCase.spineType,
  };
}

export function generateLumbarMeasurementDataset(
  data: IPostOpAnalysis,
  caseSpineProfile: CaseSpineProfile,
): LumbarMeasurementType[] {
  const validLevels = caseUtils.getLevelsSortedByHierarchy(caseSpineProfile, 'desc');

  return validLevels.map((level) => ({
    level,
    segmentalLumbarLordosis: data?.segmentalLumbarLordosis?.[level] ?? 0,
    angleToS1: data?.angleToS1?.[level] ?? 0,
    segmentalCoronalAngle: data?.segmentalCoronalAngle?.[level] ?? 0,
    segmentalAnteriorHeight: data?.segmentalAnteriorHeight?.[level] ?? 0,
    segmentalPosteriorHeight: data?.segmentalPosteriorHeight?.[level] ?? 0,
  }));
}

export function getProposedLumbarMeasurements(
  preOp: IPostOpAnalysis,
  plan: IPostOpAnalysis,
  caseSpineProfile: CaseSpineProfile,
): ProposedLumbarMeasurementType {
  return {
    preOpLumbarMeasurements: generateLumbarMeasurementDataset(preOp, caseSpineProfile),
    planLumbarMeasurements: generateLumbarMeasurementDataset(plan, caseSpineProfile),
  };
}

export const checkGuardrailsFlagged = (
  caseReportCorrectionPlanRules: CaseReportCorrectionPlanRuleType[],
): boolean => {
  return caseReportCorrectionPlanRules.some((rule) => rule.flagged === true);
};

export function isValidDesignEngineerUser(userId?: number, currentUser?: IUser) {
  if (!currentUser) {
    return false;
  }

  if (userId === currentUser?.userId) {
    return true;
  }

  return false;
}

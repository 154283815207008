import { useState } from 'react';

import { faEye, faTrash } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles } from '@mui/styles';

import { IAsset, ValidFileExtensions } from '@workflow-nx/common';
import { Control, FieldValues } from 'react-hook-form';
import {
  Button,
  Grid,
  Stack,
  Typography,
  IconButton,
  LinearProgress,
  Theme,
  Box,
} from '@mui/material';
import { DropzoneRHF, ErrorMessageRHF } from '@workflow-nx/ui';
import React from 'react';
import { FileInfoView } from './FileInfoView';
import { Tem13ImageViewDialog } from '../../../../../components/Tem13ImageViewDialog';

const useStyles = makeStyles((theme: Theme) => ({
  gridCell: {
    backgroundColor: 'white',
    height: 50,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  gridCellOverflow: {
    backgroundColor: 'white',
    height: 50,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
}));

export function Tem13FileRow(props: {
  id: string;
  label: string;
  editing: boolean;
  asset?: IAsset;
  inputAssetFile?: File | null;
  onClick: () => void;
  control: Control<FieldValues>;
  validFileExtensions: ValidFileExtensions[];
}) {
  const styles = useStyles();
  const [openTem13ImageViewer, setOpenTem13ImageViewer] = useState(false);

  return (
    <>
      <Grid item container xs={12} spacing={1} alignItems={'center'}>
        <Grid item xs={10} md={10}>
          {!props.editing && props.asset ? <FileInfoView asset={props.asset} /> : null}
          {!props.editing && !props.asset ? (
            <Typography color={'textSecondary'}>No file</Typography>
          ) : null}
          {props.editing && props.asset ? (
            <Stack direction={'row'} alignItems={'center'}>
              <Typography>{props.asset?.fileName}</Typography>
              {/* <Button variant={'text'} onClick={props.onClick}>
                Clear
              </Button> */}
            </Stack>
          ) : null}
          {props.editing && !props.asset ? (
            <DropzoneRHF
              name={props.id}
              control={props.control as unknown as Control<FieldValues>}
              validFileExtensions={props.validFileExtensions}
              variant={'small'}
            />
          ) : null}
        </Grid>
        {/* <Grid item xs={3} md={3} display={'flex'} justifyContent={'center'}>
          <ErrorMessageRHF
            name={props.id}
            control={props.control as unknown as Control<FieldValues>}
          />
        </Grid> */}
        <Grid item xs={1} md={1} display={'flex'} justifyContent={'center'}>
          {props.editing && props?.asset ? (
            <Box className={styles.gridCell}>
              <Box display={'flex'} alignItems={'center'} height={50}>
                <IconButton disabled={!props.asset} onClick={props.onClick} size="large">
                  <FontAwesomeIcon icon={faTrash} />
                </IconButton>
              </Box>
            </Box>
          ) : null}
        </Grid>
        <Grid item xs={1} md={1} display={'flex'} justifyContent={'center'}>
          {props?.asset || props?.inputAssetFile ? (
            <Box className={styles.gridCell}>
              <Box display={'flex'} alignItems={'center'} height={50}>
                <IconButton
                  disabled={!props.asset && !props.inputAssetFile}
                  onClick={() => {
                    setOpenTem13ImageViewer(true);
                  }}
                  size="large"
                >
                  <FontAwesomeIcon icon={faEye} />
                </IconButton>
              </Box>
            </Box>
          ) : null}
        </Grid>
      </Grid>
      <Tem13ImageViewDialog
        open={openTem13ImageViewer}
        asset={props?.asset}
        inputAssetFile={props.inputAssetFile}
        onClose={() => setOpenTem13ImageViewer(false)}
      />
    </>
  );
}

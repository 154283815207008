import React, { useState, useEffect } from 'react';
import { format, LevelType, CorrectionPlanRuleType } from '@workflow-nx/common';
import { useFormContext, Control, FieldValues } from 'react-hook-form';
import { TableRow } from '@mui/material';
import { TableCell } from '../../../../../components/TableCell';
import { TextFieldRHF } from '@workflow-nx/ui';
import { Tem13FillDialogStateType } from './Tem13FillDialog.reducer';
export function CaseReportCorrectionPlanRuleTableRow(props: {
  correctionPlanRuleType: CorrectionPlanRuleType;
  planValue?: number;
  level?: LevelType;
  condition?: string | number;
  preOp?: number;
  min?: number;
  max?: number;
  flagged?: boolean;
  correctionPlanRuleIndex: number;
  disabled: boolean;
}) {
  const { control, setValue, resetField } = useFormContext<Tem13FillDialogStateType>();

  return (
    props?.flagged && (
      <TableRow hover>
        <TableCell>
          {props?.level ? `${format.formatLevelType(props?.level as LevelType)} - ` : ''}
          {format.formatCorrectionPlanRuleType(props.correctionPlanRuleType)}
        </TableCell>
        <TableCell>{Number(props?.planValue).toFixed(2)}</TableCell>
        <TableCell>{props?.preOp ? Number(props.preOp).toFixed(2) : 'N/A'}</TableCell>
        <TableCell>{props.min}</TableCell>
        <TableCell>{props.max}</TableCell>
        <TableCell>{props.flagged ? 'Yes' : 'No'}</TableCell>
        <TableCell>
          <TextFieldRHF
            name={`caseReportCorrectionPlanRules[${props.correctionPlanRuleIndex}].note`}
            control={control as unknown as Control<FieldValues>}
            disabled={props.disabled}
            fullWidth={true}
            multiLineRows={3}
            multiline={true}
          />
        </TableCell>
      </TableRow>
    )
  );
}

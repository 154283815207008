import React, { useState, useEffect } from 'react';
import {
  IUser,
  ICase,
  IAsset,
  AssetType,
  format,
  Tem13Error,
  SegmentationImagingAssessmentType,
  UserRoleType,
  StatementType,
  SagittalGoalType,
  CoronalGoalType,
  LordosisDifferenceRationaleType,
  ValidFileExtensions,
  SegmentationSliceThicknessType,
  CaseRiskAssessmentType,
  PlanFeedbackType,
  MedicalReviewStatusType,
  CaseReportStatusType,
} from '@workflow-nx/common';
import {
  Box,
  Skeleton,
  Typography,
  useTheme,
  SelectChangeEvent,
  Button,
  Divider,
} from '@mui/material';
import {
  SelectFieldRHF,
  TextFieldRHF,
  CheckboxRHF,
  CheckboxListFieldRHF,
  ErrorMessageRHF,
  ProgressButton,
} from '@workflow-nx/ui';
import { NumberTextFieldRHF } from '../../../../../components/NumberTextFieldRHF';
import RadioGroupRHF from '../../../../../components/RadioGroupRHF';
import { CasePathologiesEditTable } from './CasePathologiesEditTable';
import { Tem13FileRow } from './Tem13FileRow';
import { CaseReportAlignmentGoalViewTable } from './CaseReportAlignmentGoalViewTable';
import { CaseReportStatementsEditTable } from './CaseReportStatementsEditTable';
import { CaseReportCorrectionPlanRuleEditTable } from './CaseReportCorrectionPlanRuleEditTable';
import useAuth from '../../../../../hooks/useAuth';

import { useQuery } from '@apollo/client';
import _ from 'lodash';
import { Alert } from '@mui/material';
import { useFormContext, Control, FieldValues, SubmitHandler } from 'react-hook-form';
import {
  Tem13FillDialogStateType,
  LevelAssets,
  InputAssetType,
  FormActionType,
} from './Tem13FillDialog.reducer';
import {
  absoluteDifference,
  CaseReportAlignmentGoalType,
  generateCaseAlignmentGoals,
  checkGuardrailsFlagged,
} from '../../../../../utils/tem13';
import { toNumber, isNumber } from 'lodash';
import { date } from '@workflow-nx/utils';
import { eventNames } from 'process';

export function Tem13FillForm(props: {
  activeCase: ICase;
  state: Tem13FillDialogStateType;
  surgeonUser: IUser;
  disabled: boolean;
  errors: Tem13Error[];
  loading: boolean;
  tem13Users: IUser[];
  handleFormSubmit: (
    actionType: FormActionType,
  ) => (event: React.MouseEvent<HTMLButtonElement>) => void;
  activeButton: FormActionType | null;
  selfApproveCaseReportEnabled: boolean;
  sendMedicalReviewEnabled: boolean;
  sendCaseReportReviewEnabled: boolean;
}) {
  const { user: currentUser } = useAuth();
  const [tem13Assets, setTem13Assets] = useState<IAsset[]>([]);
  const [segmentationOperators, setSegmentationOperators] = useState<
    { key: string; value: string }[]
  >([]);
  const [segmentationReviewers, setSegmentationReviewers] = useState<
    { key: string; value: string }[]
  >([]);
  const [caseReportReviewers, setCaseReportReviewers] = useState<{ key: string; value: string }[]>(
    [],
  );
  const [caseReportAlignmentGoals, setCaseReportAlignmentGoals] = useState<
    CaseReportAlignmentGoalType[]
  >([]);
  const [disablePeerReviewer, setDisablePeerReviewer] = useState(false);
  const [selectedFeedback, setSelectedFeedback] = useState<string[]>([]);

  const isEditingAllowed = !props.disabled && !props.loading;

  const {
    control,
    handleSubmit,
    reset,
    setError,
    resetField,
    setValue,
    formState,
    getValues,
    watch,
  } = useFormContext<Tem13FillDialogStateType>();

  const tem13FormData = getValues();
  const levelAssets = tem13FormData?.levelAssets ?? [];
  const deletedAssetIds = tem13FormData.deletedAssetIds;

  // Function to generate watch fields dynamically
  const generateWatchFields = (): any[] => {
    const levelAssetFields: string[] = [];

    levelAssets?.forEach((levelAsset, levelIndex) => {
      levelAsset.inputAssets.forEach((_, inputIndex) => {
        levelAssetFields.push(`levelAssets[${levelIndex}].inputAssets[${inputIndex}].inputAsset`);
      });
    });

    return [
      'riskAssessment',
      'correctionGoalSagittal',
      'correctionGoalDiscHeightOnly',
      'correctionGoalCoronal',
      'measurementLordosisDifference',
      'measurementLordosisDifferenceRationale',
      'patientRecord.lumbarLordosis',
      'segmentedByQualifiedOperator',
      'patientRecord.pelvicIncidence',
      'caseReportStandingXrayLateralMeasured',
      'caseReportVertebraePreEditImage',
      'caseReportVertebraePostEditImage',
      'caseReportImplantPreEditImage',
      'caseReportImplantPostEditImage',
      'reportReviewedBy',
      'planFeedback',
      'isComplete',
      ...levelAssetFields,
    ];
  };

  const watchFields = watch(generateWatchFields() as any);

  const theme = useTheme();
  const disabled = props.loading || props.disabled;

  const lumbarLordosisCt = isNumber(tem13FormData?.preOp?.lumbarLordosis)
    ? toNumber(tem13FormData?.preOp?.lumbarLordosis.toFixed(0))
    : undefined;
  const lumbarLordosis = tem13FormData?.patientRecord?.lumbarLordosis;

  function getAsset(assetType: AssetType) {
    return tem13Assets.find((tem13Asset) => tem13Asset.assetType === assetType);
  }

  function clearAsset(assetType: AssetType) {
    const foundAsset = tem13Assets.find((tem13Asset) => tem13Asset.assetType === assetType);
    const updatedTem13Assets = tem13Assets.filter(
      (tem13Asset) => tem13Asset.assetType !== assetType,
    );
    setTem13Assets(updatedTem13Assets);
    setValue('assets' as any, updatedTem13Assets);

    if (foundAsset) {
      deletedAssetIds?.push(foundAsset.assetId);
      setValue('deletedAssetIds' as any, deletedAssetIds);
    }
  }

  const hasFlaggedGuardrails = checkGuardrailsFlagged(tem13FormData?.caseReportCorrectionPlanRules);

  const showMedicalReviewSection =
    currentUser?.userId !== tem13FormData?.medicalReviewedByUser?.userId ||
    tem13FormData.medicalReviewStatus === MedicalReviewStatusType.Approved;

  const showCaseReportReviewSection =
    currentUser?.userId !== tem13FormData?.reportReviewedByUser?.userId ||
    tem13FormData.status === CaseReportStatusType.Approved;

  const isTem13ReadyToReview = true;

  const segmentationImagingAssessmentTypeOptions = [
    {
      key: SegmentationImagingAssessmentType.GoldCtFullLengthXrayWithFemoralHeads,
      value: format.formatSegmentationImagingAssessmentType(
        SegmentationImagingAssessmentType.GoldCtFullLengthXrayWithFemoralHeads,
      ),
    },
    {
      key: SegmentationImagingAssessmentType.SilverCtStandardLumbarXrayWithFemoralHeads,
      value: format.formatSegmentationImagingAssessmentType(
        SegmentationImagingAssessmentType.SilverCtStandardLumbarXrayWithFemoralHeads,
      ),
    },
    {
      key: SegmentationImagingAssessmentType.BronzeCtStandardLumbarXrayWithoutFemoralHeads,
      value: format.formatSegmentationImagingAssessmentType(
        SegmentationImagingAssessmentType.BronzeCtStandardLumbarXrayWithoutFemoralHeads,
      ),
    },
    {
      key: SegmentationImagingAssessmentType.BronzeCtNoXrayOrUnusableXray,
      value: format.formatSegmentationImagingAssessmentType(
        SegmentationImagingAssessmentType.BronzeCtNoXrayOrUnusableXray,
      ),
    },
  ];

  const sagittalGoalTypeMenuItems = [
    {
      key: SagittalGoalType.AgeAdjusted,
      value: format.formatSagittalGoalType(SagittalGoalType.AgeAdjusted),
    },
    {
      key: SagittalGoalType.MinimalMismatch,
      value: format.formatSagittalGoalType(SagittalGoalType.MinimalMismatch),
    },
    {
      key: SagittalGoalType.GapScore,
      value: format.formatSagittalGoalType(SagittalGoalType.GapScore),
    },
    {
      key: SagittalGoalType.AlternativeSupportivePlanningReference,
      value: format.formatSagittalGoalType(SagittalGoalType.AlternativeSupportivePlanningReference),
    },
    {
      key: SagittalGoalType.Other,
      value: 'Surgeon Request',
    },
  ];

  const lordosisDifferenceRationaleTypeListItems = [
    {
      key: LordosisDifferenceRationaleType.HypermobilityInDiscSpaces,
      value: format.formatLordosisDifferenceRationaleType(
        LordosisDifferenceRationaleType.HypermobilityInDiscSpaces,
      ),
    },
    {
      key: LordosisDifferenceRationaleType.XrayQualityCausingInaccurateS1OrL1Placement,
      value: format.formatLordosisDifferenceRationaleType(
        LordosisDifferenceRationaleType.XrayQualityCausingInaccurateS1OrL1Placement,
      ),
    },
    {
      key: LordosisDifferenceRationaleType.XrayQualityCausingInaccurateFemoralHeadPlacement,
      value: format.formatLordosisDifferenceRationaleType(
        LordosisDifferenceRationaleType.XrayQualityCausingInaccurateFemoralHeadPlacement,
      ),
    },
    {
      key: LordosisDifferenceRationaleType.Other,
      value: format.formatLordosisDifferenceRationaleType(LordosisDifferenceRationaleType.Other),
    },
  ];

  const coronalGoalTypeMenuItems = [
    {
      key: CoronalGoalType.ParallelDiscSpaceAtTreatmentLevels,
      value: format.formatCoronalGoalType(CoronalGoalType.ParallelDiscSpaceAtTreatmentLevels),
    },
    {
      key: CoronalGoalType.SuperiorEndplateOfHighestTreatedLevelParallelToSacrum,
      value: format.formatCoronalGoalType(
        CoronalGoalType.SuperiorEndplateOfHighestTreatedLevelParallelToSacrum,
      ),
    },
    {
      key: CoronalGoalType.SuperiorL1EndplateParallelToSacrum,
      value: format.formatCoronalGoalType(CoronalGoalType.SuperiorL1EndplateParallelToSacrum),
    },
    {
      key: CoronalGoalType.SuperiorL1EndplateParallelToFloor,
      value: format.formatCoronalGoalType(CoronalGoalType.SuperiorL1EndplateParallelToFloor),
    },
    {
      key: CoronalGoalType.Other,
      value: 'Surgeon Request',
    },
  ];

  const segmentationSliceThicknessTypeMenuItems = [
    {
      key: SegmentationSliceThicknessType.LessThanOneMm,
      value: format.formatSegmentationSliceThicknessType(
        SegmentationSliceThicknessType.LessThanOneMm,
      ),
    },
    {
      key: SegmentationSliceThicknessType.BetweenOneAndTwoMm,
      value: format.formatSegmentationSliceThicknessType(
        SegmentationSliceThicknessType.BetweenOneAndTwoMm,
      ),
    },
    {
      key: SegmentationSliceThicknessType.GreaterThanTwoMm,
      value: format.formatSegmentationSliceThicknessType(
        SegmentationSliceThicknessType.GreaterThanTwoMm,
      ),
    },
  ];

  const caseRiskAssessmentTypeOptions = [
    {
      key: CaseRiskAssessmentType.CaseReviewQaReview,
      value: format.formatCaseRiskAssessmentType(CaseRiskAssessmentType.CaseReviewQaReview),
    },
    {
      key: CaseRiskAssessmentType.QaReviewOnly,
      value: format.formatCaseRiskAssessmentType(CaseRiskAssessmentType.QaReviewOnly),
    },
  ];

  const planFeedbackTypeMenuItems = [
    {
      key: PlanFeedbackType.NoFeedback,
      value: format.formatPlanFeedbackType(PlanFeedbackType.NoFeedback),
    },
    {
      key: PlanFeedbackType.AnatomicalConstraints,
      value: format.formatPlanFeedbackType(PlanFeedbackType.AnatomicalConstraints),
    },
    {
      key: PlanFeedbackType.ImplantFootprintSpecification,
      value: format.formatPlanFeedbackType(PlanFeedbackType.ImplantFootprintSpecification),
    },
    {
      key: PlanFeedbackType.ImplantHeightSpecification,
      value: format.formatPlanFeedbackType(PlanFeedbackType.ImplantHeightSpecification),
    },
    {
      key: PlanFeedbackType.KyphoticSpecification,
      value: format.formatPlanFeedbackType(PlanFeedbackType.KyphoticSpecification),
    },
    {
      key: PlanFeedbackType.LordoticSpecification,
      value: format.formatPlanFeedbackType(PlanFeedbackType.LordoticSpecification),
    },
    {
      key: PlanFeedbackType.Other,
      value: format.formatPlanFeedbackType(PlanFeedbackType.Other),
    },
  ];

  const handleSagittalGoalChange = (event: SelectChangeEvent<HTMLSelectElement>) => {
    // setValue(
    //   `correctionGoalSagittal` as any,
    //   event.target.value,
    // );
  };

  const handleChangeRiskAssessment = (event: React.ChangeEvent<HTMLInputElement>) => {
    const riskAssessment = event.target.value;
    if (riskAssessment === CaseRiskAssessmentType.QaReviewOnly) {
      setValue('reportReviewedBy' as keyof Tem13FillDialogStateType, '' as any);
      setValue(
        'riskAssessment' as keyof Tem13FillDialogStateType,
        CaseRiskAssessmentType.QaReviewOnly as any,
      );
      setDisablePeerReviewer(true);
    } else {
      setValue(
        'riskAssessment' as keyof Tem13FillDialogStateType,
        CaseRiskAssessmentType.CaseReviewQaReview as any,
      );
      setDisablePeerReviewer(false);
    }
  };

  const handleFeedbackChange = (event: SelectChangeEvent<unknown>) => {
    const value = event.target.value as string[];

    if (value.includes(PlanFeedbackType.NoFeedback)) {
      setSelectedFeedback([PlanFeedbackType.NoFeedback]);
      setValue(
        'planFeedback' as keyof Tem13FillDialogStateType,
        [PlanFeedbackType.NoFeedback] as any[],
      );
    } else {
      const filteredValues = value.filter((item) => item !== PlanFeedbackType.NoFeedback);
      setSelectedFeedback(filteredValues);
      setValue('planFeedback' as keyof Tem13FillDialogStateType, filteredValues as any[]);
    }
  };

  useEffect(() => {
    const segmentationOperatorsAndReportReviewers: { key: string; value: string }[] = [];
    const segmentationReviewers: { key: string; value: string }[] = [];

    for (const user of props.tem13Users) {
      if (user.role !== UserRoleType.Clinical) {
        segmentationOperatorsAndReportReviewers.push({
          key: user.userId as unknown as string,
          value: format.formatName(user),
        });
      }

      if (user.role === UserRoleType.CaseEngineer) {
        segmentationReviewers.push({
          key: user.userId as unknown as string,
          value: format.formatName(user),
        });
      }
    }

    setSegmentationOperators(segmentationOperatorsAndReportReviewers);
    setSegmentationReviewers(segmentationReviewers);
    setCaseReportReviewers(segmentationOperatorsAndReportReviewers);
    setTem13Assets((tem13FormData?.assets as unknown as IAsset[]) ?? []);
  }, []);

  useEffect(() => {
    const patientAge = date.distanceInYears(props.activeCase?.patient?.birthDate as string);
    const pelvicIncidence = tem13FormData?.patientRecord?.pelvicIncidence ?? 0;
    const caseAlignmentGoals = generateCaseAlignmentGoals(
      props.activeCase.levels,
      tem13FormData.correctionGoalSagittal as SagittalGoalType,
      patientAge,
      pelvicIncidence,
      tem13FormData.correctionGoalCoronal,
      tem13FormData?.preOp,
    );

    setCaseReportAlignmentGoals(caseAlignmentGoals);
    setValue('caseReportAlignmentGoalTypes' as any, caseAlignmentGoals);
  }, [
    tem13FormData.correctionGoalCoronal,
    tem13FormData.correctionGoalSagittal,
    tem13FormData?.patientRecord?.pelvicIncidence,
  ]);

  useEffect(() => {
    if (lumbarLordosis !== undefined || lumbarLordosisCt !== undefined) {
      const rationaleDifference = absoluteDifference(lumbarLordosisCt, lumbarLordosis);
      setValue('measurementLordosisDifference', rationaleDifference);
    }
  }, [lumbarLordosis, lumbarLordosisCt, setValue]);

  useEffect(() => {
    if (tem13FormData?.riskAssessment === CaseRiskAssessmentType.QaReviewOnly) {
      setDisablePeerReviewer(true);
    } else {
      setDisablePeerReviewer(false);
    }
  }, [tem13FormData?.riskAssessment]);

  return (
    <Box display={'flex'}>
      <Box width={'100%'}>
        <Box>
          <Box display={'flex'} alignItems={'center'} my={2} gap={1}>
            <Box display={'flex'} width={240} justifyContent={'left'}>
              <Typography variant={'body1'}>Report Status</Typography>
            </Box>
            <Box display={'flex'} width={650} justifyContent={'left'}>
              <Typography variant={'body1'}>
                {format.formatCaseReportStatusType(tem13FormData.status)}
              </Typography>
            </Box>
            <Box display={'flex'} width={240} justifyContent={'left'}></Box>
          </Box>
          <Box display={'flex'} alignItems={'center'} mt={4}>
            <Box display={'flex'} justifyContent={'left'}>
              <Typography variant={'h5'}>
                <strong>Case Imaging and Segmentation Section</strong>
              </Typography>
            </Box>
          </Box>

          <Box display={'flex'} alignItems={'center'} my={2} gap={1}>
            <Box display={'flex'} width={240} justifyContent={'left'}>
              <Typography variant={'body1'}>Case Imaging</Typography>
            </Box>
            <Box display={'flex'} width={650} justifyContent={'left'}>
              <RadioGroupRHF
                name={'segmentationImagingAssessment'}
                control={control as unknown as Control<FieldValues>}
                label={''}
                hideLabel={false}
                disabled={disabled}
                row={true}
                options={segmentationImagingAssessmentTypeOptions}
                onChange={(e) => {}}
              />
            </Box>
            <Box display={'flex'} width={240} justifyContent={'left'}>
              <ErrorMessageRHF
                name={'segmentationImagingAssessment'}
                control={control as unknown as Control<FieldValues>}
              />
            </Box>
          </Box>
          <Box display={'flex'} alignItems={'center'} my={2} gap={1}>
            <Box display={'flex'} width={240} justifyContent={'left'}>
              <Typography variant={'body1'}>Segmentation Slice Thickness</Typography>
            </Box>
            <Box display={'flex'} width={650} justifyContent={'left'}>
              <SelectFieldRHF
                name={'segmentationSliceThickness'}
                control={control as unknown as Control<FieldValues>}
                label={''}
                hideNone={true}
                menuItems={segmentationSliceThicknessTypeMenuItems}
                disabled={disabled}
              />
            </Box>
            <Box display={'flex'} width={240} justifyContent={'left'}>
              <ErrorMessageRHF
                name={'segmentationSliceThickness'}
                control={control as unknown as Control<FieldValues>}
              />
            </Box>
          </Box>

          <Box display={'flex'} alignItems={'center'} my={2} gap={1}>
            <Box display={'flex'} width={240} justifyContent={'left'}>
              <Typography variant={'body1'}>Did Segmentation Pass?</Typography>
            </Box>
            <Box display={'flex'} width={650} justifyContent={'left'}>
              <CheckboxRHF
                name={`isSegmentationPassed`}
                control={control as unknown as Control<FieldValues>}
                label={''}
                disabled={props.disabled}
              />
            </Box>
            <Box display={'flex'} width={240} justifyContent={'left'}></Box>
          </Box>

          <Box display={'flex'} alignItems={'center'} my={2} gap={1}>
            <Box display={'flex'} width={240} justifyContent={'left'}>
              <Typography variant={'body1'}>Performed by Qualified Operator</Typography>
            </Box>
            <Box display={'flex'} width={650} justifyContent={'left'}>
              <CheckboxRHF
                name={`segmentedByQualifiedOperator`}
                control={control as unknown as Control<FieldValues>}
                label={''}
                disabled={props.disabled}
              />
            </Box>
            <Box display={'flex'} width={240} justifyContent={'left'}></Box>
          </Box>
          {!tem13FormData.segmentedByQualifiedOperator ? (
            <>
              <Box display={'flex'} alignItems={'center'} my={2} gap={1}>
                <Box display={'flex'} width={240} justifyContent={'left'}>
                  <Typography variant={'body1'}>Segmentation Operator</Typography>
                </Box>
                <Box display={'flex'} width={650} justifyContent={'left'}>
                  <SelectFieldRHF
                    name={'segmentationPerformedBy'}
                    control={control as unknown as Control<FieldValues>}
                    label={''}
                    hideNone={true}
                    menuItems={segmentationOperators}
                    disabled={disabled}
                  />
                </Box>
                <Box display={'flex'} width={240} justifyContent={'left'}>
                  <ErrorMessageRHF
                    name={'segmentationPerformedBy'}
                    control={control as unknown as Control<FieldValues>}
                  />
                </Box>
              </Box>
              <Box display={'flex'} alignItems={'center'} my={2} gap={1}>
                <Box display={'flex'} width={240} justifyContent={'left'}>
                  <Typography variant={'body1'}>Segmentation Reviewer</Typography>
                </Box>
                <Box display={'flex'} width={650} justifyContent={'left'}>
                  <SelectFieldRHF
                    name={'segmentationReviewedBy'}
                    control={control as unknown as Control<FieldValues>}
                    label={''}
                    hideNone={true}
                    menuItems={segmentationReviewers}
                    disabled={disabled}
                  />
                </Box>
                <Box display={'flex'} width={240} justifyContent={'left'}>
                  <ErrorMessageRHF
                    name={'segmentationReviewedBy'}
                    control={control as unknown as Control<FieldValues>}
                  />
                </Box>
              </Box>
            </>
          ) : null}
          <Box display={'flex'} alignItems={'center'} mt={4}>
            <Box display={'flex'} justifyContent={'left'}>
              <Typography variant={'h5'}>
                <strong>Pre-operative X-ray Measurements Section</strong>
              </Typography>
            </Box>
          </Box>
          <Box display={'flex'} alignItems={'center'} my={2} gap={1}>
            <Box display={'flex'} width={240} justifyContent={'left'} alignItems={'center'}>
              <Typography variant={'body1'}>
                {format.formatAssetType(AssetType.CaseReportStandingXrayLateralMeasured)}
              </Typography>
            </Box>
            <Box display={'flex'} width={650} justifyContent={'left'}>
              <Tem13FileRow
                id={'caseReportStandingXrayLateralMeasured'}
                label={'Standing X-ray'}
                editing={isEditingAllowed}
                asset={getAsset(AssetType.CaseReportStandingXrayLateralMeasured)}
                onClick={() => clearAsset(AssetType.CaseReportStandingXrayLateralMeasured)}
                control={control as unknown as Control<FieldValues>}
                validFileExtensions={[ValidFileExtensions.JPG]}
                inputAssetFile={tem13FormData?.caseReportStandingXrayLateralMeasured}
              />
            </Box>
            <Box display={'flex'} width={240} justifyContent={'left'}>
              <ErrorMessageRHF
                name={'caseReportStandingXrayLateralMeasured'}
                control={control as unknown as Control<FieldValues>}
              />
            </Box>
          </Box>
          <Box display={'flex'} alignItems={'flex-start'} my={2} gap={1}>
            <Box display={'flex'} width={240} justifyContent={'left'}>
              <Typography variant={'body1'}>Pelvic Incidence (°)</Typography>
            </Box>
            <Box display={'flex'} width={650} justifyContent={'left'}>
              <NumberTextFieldRHF
                allowNegative={true}
                decimalPlaces={2}
                name={`patientRecord.pelvicIncidence`}
                control={control as unknown as Control<FieldValues>}
                label={''}
                disabled={props.disabled}
              />
            </Box>
            <Box display={'flex'} width={240} justifyContent={'left'}>
              <ErrorMessageRHF
                name={'patientRecord.pelvicIncidence'}
                control={control as unknown as Control<FieldValues>}
              />
            </Box>
          </Box>
          <Box display={'flex'} alignItems={'flex-start'} my={2} gap={1}>
            <Box display={'flex'} width={240} justifyContent={'left'}>
              <Typography variant={'body1'}>Lumbar Coronal Angle (°)</Typography>
            </Box>
            <Box display={'flex'} width={650} justifyContent={'left'}>
              <NumberTextFieldRHF
                allowNegative={true}
                decimalPlaces={2}
                name={`patientRecord.lumbarCoronalCobb`}
                control={control as unknown as Control<FieldValues>}
                label={''}
                disabled={props.disabled}
              />
            </Box>
            <Box display={'flex'} width={240} justifyContent={'left'}>
              <ErrorMessageRHF
                name={'patientRecord.lumbarCoronalCobb'}
                control={control as unknown as Control<FieldValues>}
              />
            </Box>
          </Box>
          <Box display={'flex'} alignItems={'flex-start'} my={2} gap={1}>
            <Box display={'flex'} width={240} justifyContent={'left'}>
              <Typography variant={'body1'}>L4-S1 Lordotic Distribution %</Typography>
            </Box>
            <Box display={'flex'} width={650} justifyContent={'left'}>
              <NumberTextFieldRHF
                allowNegative={true}
                decimalPlaces={2}
                name={`patientRecord.l4S1LordoticDistribution`}
                control={control as unknown as Control<FieldValues>}
                label={''}
                disabled={props.disabled}
              />
            </Box>
            <Box display={'flex'} width={240} justifyContent={'left'}>
              <ErrorMessageRHF
                name={'patientRecord.l4S1LordoticDistribution'}
                control={control as unknown as Control<FieldValues>}
              />
            </Box>
          </Box>
          <Box display={'flex'} alignItems={'flex-start'} my={2} gap={1}>
            <Box display={'flex'} width={240} justifyContent={'left'}>
              <Typography variant={'body1'}>Global Tilt</Typography>
            </Box>
            <Box display={'flex'} width={650} justifyContent={'left'}>
              <NumberTextFieldRHF
                allowNegative={true}
                decimalPlaces={2}
                name={`patientRecord.globalTilt`}
                control={control as unknown as Control<FieldValues>}
                label={''}
                disabled={props.disabled}
              />
            </Box>
            <Box display={'flex'} width={240} justifyContent={'left'}>
              <ErrorMessageRHF
                name={'patientRecord.globalTilt'}
                control={control as unknown as Control<FieldValues>}
              />
            </Box>
          </Box>
          <Box display={'flex'} alignItems={'flex-start'} my={2} gap={1}>
            <Box display={'flex'} width={240} justifyContent={'left'}>
              <Typography variant={'body1'}>Pelvic Tilt</Typography>
            </Box>
            <Box display={'flex'} width={650} justifyContent={'left'}>
              <NumberTextFieldRHF
                allowNegative={true}
                decimalPlaces={2}
                name={`patientRecord.pelvicTilt`}
                control={control as unknown as Control<FieldValues>}
                label={''}
                disabled={props.disabled}
              />
            </Box>
            <Box display={'flex'} width={240} justifyContent={'left'}>
              <ErrorMessageRHF
                name={'patientRecord.pelvicTilt'}
                control={control as unknown as Control<FieldValues>}
              />
            </Box>
          </Box>
          <Box display={'flex'} alignItems={'flex-start'} my={2} gap={1}>
            <Box display={'flex'} width={240} justifyContent={'left'}>
              <Typography variant={'body1'}>Sacral Slope</Typography>
            </Box>
            <Box display={'flex'} width={650} justifyContent={'left'}>
              <NumberTextFieldRHF
                allowNegative={true}
                decimalPlaces={2}
                name={`patientRecord.sacralSlope`}
                control={control as unknown as Control<FieldValues>}
                label={''}
                disabled={props.disabled}
              />
            </Box>
            <Box display={'flex'} width={240} justifyContent={'left'}>
              <ErrorMessageRHF
                name={'patientRecord.sacralSlope'}
                control={control as unknown as Control<FieldValues>}
              />
            </Box>
          </Box>
          <Box display={'flex'} alignItems={'flex-start'} my={2} gap={1}>
            <Box display={'flex'} width={240} justifyContent={'left'}>
              <Typography variant={'body1'}>c7-Sagittal Veritical Line</Typography>
            </Box>
            <Box display={'flex'} width={650} justifyContent={'left'}>
              <NumberTextFieldRHF
                allowNegative={true}
                decimalPlaces={2}
                name={`patientRecord.c7SagittalVerticalLine`}
                control={control as unknown as Control<FieldValues>}
                label={''}
                disabled={props.disabled}
              />
            </Box>
            <Box display={'flex'} width={240} justifyContent={'left'}>
              <ErrorMessageRHF
                name={'patientRecord.c7SagittalVerticalLine'}
                control={control as unknown as Control<FieldValues>}
              />
            </Box>
          </Box>
          <Box display={'flex'} alignItems={'flex-start'} my={2} gap={1}>
            <Box display={'flex'} width={240} justifyContent={'left'}>
              <Typography variant={'body1'}>Sagittal Vertical Axis</Typography>
            </Box>
            <Box display={'flex'} width={650} justifyContent={'left'}>
              <NumberTextFieldRHF
                allowNegative={true}
                decimalPlaces={2}
                name={`patientRecord.sagittalVerticalAxis`}
                control={control as unknown as Control<FieldValues>}
                label={''}
                disabled={props.disabled}
              />
            </Box>
            <Box display={'flex'} width={240} justifyContent={'left'}>
              <ErrorMessageRHF
                name={'patientRecord.sagittalVerticalAxis'}
                control={control as unknown as Control<FieldValues>}
              />
            </Box>
          </Box>
          <Box display={'flex'} alignItems={'flex-start'} my={2} gap={1}>
            <Box display={'flex'} width={240} justifyContent={'left'}>
              <Typography variant={'body1'}>Lumbar Lordosis (X-ray) (°)</Typography>
            </Box>
            <Box display={'flex'} width={650} justifyContent={'left'}>
              <NumberTextFieldRHF
                allowNegative={true}
                decimalPlaces={2}
                name={`patientRecord.lumbarLordosis`}
                control={control as unknown as Control<FieldValues>}
                label={''}
                disabled={props.disabled}
              />
            </Box>
            <Box display={'flex'} width={240} justifyContent={'left'}>
              <ErrorMessageRHF
                name={'patientRecord.lumbarLordosis'}
                control={control as unknown as Control<FieldValues>}
              />
            </Box>
          </Box>
          <Box display={'flex'} alignItems={'flex-start'} my={2} gap={1}>
            <Box display={'flex'} width={240} justifyContent={'left'}>
              <Typography variant={'body1'}>Lumbar Lordosis (CT) (°)</Typography>
            </Box>
            <Box display={'flex'} width={650} justifyContent={'left'}>
              <Typography variant={'body1'}>
                {'\u00A0\u00A0\u00A0'}
                {tem13FormData?.preOp?.lumbarLordosis.toFixed(0)}
              </Typography>
            </Box>
            <Box display={'flex'} width={240} justifyContent={'left'}></Box>
          </Box>
          <Box display={'flex'} alignItems={'flex-start'} my={2} gap={1}>
            <Box display={'flex'} width={240} justifyContent={'left'}>
              <Typography variant={'body1'}> Difference (CT LL and X-Ray LL)</Typography>
            </Box>
            <Box display={'flex'} width={650} justifyContent={'left'}>
              <Typography variant={'body1'}>
                {tem13FormData.measurementLordosisDifference
                  ? `${tem13FormData.measurementLordosisDifference}°`
                  : 'Invalid'}
              </Typography>
            </Box>
            <Box display={'flex'} width={240} justifyContent={'left'}></Box>
          </Box>
          {toNumber(tem13FormData.measurementLordosisDifference) > 10 ? (
            <>
              <Box display={'flex'} alignItems={'center'} my={2} gap={1}>
                <Box display={'flex'} width={240} justifyContent={'left'}>
                  <Typography variant={'body1'}>
                    Rationale for difference greater than 10°{' '}
                  </Typography>
                </Box>
                <Box display={'flex'} width={650} justifyContent={'left'}>
                  <CheckboxListFieldRHF
                    label={''}
                    name={'measurementLordosisDifferenceRationale'}
                    control={control as unknown as Control<FieldValues>}
                    disabled={disabled}
                    disableInline={true}
                    displayError={false}
                    listItems={lordosisDifferenceRationaleTypeListItems}
                  />
                </Box>
                <Box display={'flex'} width={240} justifyContent={'left'}>
                  <ErrorMessageRHF
                    name={'measurementLordosisDifferenceRationale'}
                    control={control as unknown as Control<FieldValues>}
                  />
                </Box>
              </Box>
              {tem13FormData.measurementLordosisDifferenceRationale.includes(
                LordosisDifferenceRationaleType.Other,
              ) ? (
                <Box display={'flex'} alignItems={'flex-start'} my={2} gap={1}>
                  <Box display={'flex'} width={240} justifyContent={'left'}>
                    <Typography variant={'body1'}>Difference Rationale Other Note</Typography>
                  </Box>
                  <Box display={'flex'} width={650} justifyContent={'left'}>
                    <TextFieldRHF
                      name={'measurementLordosisDifferenceRationaleNote'}
                      control={control as unknown as Control<FieldValues>}
                      label={''}
                      fullWidth={true}
                      multiline={true}
                      disabled={disabled}
                    />
                  </Box>
                  <Box display={'flex'} width={240} justifyContent={'left'}>
                    <ErrorMessageRHF
                      name={'measurementLordosisDifferenceRationaleNote'}
                      control={control as unknown as Control<FieldValues>}
                    />
                  </Box>
                </Box>
              ) : null}
            </>
          ) : null}
          <Box display={'flex'} alignItems={'flex-start'} my={2} gap={1}>
            <Box display={'flex'} width={240} justifyContent={'left'}>
              <Typography variant={'body1'}>Measurement Note</Typography>
            </Box>
            <Box display={'flex'} width={650} justifyContent={'left'}>
              <TextFieldRHF
                name={'measurementNote'}
                control={control as unknown as Control<FieldValues>}
                label={''}
                fullWidth={true}
                multiline={true}
                disabled={disabled}
              />
            </Box>
            <Box display={'flex'} width={240} justifyContent={'left'}></Box>
          </Box>
          <Box display={'flex'} alignItems={'center'} mt={4}>
            <Box display={'flex'} justifyContent={'left'}>
              <Typography variant={'h5'}>
                <strong>Correction Goals Section</strong>
              </Typography>
            </Box>
          </Box>
          <Box display={'flex'} alignItems={'center'} my={2} gap={1}>
            <Box display={'flex'} width={240} justifyContent={'left'}>
              <Typography variant={'body1'}>Disc Space Only</Typography>
            </Box>
            <Box display={'flex'} width={650} justifyContent={'left'}>
              <CheckboxRHF
                name={`correctionGoalDiscHeightOnly`}
                control={control as unknown as Control<FieldValues>}
                label={''}
                disabled={props.disabled}
              />
            </Box>
            <Box display={'flex'} width={240} justifyContent={'left'}></Box>
          </Box>
          {!tem13FormData.correctionGoalDiscHeightOnly ? (
            <>
              <Box display={'flex'} alignItems={'center'} my={2} gap={1}>
                <Box display={'flex'} width={240} justifyContent={'left'}>
                  <Typography variant={'body1'}>Sagittal Correction</Typography>
                </Box>
                <Box display={'flex'} width={650} justifyContent={'left'}>
                  <SelectFieldRHF
                    name={'correctionGoalSagittal'}
                    control={control as unknown as Control<FieldValues>}
                    label={''}
                    hideNone={true}
                    menuItems={sagittalGoalTypeMenuItems}
                    disabled={disabled}
                    onChange={handleSagittalGoalChange}
                  />
                </Box>
                <Box display={'flex'} width={240} justifyContent={'left'}>
                  <ErrorMessageRHF
                    name={'correctionGoalSagittal'}
                    control={control as unknown as Control<FieldValues>}
                  />
                </Box>
              </Box>
              {tem13FormData?.correctionGoalSagittal === SagittalGoalType.Other ? (
                <Box display={'flex'} alignItems={'center'} my={2} gap={1}>
                  <Box display={'flex'} width={240} justifyContent={'left'}>
                    <Typography variant={'body1'}>Sagittal Correction Other</Typography>
                  </Box>
                  <Box display={'flex'} width={650} justifyContent={'left'}>
                    <TextFieldRHF
                      name={'correctionGoalSagittalOther'}
                      control={control as unknown as Control<FieldValues>}
                      label={''}
                      fullWidth={true}
                      multiline={false}
                      disabled={disabled}
                      shrink={false}
                    />
                  </Box>
                  <Box display={'flex'} width={240} justifyContent={'left'}>
                    <ErrorMessageRHF
                      name={'correctionGoalSagittalOther'}
                      control={control as unknown as Control<FieldValues>}
                    />
                  </Box>
                </Box>
              ) : null}
              <Box display={'flex'} alignItems={'center'} my={2} gap={1}>
                <Box display={'flex'} width={240} justifyContent={'left'}>
                  <Typography variant={'body1'}>Coronal Correction</Typography>
                </Box>
                <Box display={'flex'} width={650} justifyContent={'left'}>
                  <SelectFieldRHF
                    name={'correctionGoalCoronal'}
                    control={control as unknown as Control<FieldValues>}
                    label={''}
                    hideNone={true}
                    menuItems={coronalGoalTypeMenuItems}
                    disabled={disabled}
                  />
                </Box>
                <Box display={'flex'} width={240} justifyContent={'left'}>
                  <ErrorMessageRHF
                    name={'correctionGoalCoronal'}
                    control={control as unknown as Control<FieldValues>}
                  />
                </Box>
              </Box>
              {tem13FormData?.correctionGoalCoronal === CoronalGoalType.Other ? (
                <Box display={'flex'} alignItems={'center'} my={2} gap={1}>
                  <Box display={'flex'} width={240} justifyContent={'left'}>
                    <Typography variant={'body1'}>Coronal Correction Other</Typography>
                  </Box>
                  <Box display={'flex'} width={650} justifyContent={'left'}>
                    <TextFieldRHF
                      name={'correctionGoalCoronalOther'}
                      control={control as unknown as Control<FieldValues>}
                      label={''}
                      fullWidth={true}
                      multiline={false}
                      disabled={disabled}
                      shrink={false}
                    />
                  </Box>
                  <Box display={'flex'} width={240} justifyContent={'left'}>
                    <ErrorMessageRHF
                      name={'correctionGoalCoronalOther'}
                      control={control as unknown as Control<FieldValues>}
                    />
                  </Box>
                </Box>
              ) : null}
            </>
          ) : null}
          <Box display={'flex'} alignItems={'flex-start'} my={2} gap={1}>
            <Box display={'flex'} width={240} justifyContent={'left'}>
              <Typography variant={'body1'}>Correction Note</Typography>
            </Box>
            <Box display={'flex'} width={650} justifyContent={'left'}>
              <TextFieldRHF
                name={`correctionGoalNote`}
                control={control as unknown as Control<FieldValues>}
                label={''}
                fullWidth={true}
                multiline={true}
                multiLineRows={2}
                disabled={disabled}
              />
            </Box>
            <Box display={'flex'} width={240} justifyContent={'left'}></Box>
          </Box>
          <Box display={'flex'} alignItems={'center'} mt={4}>
            <Box display={'flex'} justifyContent={'left'}>
              <Typography variant={'h5'}>
                <strong>Patient Pathologies Section</strong>
              </Typography>
            </Box>
          </Box>
          <Box display={'flex'} alignItems={'flex-start'} my={2} gap={1}>
            <Box display={'flex'} width={900} justifyContent={'left'}>
              <CasePathologiesEditTable
                casePathologies={tem13FormData?.casePathologies}
                disabled={disabled}
              />
            </Box>
          </Box>
          <Box display={'flex'} alignItems={'center'} mt={4}>
            <Box display={'flex'} justifyContent={'left'}>
              <Typography variant={'h5'}>
                <strong>Correction Planning Section</strong>
              </Typography>
            </Box>
          </Box>
          {caseReportAlignmentGoals.length > 0 ? (
            <Box display={'flex'} alignItems={'flex-start'} my={2} gap={1}>
              <Box display={'flex'} width={900} justifyContent={'left'}>
                <CaseReportAlignmentGoalViewTable
                  caseReportAlignmentGoals={caseReportAlignmentGoals}
                />
              </Box>
            </Box>
          ) : null}
          <Box>
            <CaseReportStatementsEditTable
              statementType={StatementType.CorrectionPlanning}
              caseReportStatements={tem13FormData?.caseReportStatements}
              disabled={disabled}
            />
          </Box>
          {hasFlaggedGuardrails && (
            <>
              <Box display={'flex'} alignItems={'center'} mt={4}>
                <Box display={'flex'} justifyContent={'left'}>
                  <Typography variant={'h5'}>
                    <strong>Guardrails Correction Plan Section</strong>
                  </Typography>
                </Box>
              </Box>
              <Box display={'flex'} alignItems={'flex-start'} my={2} gap={1}>
                <Box display={'flex'} width={900} justifyContent={'left'}>
                  <CaseReportCorrectionPlanRuleEditTable
                    caseReportCorrectionPlanRules={tem13FormData?.caseReportCorrectionPlanRules}
                    disabled={disabled}
                  />
                </Box>
              </Box>
            </>
          )}
          <Box display={'flex'} alignItems={'center'} mt={4}>
            <Box display={'flex'} justifyContent={'left'}>
              <Typography variant={'h5'}>Implant Design Section</Typography>
            </Box>
          </Box>
          <Box>
            <CaseReportStatementsEditTable
              statementType={StatementType.ImplantDesign}
              caseReportStatements={tem13FormData?.caseReportStatements}
              disabled={disabled}
            />
          </Box>
          <Box display={'flex'} alignItems={'center'} my={2} gap={1}>
            <Box display={'flex'} width={240} justifyContent={'left'} alignItems={'center'}>
              <Typography variant={'body1'}>
                {format.formatAssetType(AssetType.CaseReportVertebraePreEditImage)}
              </Typography>
            </Box>
            <Box display={'flex'} width={650} justifyContent={'left'} ml={2}>
              <Tem13FileRow
                id={'caseReportVertebraePreEditImage'}
                label={''}
                editing={isEditingAllowed}
                asset={getAsset(AssetType.CaseReportVertebraePreEditImage)}
                inputAssetFile={tem13FormData?.caseReportVertebraePreEditImage}
                onClick={() => clearAsset(AssetType.CaseReportVertebraePreEditImage)}
                control={control as unknown as Control<FieldValues>}
                validFileExtensions={[ValidFileExtensions.JPG]}
              />
            </Box>
            <Box display={'flex'} width={240} justifyContent={'left'}></Box>
          </Box>
          <Box display={'flex'} alignItems={'center'} my={2} gap={1}>
            <Box display={'flex'} width={240} justifyContent={'left'} alignItems={'center'}>
              <Typography variant={'body1'}>
                {format.formatAssetType(AssetType.CaseReportVertebraePostEditImage)}
              </Typography>
            </Box>
            <Box display={'flex'} width={650} justifyContent={'left'} ml={2}>
              <Tem13FileRow
                id={'caseReportVertebraePostEditImage'}
                label={''}
                editing={isEditingAllowed}
                asset={getAsset(AssetType.CaseReportVertebraePostEditImage)}
                inputAssetFile={tem13FormData?.caseReportVertebraePostEditImage}
                onClick={() => clearAsset(AssetType.CaseReportVertebraePostEditImage)}
                control={control as unknown as Control<FieldValues>}
                validFileExtensions={[ValidFileExtensions.JPG]}
              />
            </Box>
            <Box display={'flex'} width={240} justifyContent={'left'}></Box>
          </Box>
          <Box display={'flex'} alignItems={'center'} my={2} gap={1}>
            <Box display={'flex'} width={240} justifyContent={'left'} alignItems={'center'}>
              <Typography variant={'body1'}>
                {format.formatAssetType(AssetType.CaseReportImplantPreEditImage)}
              </Typography>
            </Box>
            <Box display={'flex'} width={650} justifyContent={'left'} ml={2}>
              <Tem13FileRow
                id={'caseReportImplantPreEditImage'}
                label={''}
                editing={isEditingAllowed}
                asset={getAsset(AssetType.CaseReportImplantPreEditImage)}
                inputAssetFile={tem13FormData?.caseReportImplantPreEditImage}
                onClick={() => clearAsset(AssetType.CaseReportImplantPreEditImage)}
                control={control as unknown as Control<FieldValues>}
                validFileExtensions={[ValidFileExtensions.JPG]}
              />
            </Box>
            <Box display={'flex'} width={240} justifyContent={'left'}></Box>
          </Box>
          <Box display={'flex'} alignItems={'center'} my={2} gap={1}>
            <Box display={'flex'} width={240} justifyContent={'left'} alignItems={'center'}>
              <Typography variant={'body1'}>
                {format.formatAssetType(AssetType.CaseReportImplantPostEditImage)}
              </Typography>
            </Box>
            <Box display={'flex'} width={650} justifyContent={'left'} ml={2}>
              <Tem13FileRow
                id={'caseReportImplantPostEditImage'}
                label={''}
                editing={isEditingAllowed}
                asset={getAsset(AssetType.CaseReportImplantPostEditImage)}
                inputAssetFile={tem13FormData?.caseReportImplantPostEditImage}
                onClick={() => clearAsset(AssetType.CaseReportImplantPostEditImage)}
                control={control as unknown as Control<FieldValues>}
                validFileExtensions={[ValidFileExtensions.JPG]}
              />
            </Box>
            <Box display={'flex'} width={240} justifyContent={'left'}></Box>
          </Box>
          {levelAssets?.map((levelAsset: LevelAssets, levelIndex: number) => (
            <>
              <Box key={levelIndex} display={'flex'} alignItems={'center'} mt={4}>
                <Box display={'flex'} justifyContent={'left'}>
                  <Typography variant={'h5'}>
                    <strong>
                      {format.formatLevelType(levelAsset.levelType)} &mdash;{' '}
                      {format.formatImplantTypes(levelAsset.implantType)}
                    </strong>
                  </Typography>
                </Box>
              </Box>
              {levelAsset.inputAssets.map((inputAsset: InputAssetType, inputIndex: number) => (
                <Box display={'flex'} alignItems={'center'} my={2} gap={1}>
                  <Box display={'flex'} width={240} justifyContent={'left'} alignItems={'center'}>
                    <Typography variant={'body1'}>
                      {format.formatAssetType(inputAsset.assetType)}
                    </Typography>
                  </Box>
                  <Box display={'flex'} width={650} justifyContent={'left'} ml={2}>
                    <Tem13FileRow
                      key={`${levelIndex}.${inputIndex}`}
                      id={`levelAssets[${levelIndex}].inputAssets[${inputIndex}].inputAsset`}
                      label={''}
                      editing={isEditingAllowed}
                      asset={getAsset(inputAsset.assetType as AssetType)}
                      onClick={() => clearAsset(inputAsset.assetType as AssetType)}
                      control={control as unknown as Control<FieldValues>}
                      validFileExtensions={[ValidFileExtensions.JPG]}
                      inputAssetFile={inputAsset.inputAsset}
                    />
                  </Box>
                  <Box display={'flex'} width={240} justifyContent={'left'}></Box>
                </Box>
              ))}
            </>
          ))}
          <Box>
            <Box display={'flex'} alignItems={'center'} mt={4}>
              <Box display={'flex'} justifyContent={'left'}>
                <Typography variant={'h5'}>
                  <strong>
                    Designer Feedback Section: Were there any limitations encountered during the
                    case planning?
                  </strong>
                </Typography>
              </Box>
            </Box>

            <>
              <Box display={'flex'} alignItems={'center'} my={2} gap={1}>
                <Box display={'flex'} width={240} justifyContent={'left'}>
                  <Typography variant={'body1'}>Select All That Apply</Typography>
                </Box>
                <Box display={'flex'} width={650} justifyContent={'left'}>
                  <SelectFieldRHF
                    name={'planFeedback'}
                    control={control as unknown as Control<FieldValues>}
                    label={''}
                    hideNone={true}
                    multiple={true}
                    menuItems={planFeedbackTypeMenuItems}
                    disabled={disabled}
                    onChange={handleFeedbackChange}
                  />
                </Box>
                <Box display={'flex'} width={240} justifyContent={'left'}>
                  <ErrorMessageRHF
                    name={'planFeedback'}
                    control={control as unknown as Control<FieldValues>}
                  />
                </Box>
              </Box>
              {tem13FormData?.planFeedback?.includes(PlanFeedbackType.Other) ? (
                <Box display={'flex'} alignItems={'center'} my={2} gap={1}>
                  <Box display={'flex'} width={240} justifyContent={'left'}>
                    <Typography variant={'body1'}>Please Describe</Typography>
                  </Box>
                  <Box display={'flex'} width={650} justifyContent={'left'}>
                    <TextFieldRHF
                      name={'planFeedbackOther'}
                      control={control as unknown as Control<FieldValues>}
                      label={''}
                      fullWidth={true}
                      multiline={true}
                      disabled={disabled}
                      shrink={false}
                    />
                  </Box>
                  <Box display={'flex'} width={240} justifyContent={'left'}>
                    <ErrorMessageRHF
                      name={'planFeedbackOther'}
                      control={control as unknown as Control<FieldValues>}
                    />
                  </Box>
                </Box>
              ) : null}
            </>
          </Box>

          {hasFlaggedGuardrails && showMedicalReviewSection && (
            <>
              <Box display={'flex'} alignItems={'center'} mt={4}>
                <Box display={'flex'} justifyContent={'left'}>
                  <Typography variant={'h5'}>
                    <strong>Medical Review Section</strong>
                  </Typography>
                </Box>
              </Box>

              {tem13FormData?.medicalReviewNote && (
                <>
                  <Box display={'flex'} alignItems={'flex-start'} my={2} gap={1}>
                    <Box display={'flex'} width={240} justifyContent={'left'}>
                      <Typography variant={'body1'}>Medical Review Note</Typography>
                    </Box>
                    <Box
                      display={'flex'}
                      width={650}
                      justifyContent={'left'}
                      flexDirection="column"
                    >
                      <Typography variant={'body1'}>{tem13FormData?.medicalReviewNote}</Typography>
                      {[
                        MedicalReviewStatusType.Approved,
                        MedicalReviewStatusType.Rejected,
                      ].includes(tem13FormData?.medicalReviewStatus as MedicalReviewStatusType) && (
                        <>
                          <br />
                          <Typography variant={'body1'} mt={1}>
                            {format.formatMedicalReviewStatusType(
                              tem13FormData?.medicalReviewStatus as MedicalReviewStatusType,
                            )}{' '}
                            by {format.formatName(tem13FormData?.medicalReviewedByUser)} on{' '}
                            {format.formatDate(tem13FormData?.medicalReviewedAt as any)} at{' '}
                            {format.formatTime(tem13FormData?.medicalReviewedAt as any)}.
                          </Typography>
                        </>
                      )}
                    </Box>
                    <Box display={'flex'} width={240} justifyContent={'left'}></Box>
                  </Box>
                  <Divider />
                </>
              )}
              {props.sendMedicalReviewEnabled && (
                <Box display={'flex'} alignItems={'flex-start'} my={2} gap={1}>
                  <Box display={'flex'} width={240} justifyContent={'left'}>
                    <Typography variant={'body1'}>Issues Found</Typography>
                  </Box>
                  <Box display={'flex'} width={650} flexDirection="column">
                    <Box>
                      <Typography variant={'body1'}>
                        Some issues have been identified in the correction plan due to guardrail
                        rules. Please submit a medical review request to the clinical team by
                        clicking the 'Send for Medical Review' button.
                      </Typography>
                    </Box>
                    <Box display={'flex'} justifyContent="flex-end" mt={1}>
                      <ProgressButton
                        variant={'contained'}
                        disabled={false}
                        onClick={props.handleFormSubmit(FormActionType.SendMedicalReviewRequest)}
                        label={'Send for Medical Review'}
                        loading={
                          formState.isSubmitting &&
                          props.activeButton === FormActionType.SendMedicalReviewRequest
                        }
                      />
                    </Box>
                  </Box>
                  <Box display={'flex'} width={240} />
                </Box>
              )}
            </>
          )}
          <Box display={'flex'} alignItems={'center'} mt={4}>
            <Box display={'flex'} justifyContent={'left'}>
              <Typography variant={'h5'}>
                <strong>Risk Assessment Section</strong>
              </Typography>
            </Box>
          </Box>
          <Box display={'flex'} alignItems={'center'} my={2} gap={1}>
            <Box display={'flex'} width={240} justifyContent={'left'}>
              <Typography variant={'body1'}>Risk Assessment</Typography>
            </Box>
            <Box display={'flex'} width={650} justifyContent={'left'}>
              <RadioGroupRHF
                name={'riskAssessment'}
                control={control as unknown as Control<FieldValues>}
                label={''}
                hideLabel={false}
                disabled={
                  props.disabled ||
                  (tem13FormData?.initialCaseRiskAssessment ===
                    CaseRiskAssessmentType.CaseReviewQaReview &&
                    tem13FormData?.riskAssessment === CaseRiskAssessmentType.CaseReviewQaReview)
                }
                row={false}
                options={caseRiskAssessmentTypeOptions}
                onChange={handleChangeRiskAssessment}
              />
            </Box>
            <Box display={'flex'} width={240} justifyContent={'left'}></Box>
          </Box>
          <Box display={'flex'} alignItems={'center'} my={2} gap={1}>
            <Box display={'flex'} width={240} justifyContent={'left'}>
              <Typography variant={'body1'}>Case Report Reviewer</Typography>
            </Box>
            <Box display={'flex'} width={650} justifyContent={'left'}>
              <SelectFieldRHF
                name={'reportReviewedBy'}
                control={control as unknown as Control<FieldValues>}
                label={''}
                hideNone={false}
                menuItems={caseReportReviewers}
                disabled={disabled || disablePeerReviewer}
              />
            </Box>
            <Box display={'flex'} width={240} justifyContent={'left'}>
              <ErrorMessageRHF
                name={'reportReviewedBy'}
                control={control as unknown as Control<FieldValues>}
              />
            </Box>
          </Box>

          <Box display={'flex'} alignItems={'center'} mt={4}>
            <Box display={'flex'} justifyContent={'left'}>
              <Typography variant={'h5'}>
                <strong>Case Report Review Section</strong>
              </Typography>
            </Box>
          </Box>
          {tem13FormData?.riskAssessment === CaseRiskAssessmentType.CaseReviewQaReview &&
          showCaseReportReviewSection ? (
            <>
              {tem13FormData?.reportReviewNote && (
                <>
                  <Box display={'flex'} alignItems={'flex-start'} my={2} gap={1}>
                    <Box display={'flex'} width={240} justifyContent={'left'}>
                      <Typography variant={'body1'}>Report Review Note</Typography>
                    </Box>
                    <Box
                      display={'flex'}
                      width={650}
                      justifyContent={'left'}
                      flexDirection="column"
                    >
                      <Typography variant={'body1'}>{tem13FormData?.reportReviewNote}</Typography>
                      {[CaseReportStatusType.Approved, CaseReportStatusType.Rejected].includes(
                        tem13FormData?.status as CaseReportStatusType,
                      ) && (
                        <>
                          <br />
                          <Typography variant={'body1'} mt={1}>
                            {format.formatCaseReportStatusType(
                              tem13FormData?.status as CaseReportStatusType,
                            )}{' '}
                            by {format.formatName(tem13FormData?.reportReviewedByUser)} on{' '}
                            {format.formatDate(tem13FormData?.reportReviewedAt as any)} at{' '}
                            {format.formatTime(tem13FormData?.reportReviewedAt as any)}.
                          </Typography>
                        </>
                      )}
                    </Box>
                    <Box display={'flex'} width={240} justifyContent={'left'}></Box>
                  </Box>
                  <Divider variant={'middle'} />
                </>
              )}
              {props.sendCaseReportReviewEnabled && (
                <Box display={'flex'} alignItems={'flex-start'} my={2} gap={1}>
                  <Box display={'flex'} width={240} justifyContent={'left'}>
                    <Typography variant={'body1'}>Report Review</Typography>
                  </Box>
                  <Box display={'flex'} width={650} flexDirection="column">
                    <Box>
                      <Typography variant={'body1'}>
                        Based on the risk assessment matrix, a case report review is required.
                        Please submit a case report review request to the case report reviewer by
                        clicking the 'Send for Case Report Review' button.
                      </Typography>
                    </Box>
                    <Box display={'flex'} justifyContent="flex-end" mt={1}>
                      <ProgressButton
                        variant={'contained'}
                        disabled={false}
                        onClick={props.handleFormSubmit(FormActionType.SendCaseReportReviewRequest)}
                        label={'Send for Case Report Review'}
                        loading={
                          formState.isSubmitting &&
                          props.activeButton === FormActionType.SendCaseReportReviewRequest
                        }
                      />
                    </Box>
                  </Box>
                  <Box display={'flex'} width={240} />
                </Box>
              )}
            </>
          ) : (
            props.selfApproveCaseReportEnabled && (
              <Box display={'flex'} alignItems={'flex-start'} my={2} gap={1}>
                <Box display={'flex'} width={240} justifyContent={'left'}>
                  <Typography variant={'body1'}>Report Review</Typography>
                </Box>
                <Box display={'flex'} width={650} flexDirection="column">
                  <Box>
                    <Typography variant={'body1'}>
                      Based on the risk assessment matrix, a case report review is not required.
                      Please approve the case report by clicking the 'Approve Case Report' button.
                    </Typography>
                  </Box>
                  <Box display={'flex'} justifyContent="flex-end" mt={1}>
                    <ProgressButton
                      variant={'contained'}
                      disabled={false}
                      onClick={props.handleFormSubmit(FormActionType.ApproveCaseReportReview)}
                      label={'Approve Case Report'}
                      loading={
                        formState.isSubmitting &&
                        props.activeButton === FormActionType.ApproveCaseReportReview
                      }
                    />
                  </Box>
                </Box>
                <Box display={'flex'} width={240} />
              </Box>
            )
          )}
        </Box>
      </Box>
    </Box>
  );
}

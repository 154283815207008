import * as pdfMake from 'pdfmake/build/pdfmake.js';
import {
  format,
  ImplantType,
  NavigationSystemType,
  ISurgeonPreferences,
  ISurgeonPreferenceImplant,
  ISurgeonPreferenceImplantMeasurement,
  ImplantDirection,
  ImplantOrientation,
  ICasePathology,
  caseUtils,
  ThreadType,
  LordosisDifferenceRationaleType,
  ICaseLevel,
  SegmentationImagingAssessmentType,
} from '@workflow-nx/common';
import { date } from '@workflow-nx/utils';

import {
  Tem13FillDialogStateType,
  CaseReportCorrectionPlanRuleType,
  ProposedLumbarMeasurementType,
  LumbarMeasurementType,
  CaseEncodedToBase64ImageType,
  EncodedAssetLevelType,
} from '../../../../apps/workflow-client/src/app/views/cases/CaseView/CasePlanningTab/Tem13FillDialog/Tem13FillDialog.reducer';

import * as vfsFonts from 'pdfmake/build/vfs_fonts.js';
import { sortBy } from 'lodash';
import {
  companyLogoImageDataUrl,
  inputImageGoldDataUrl,
  inputImageSilverDataUrl,
  inputImageBronzeDataUrl,
  planCoronalImage,
  planLateralImage,
  preOpCoronalImage,
  preOpLateralImage,
} from './tem13';
import { toNumber, isNumber } from 'lodash';

export const tem13Report = async (temp13Data: Tem13FillDialogStateType): Promise<Blob> => {
  const colors = {
    black: 'black',
    blue: 'blue',
    green: 'green',
    orange: 'orange',
    red: 'red',
    white: 'white',
    yellow: 'yellow',
    lightGray: '#d9d9d9',
    lightGreen: '#e2efda',
    lightOrange: '#f8cbad',
    lightYellow: '#fff2cc',
    poloBlue: '#8ea9db',
  };

  const pageBreak = { text: '', pageBreak: 'before', style: 'subheader' };
  const docTtitle = 'TEM-013';
  const docDescription = 'Case Report Template';
  const temVersion = '4';
  const docVersion = '1';

  const preferences = temp13Data?.preferences;
  const tem13CaseData = temp13Data?.tem13CaseData;
  const validCaseLevels = caseUtils.getValidCaseLevelRecords(tem13CaseData?.caseLevels ?? []);
  const casePathologies = temp13Data?.casePathologies as unknown as ICasePathology[];
  const caseReportAlignmentGoalTypes = temp13Data?.caseReportAlignmentGoalTypes ?? [];
  const patientRecords = temp13Data?.patientRecord;
  const lumbarLordosisCt = isNumber(temp13Data?.preOp?.lumbarLordosis)
    ? toNumber(temp13Data?.preOp?.lumbarLordosis.toFixed(0))
    : undefined;
  const planRules: CaseReportCorrectionPlanRuleType[] =
    temp13Data?.caseReportCorrectionPlanRules ?? [];
  const preOpLumbarMeasurements = temp13Data?.proposedLumbarMeasurements.preOpLumbarMeasurements;
  const planLumbarMeasurements = temp13Data?.proposedLumbarMeasurements.planLumbarMeasurements;
  const caseEncodedToBase64Images: CaseEncodedToBase64ImageType =
    temp13Data.caseEncodedToBase64Images;
  const encodedAssetLevels = temp13Data.caseEncodedToBase64Images.encodedAssetLevels ?? [];

  const getSegmentationImagingAssessment = (
    segmentationImagingAssessment: SegmentationImagingAssessmentType,
  ): string => {
    switch (segmentationImagingAssessment) {
      case SegmentationImagingAssessmentType.GoldCtFullLengthXrayWithFemoralHeads:
        return inputImageGoldDataUrl;
      case SegmentationImagingAssessmentType.SilverCtStandardLumbarXrayWithFemoralHeads:
        return inputImageSilverDataUrl;
      case SegmentationImagingAssessmentType.BronzeCtStandardLumbarXrayWithoutFemoralHeads:
      case SegmentationImagingAssessmentType.BronzeCtNoXrayOrUnusableXray:
        return inputImageBronzeDataUrl;
      default:
        return '';
    }
  };
  const segmentationImagingAssessmentEncode = getSegmentationImagingAssessment(
    temp13Data?.segmentationImagingAssessment,
  );

  const drawTem13PageHeader = () => {
    return {
      style: 'table',
      table: {
        widths: [100, '*', 100],
        body: [
          [
            {
              image: companyLogoImageDataUrl,
              fit: [100, 100],
            },
            {
              text: 'Template',
              style: 'tableHeader',
              colSpan: 2,
              alignment: 'center',
            },
            {},
          ],
          [
            {
              text: 'Document Title',
              style: 'tableRow',
              alignment: 'center',
            },
            {
              text: 'Document Description',
              style: 'tableRow',
              alignment: 'center',
            },
            {
              text: 'Template Version',
              style: 'tableRow',
              alignment: 'center',
            },
          ],
          [
            {
              text: 'TEM-013',
              style: 'tableHeader',
              alignment: 'center',
            },
            {
              text: 'Case Report Template',
              style: 'tableHeader',
              alignment: 'center',
            },
            {
              text: '4',
              style: 'tableHeader',
              alignment: 'center',
            },
          ],
          [
            {
              text: '\u00A0',
              style: 'tableHeader',
              colSpan: 3,
              alignment: 'center',
              fillColor: colors.lightGray,
            },
            {},
            {},
          ],
          [
            {
              text: 'Document Version: 0',
              style: 'tableHeader',
              colSpan: 3,
              alignment: 'center',
            },
            {},
            {},
          ],
        ],
      },
    };
  };

  const drawTem13PageFooter = (currentPage: number, pageCount: number) => {
    return {
      style: 'table',
      table: {
        widths: [130, '*', 80],
        heights: [30],
        body: [
          [
            {
              text: 'COMPANY CONFIDENTIAL',
              style: 'tableRow',
              alignment: 'center',
            },
            {
              text: 'Once printed, this document becomes UNCONTROLLED\nPlease verify latest revision before each use',
              style: 'tableRow',
              alignment: 'center',
            },
            {
              text: currentPage.toString() + ' of ' + pageCount,
              alignment: 'center',
              //margin: [0, 0, 20, 20],
            },
          ],
        ],
      },
    };
  };

  const drawPageBreak = () => {
    return { text: '', pageBreak: 'before', style: 'subheader' };
  };

  const printSectionTitle = (sectionTitle: string) => {
    return { text: sectionTitle, style: 'sectionTitle' };
  };

  function getTem13RevisionHistoryTable() {
    const body = [
      [
        {
          text: `CASE REPORT REVISION HISTORY`,
          colSpan: 3,
          alignment: 'center',
          fillColor: colors.poloBlue,
        },
        {},
        {},
      ],
      [
        { text: 'Version', style: 'tableHeader2' },
        { text: 'Description of Change', style: 'tableHeader2' },
        { text: 'Section', style: 'tableHeader2' },
      ],
    ];

    const row = [
      { text: '0', style: 'tableRow' },
      { text: 'Initial Release', style: 'tableRow' },
      { text: 'NA', style: 'tableRow4' },
    ];
    body.push(row);
    return {
      style: 'table',
      table: {
        headerRows: 1,
        widths: [100, '*', 100],
        body: body,
      },
    };
  }

  function drawTem13CaseHeaderTable() {
    const body = [
      [
        { text: 'CASE ID / LOT CODE', style: 'tableHeader4' },
        { text: 'SURGEON', style: 'tableHeader4' },
        { text: 'DESIGNER', style: 'tableHeader4' },
        { text: 'SALES', style: 'tableHeader4' },
      ],
      [
        { text: tem13CaseData?.caseNumber, style: 'tableRow' },
        {
          text: tem13CaseData?.surgeonUser ? format.formatName(tem13CaseData?.surgeonUser) : '—',
          style: 'tableRow',
        },
        {
          text: tem13CaseData?.assignedUser ? format.formatName(tem13CaseData?.assignedUser) : '—',
          style: 'tableRow',
        },
        {
          text: tem13CaseData?.fieldRepUser ? format.formatName(tem13CaseData?.fieldRepUser) : '—',
          style: 'tableRow',
        },
      ],
      [
        { text: 'CASE RISK ASSESSMENT ', style: 'tableHeader4' },
        { text: 'PATIENT AGE', style: 'tableHeader4' },
        { text: 'PATIENT GENDER', style: 'tableHeader4' },
        { text: 'PATIENT MRN', style: 'tableHeader4' },
      ],
      [
        {
          text: temp13Data?.riskAssessment
            ? format.formatCaseRiskAssessmentType(temp13Data?.riskAssessment)
            : 'N/A',
          style: 'tableRow',
        },
        {
          text: `${date.distanceInYears(
            tem13CaseData.patientBirthDate as unknown as string,
          )} Years`,
          style: 'tableRow',
        },
        {
          text: format.formatGender(tem13CaseData.patientGender as unknown as 'MALE' | 'FEMALE'),
          style: 'tableRow',
        },
        { text: tem13CaseData?.mrn ? tem13CaseData.mrn : '—', style: 'tableRow' },
      ],
    ];

    return {
      style: 'table',
      table: {
        headerRows: 1,
        widths: ['*', '*', '*', '*'],
        body: body,
      },
    };
  }

  function drawTem13InterbodyLevelsTable() {
    const body = [
      [
        { text: 'Level', style: 'tableHeader4' },
        { text: 'Implant Type', style: 'tableHeader4' },
        { text: 'Direction', style: 'tableHeader4' },
        { text: 'Orientation', style: 'tableHeader4' },
        { text: 'Thread Type', style: 'tableHeader4' },
        { text: 'Threaded Holes', style: 'tableHeader4' },
      ],
    ];

    for (const caseLevel of validCaseLevels) {
      const row = [
        { text: format.formatLevelType(caseLevel.level), style: 'tableRow' },
        { text: format.formatImplantTypes(caseLevel.implantType), style: 'tableRow' },
        {
          text: caseLevel?.implantDirection
            ? format.formatImplantDirection(caseLevel?.implantDirection)
            : 'N/A',
          style: 'tableRow',
        },
        {
          text: caseLevel?.implantOrientation
            ? format.formatImplantOrientation(caseLevel?.implantOrientation)
            : 'N/A',
          style: 'tableRow',
        },
        {
          text: caseLevel?.threadType
            ? caseLevel?.threadType === ThreadType.M2_2
              ? 'M2.2'
              : caseLevel?.threadType
            : 'N/A',
          style: 'tableRow',
        },
        {
          text: caseLevel?.threadedHoles ? caseLevel?.threadedHoles.toString() : 'N/A',
          style: 'tableRow',
        },
      ];
      body.push(row);
    }
    return {
      style: 'table',
      table: {
        headerRows: 1,
        widths: ['*', '*', '*', '*', '*', '*'],
        body: body,
      },
    };
  }

  function drawCaseImagingAssessmentTable() {
    const body = [
      [
        {
          text: `CASE IMAGING ASSESSMENT`,
          colSpan: 3,
          alignment: 'center',
          fillColor: colors.poloBlue,
        },
        {},
        {},
      ],
      [
        { text: 'Selected Image', style: 'tableHeader4' },
        { text: 'Description', style: 'tableHeader4' },
        { text: 'Imaging Date', style: 'tableHeader4' },
      ],
      [
        {
          image: segmentationImagingAssessmentEncode,
          fit: [100, 100],
        },
        {
          text: format.formatSegmentationImagingAssessmentType(
            temp13Data?.segmentationImagingAssessment,
          ),
          style: 'tableRow',
        },
        { text: '03/01/2024', style: 'tableRow' },
      ],
    ];

    return {
      style: 'table',
      table: {
        headerRows: 1,
        widths: [100, '*', 100],
        body: body,
      },
    };
  }

  function displaySurgeonPreferences() {
    const alert = displayAlert();
  }

  function displayAlert() {
    let alertRow;
    if (preferences) {
      const updatedAlertText = `Last updated by: ${format.formatName(
        preferences?.updatedByUser,
      )} at ${format.formatDateTime(preferences?.updatedAt)}`;
      alertRow = { text: updatedAlertText, style: 'tableHeader3', alignment: 'left' };
    } else {
      alertRow = {
        text: `The surgeon's preferences have not been set up yet.`,
        style: 'tableHeader2',
        alignment: 'left',
      };
    }

    const body = [[alertRow]];

    return {
      style: 'table',
      table: {
        headerRows: 0,
        widths: ['*'],
        body: body,
      },
    };
  }

  function drawSurgeonPreferencesMainTable() {
    const displayPreferredProcedures =
      preferences?.preferredProcedures?.length === 0
        ? '—'
        : (preferences?.preferredProcedures as ImplantType[])
            .map(format.formatImplantTypes)
            .join(', ');

    const displaySagittalGoalPrimary = preferences?.sagittalGoalPrimary
      ? format.formatSagittalGoalType(preferences?.sagittalGoalPrimary)
      : '—';

    const displayCoronalGoalPrimary = preferences?.coronalGoalPrimary
      ? format.formatCoronalGoalType(preferences?.coronalGoalPrimary)
      : '—';
    const displayTargetHeightMetric = preferences?.targetHeightMetric
      ? format.formatTargetHeightMetricType(preferences?.targetHeightMetric)
      : '—';

    const body = [
      [
        { text: 'Preferred Procedures', style: 'tableColumn1' },
        { text: displayPreferredProcedures, style: 'tableColumn2' },
      ],
      [
        { text: 'Sagittal Goal Primary', style: 'tableColumn1' },
        { text: displaySagittalGoalPrimary, style: 'tableColumn2' },
      ],
    ];
    if (preferences?.sagittalGoalSecondary) {
      const sagittalGoalSecondaryRow = [
        { text: 'Sagittal Goal Secondary', style: 'tableColumn1' },
        {
          text: format.formatSagittalGoalType(preferences?.sagittalGoalSecondary),
          style: 'tableColumn2',
        },
      ];
      body.push(sagittalGoalSecondaryRow);
    }
    if (preferences?.sagittalGoalOther) {
      const sagittalGoalOtherRow = [
        { text: 'Sagittal Goal Other', style: 'tableColumn1' },
        {
          text: preferences?.sagittalGoalOther,
          style: 'tableColumn2',
        },
      ];
      body.push(sagittalGoalOtherRow);
    }

    const coronalGoalPrimaryRow = [
      { text: 'Coronal Goal Primary', style: 'tableColumn1' },
      {
        text: displayCoronalGoalPrimary,
        style: 'tableColumn2',
      },
    ];
    body.push(coronalGoalPrimaryRow);
    if (preferences?.coronalGoalSecondary) {
      const coronalGoalSecondaryRow = [
        { text: 'Coronal Goal Secondary', style: 'tableColumn1' },
        {
          text: format.formatCoronalGoalType(preferences?.coronalGoalSecondary),
          style: 'tableColumn2',
        },
      ];
      body.push(coronalGoalSecondaryRow);
    }
    if (preferences?.navigationSystems?.length) {
      const displayNavigationSystems = (preferences?.navigationSystems as NavigationSystemType[])
        .map(format.formatNavigationSystem)
        .join(', ');

      const navigationSystemsRow = [
        { text: 'Navigation Systems', style: 'tableColumn1' },
        {
          text: displayNavigationSystems,
          style: 'tableColumn2',
        },
      ];
      body.push(navigationSystemsRow);
    }
    if (preferences?.navigationSystemOther) {
      const navigationSystemOtherRow = [
        { text: 'Navigation System Other', style: 'tableColumn1' },
        {
          text: preferences?.navigationSystemOther,
          style: 'tableColumn2',
        },
      ];
      body.push(navigationSystemOtherRow);
    }
    const targetHeightMetricRow = [
      { text: 'Target Height Metric', style: 'tableColumn1' },
      {
        text: displayTargetHeightMetric,
        style: 'tableColumn2',
      },
    ];
    body.push(targetHeightMetricRow);

    if (preferences?.excludedInstruments?.length) {
      const displayExcludedInstruments = (preferences?.excludedInstruments as string[])
        .map(format.formatInstrumentSkuType)
        .join(', ');
      const excludedInstrumentsRow = [
        { text: 'Excluded Instruments', style: 'tableColumn1' },
        {
          text: displayExcludedInstruments,
          style: 'tableColumn2',
        },
      ];
      body.push(excludedInstrumentsRow);
    }

    if (preferences?.note) {
      const noteRow = [
        { text: 'General Note', style: 'tableColumn1' },
        {
          text: preferences?.note,
          style: 'tableColumn2',
        },
      ];
      body.push(noteRow);
    }

    return {
      style: 'table',
      table: {
        headerRows: 0,
        widths: [150, '*'],
        body: body,
      },
    };
  }

  function drawSurgeonPreferencesImplantTable(
    surgeonPreferenceImplants: ISurgeonPreferenceImplant[],
  ) {
    const preferenceImplantsTable: any[] = [];

    surgeonPreferenceImplants.forEach((selectedImplantType: ISurgeonPreferenceImplant) => {
      const body = [];

      if (selectedImplantType?.implantType) {
        const implantTypeRow = [
          {
            text: format.formatImplantTypes(selectedImplantType?.implantType),
            style: 'tableColumn1',
            colSpan: 2,
            fontSize: 12,
          },
          {},
        ];
        body.push(implantTypeRow);
      }

      if (selectedImplantType?.positioning) {
        const positioningRow = [
          { text: 'Position Distance', style: 'tableColumn1' },
          {
            text: format.formatPositionDistanceType(selectedImplantType?.positioning),
            style: 'tableColumn2',
          },
        ];
        body.push(positioningRow);
      }

      if (
        ![ImplantType.ALIF, ImplantType.ALIFX].includes(
          selectedImplantType?.implantType as ImplantType,
        )
      ) {
        const direction = format.formatImplantDirectionOrientation(
          selectedImplantType?.direction as ImplantDirection,
        );

        const directionRow = [
          { text: 'Direction', style: 'tableColumn1' },
          {
            text: direction,
            style: 'tableColumn2',
          },
        ];
        body.push(directionRow);
      }
      if ([ImplantType.TLIFC].includes(selectedImplantType?.implantType as ImplantType)) {
        const threadAngleRow = [
          { text: 'Thread Angle', style: 'tableColumn1' },
          {
            text: selectedImplantType
              ? format.formatImplantOrientation(
                  selectedImplantType?.orientation as ImplantOrientation,
                )
              : '',
            style: 'tableColumn2',
          },
        ];
        body.push(threadAngleRow);
      }

      if (selectedImplantType?.implantType === ImplantType.ALIFX) {
        const screwOrientationRow = [
          { text: 'Screw Orientation', style: 'tableColumn1' },
          {
            text: format.formatImplantOrientation(
              selectedImplantType?.orientation as ImplantOrientation,
            ),
            style: 'tableColumn2',
          },
        ];
        body.push(screwOrientationRow);

        if (selectedImplantType?.orientation === ImplantOrientation.LevelSpecific) {
          const levels = ['L3/L4', 'L4/L5', 'L5/S1'];
          const levelKeys = ['L3_L4', 'L4_L5', 'L5_S1'];

          levels.forEach((level, index) => {
            const levelRow = [
              { text: `${level}:`, style: 'tableColumn1' },
              {
                text: selectedImplantType?.orientationByLevels?.[levelKeys[index]]
                  ? format.formatImplantOrientation(
                      selectedImplantType?.orientationByLevels?.[
                        levelKeys[index]
                      ] as ImplantOrientation,
                    )
                  : '-',
                style: 'tableColumn2',
              },
            ];
            body.push(levelRow);
          });
        }

        const screwLengthText = selectedImplantType?.screwLength
          ? format.formatInterbodyScrewLength(selectedImplantType?.screwLength)
          : '—';

        const screwLengthRow = [
          { text: 'Screw Length', style: 'tableColumn1' },
          { text: screwLengthText, style: 'tableColumn2' },
        ];
        body.push(screwLengthRow);
      }

      if (
        [ImplantType.ALIF, ImplantType.ALIFX, ImplantType.LLIF].includes(
          selectedImplantType?.implantType as ImplantType,
        )
      ) {
        const maximizeFootprintRow = [
          { text: 'Maximize Footprint', style: 'tableColumn1' },
          { text: selectedImplantType?.maximizeFootprint ? 'Yes' : 'No', style: 'tableColumn2' },
        ];
        body.push(maximizeFootprintRow);
      }

      const plusSizeImplantText = selectedImplantType?.plusSizeImplant
        ? `+${selectedImplantType?.plusSizeImplant}mm`
        : '—';

      const plusSizeImplantRow = [
        { text: 'Plus Size Implant', style: 'tableColumn1' },
        { text: plusSizeImplantText, style: 'tableColumn2' },
      ];
      body.push(plusSizeImplantRow);

      const minimalHeightSpecificationRow = [
        { text: 'Minimal Height Specification', style: 'tableColumn1' },
        {
          text: selectedImplantType?.minimalHeightSpecification ? 'Yes' : 'No',
          style: 'tableColumn2',
        },
      ];
      body.push(minimalHeightSpecificationRow);

      if (selectedImplantType?.note) {
        const procedureNoteRow = [
          { text: 'Procedure Note:', style: 'tableColumn1' },
          { text: selectedImplantType?.note, style: 'tableColumn2' },
        ];
        body.push(procedureNoteRow);
      }

      const selectImplantMeasurements = preferences?.surgeonPreferenceImplantMeasurements.filter(
        (implantMeasurements: ISurgeonPreferenceImplantMeasurement) =>
          implantMeasurements.implantType === selectedImplantType.implantType,
      );

      const measurementTable = drawSurgeonPreferencesMeasurementTable(
        selectImplantMeasurements ?? [],
      );

      const combinedTables = [
        {
          style: 'table',
          table: {
            headerRows: 0,
            widths: [150, '*'],
            body: body,
          },
        },
        measurementTable,
      ];

      preferenceImplantsTable.push(combinedTables);
    });

    return preferenceImplantsTable;
  }

  function drawSurgeonPreferencesMeasurementTable(
    surgeonPreferenceImplantMeasurements: ISurgeonPreferenceImplantMeasurement[],
  ) {
    const body = [
      [
        { text: 'Measurement Type', style: 'tableHeader3' },
        { text: 'Min', style: 'tableHeader3' },
        { text: 'Max', style: 'tableHeader3' },
      ],
    ];

    surgeonPreferenceImplantMeasurements.forEach(
      (implantMeasurement: ISurgeonPreferenceImplantMeasurement) => {
        const measurementType = format.formatSurgeonPreferenceMeasurementType(
          implantMeasurement?.measurementType,
        );
        const min = implantMeasurement?.min;
        const max = implantMeasurement?.max;

        const implantMeasurementRow = [
          { text: measurementType, style: 'tableColumn2' },
          { text: min.toString(), style: 'tableColumn2' },
          { text: max.toString(), style: 'tableColumn2' },
        ];
        body.push(implantMeasurementRow);
      },
    );

    return {
      style: 'table',
      table: {
        headerRows: 0,
        widths: [150, 100, 100],
        body: body,
      },
    };
  }

  function drawCasePathologiesTable(casePathologies: ICasePathology[]) {
    const body = [
      [
        { text: 'Pathology', style: 'tableHeader3' },
        { text: 'Notes/Comments', style: 'tableHeader3' },
      ],
    ];

    casePathologies.forEach((casePathology: ICasePathology) => {
      const pathologyType = format.formatCasePathologyType(casePathology?.pathologyType);
      const pathologyNote = casePathology?.pathologyNote ? casePathology?.pathologyNote : '';

      const implantMeasurementRow = [
        { text: pathologyType, style: 'tableColumn2' },
        { text: pathologyNote, style: 'tableColumn2' },
      ];
      body.push(implantMeasurementRow);
    });

    return {
      style: 'table',
      table: {
        headerRows: 0,
        widths: [200, '*'],
        body: body,
      },
    };
  }

  function drawCaseCorrectionGoalsTable() {
    type CorrectionGoalTableCell = { text?: string; style?: string; colSpan?: number };
    type CorrectionGoalTableRow = CorrectionGoalTableCell[];

    const body: CorrectionGoalTableRow[] = [
      [{ text: 'Selected Correction Goal', style: 'tableHeader3', colSpan: 2 }, {}],
    ];

    if (temp13Data?.correctionGoalDiscHeightOnly) {
      body.push([
        { text: 'Disc Space Only', style: 'tableColumn2' },
        { text: 'Yes', style: 'tableColumn2' },
      ]);
    } else {
      body.push(
        [
          { text: 'Sagittal Correction', style: 'tableColumn2' },
          {
            text: temp13Data?.correctionGoalSagittal
              ? format.formatSagittalGoalType(temp13Data?.correctionGoalSagittal)
              : '—',
            style: 'tableColumn2',
          },
        ],
        [
          { text: 'Coronal Correction', style: 'tableColumn2' },
          {
            text: temp13Data?.correctionGoalCoronal
              ? format.formatCoronalGoalType(temp13Data?.correctionGoalCoronal)
              : '—',
            style: 'tableColumn2',
          },
        ],
      );
    }

    body.push([
      { text: 'Correction Notes', style: 'tableColumn2' },
      { text: temp13Data?.correctionGoalNote ?? '—', style: 'tableColumn2' },
    ]);

    return {
      style: 'table',
      table: {
        headerRows: 0,
        widths: [100, '*'],
        body: body,
      },
    };
  }

  function drawCorrectionPlanningTable() {
    type AlignmentGoalTableCell = { text: string; style?: string; colSpan?: number };
    type AlignmentGoalTableRow = AlignmentGoalTableCell[];

    const body: AlignmentGoalTableRow[] = [
      [
        { text: 'Parameter', style: 'tableHeader3' },
        { text: 'Alignment Goal', style: 'tableHeader3' },
        { text: 'Target Formula', style: 'tableHeader3' },
        { text: 'Calculation', style: 'tableHeader3' },
        { text: 'Correction Needed', style: 'tableHeader3' },
      ],
    ];

    caseReportAlignmentGoalTypes.forEach((goal) => {
      body.push([
        { text: goal.correctionParameter, style: 'tableColumn2' },
        { text: goal.alignmentGoal, style: 'tableColumn2' },
        { text: goal.targetFormula, style: 'tableColumn2' },
        { text: goal.calculation, style: 'tableColumn2' },
        { text: goal.correctionNeeded, style: 'tableColumn2' },
      ]);
    });

    return {
      style: 'table',
      table: {
        headerRows: 1,
        widths: [80, '*', '*', '*', '*'],
        body: body,
      },
    };
  }

  function drawPreOperativeXrayMeasurementsTable(imageData: string) {
    type XrayMeasurementTableCell = {
      text?: string;
      style?: string;
      image?: string;
      fit?: [number, number];
      colSpan?: number;
      rowSpan?: number;
      alignment?: string;
    };
    type XrayMeasurementTableRow = XrayMeasurementTableCell[];

    const body: XrayMeasurementTableRow[] = [
      [
        { text: 'Xray Image', style: 'tableHeader3' },
        { text: 'Measurement type', style: 'tableHeader3' },
        { text: 'Value', style: 'tableHeader3' },
      ],
    ];

    const patientRecordEntries: { measurementType: string; value: string }[] = [];

    if (temp13Data?.patientRecord) {
      patientRecordEntries.push(
        {
          measurementType: 'Pelvic Incidence (PI)°:',
          value: temp13Data.patientRecord.pelvicIncidence?.toString() ?? 'N/A',
        },
        {
          measurementType: 'Lumbar Lordosis (LL)°:',
          value: temp13Data.patientRecord.lumbarLordosis?.toString() ?? 'N/A',
        },
        {
          measurementType: 'Lumbar Lordosis CT (LL)°:',
          value: lumbarLordosisCt?.toString() ?? 'N/A',
        },
        {
          measurementType: 'Lumbar Coronal Angle°:',
          value: temp13Data.patientRecord.lumbarCoronalCobb?.toString() ?? 'N/A',
        },
        {
          measurementType: 'L4-S1 Lordotic Distribution %:',
          value: temp13Data.patientRecord.l4S1LordoticDistribution?.toString() ?? 'N/A',
        },
        {
          measurementType: 'Global Tilt (GT):',
          value: temp13Data.patientRecord.globalTilt?.toString() ?? 'N/A',
        },
        {
          measurementType: 'Pelvic Tilt (PT):',
          value: temp13Data.patientRecord.pelvicTilt?.toString() ?? 'N/A',
        },
        {
          measurementType: 'Sacral Slope (SS):',
          value: temp13Data.patientRecord.sacralSlope?.toString() ?? 'N/A',
        },
        {
          measurementType: 'C7- Sagittal Vertical Line:',
          value: temp13Data.patientRecord.c7SagittalVerticalLine?.toString() ?? 'N/A',
        },
        {
          measurementType: 'Sagittal Vertical Axis:',
          value: temp13Data.patientRecord.sagittalVerticalAxis?.toString() ?? 'N/A',
        },
        { measurementType: 'Measurement Note:', value: temp13Data?.measurementNote ?? '—' },
      );
    }
    if (temp13Data?.measurementLordosisDifference > 10) {
      patientRecordEntries.push(
        {
          measurementType: 'Difference Between CT LL and X-Ray LL:',
          value: temp13Data.measurementLordosisDifference.toString(),
        },
        {
          measurementType: 'Rationale for Difference Greater than 10°:',
          value: temp13Data.measurementLordosisDifferenceRationale
            .map((rationale) => `• ${format.formatLordosisDifferenceRationaleType(rationale)}`)
            .join('\n'),
        },
      );

      if (
        temp13Data.measurementLordosisDifferenceRationale.includes(
          LordosisDifferenceRationaleType.Other,
        ) &&
        temp13Data.measurementLordosisDifferenceRationaleNote
      ) {
        patientRecordEntries.push({
          measurementType: 'Rationale Note:',
          value: temp13Data.measurementLordosisDifferenceRationaleNote,
        });
      }
    }

    if (patientRecordEntries.length > 0) {
      body.push([
        {
          image: caseEncodedToBase64Images.encodedStandingXrayLateralMeasured,
          fit: [200, 200],
          rowSpan: patientRecordEntries.length,
          alignment: 'center',
        },
        { text: patientRecordEntries[0].measurementType, style: 'tableColumn2' },
        { text: patientRecordEntries[0].value, style: 'tableColumn2' },
      ]);

      for (let i = 1; i < patientRecordEntries.length; i++) {
        body.push([
          {},
          { text: patientRecordEntries[i].measurementType, style: 'tableColumn2' },
          { text: patientRecordEntries[i].value, style: 'tableColumn2' },
        ]);
      }
    }

    return {
      style: 'table',
      table: {
        headerRows: 1,
        widths: [200, 140, '*'],
        body: body,
      },
    };
  }

  function drawCaseCorrectionPlanRulesTable() {
    type PlanRuleTableCell = { text?: string; style?: string; colSpan?: number };
    type PlanRuleTableRow = PlanRuleTableCell[];

    const body: PlanRuleTableRow[] = [
      [
        { text: 'Guardrails Correction Plan', style: 'tableHeader3' },
        { text: 'Plan Value', style: 'tableHeader3' },
        { text: 'Pre-op', style: 'tableHeader3' },
        { text: 'Min', style: 'tableHeader3' },
        { text: 'Max', style: 'tableHeader3' },
        { text: 'Flagged', style: 'tableHeader3' },
        { text: 'Comments', style: 'tableHeader3' },
      ],
      [
        {
          text: 'If the Plan Value is less than Pre-Op or less than Min or greater than Max, then the guardrail is flagged.',
          colSpan: 7,
          style: 'tableHeader4',
        },
        {},
        {},
        {},
        {},
        {},
        {},
      ],
    ];

    if (planRules) {
      for (const rule of planRules) {
        const ruleType = `${
          rule.level ? format.formatLevelType(rule.level) : ''
        } ${format.formatCorrectionPlanRuleType(rule.ruleType)}`;
        const planValue = isNumber(rule.planValue) ? rule.planValue.toFixed(2)?.toString() : 'N/A';
        const preOp =
          rule.condition === '<PRE_OP'
            ? isNumber(rule.preOp)
              ? rule.preOp.toFixed(2)?.toString()
              : 'N/A'
            : 'N/A';
        const min = isNumber(rule.min) ? rule.min.toFixed(2)?.toString() : 'N/A';
        const max = isNumber(rule.max) ? rule.max.toFixed(2)?.toString() : 'N/A';
        const flagged = rule.flagged ? 'Yes' : 'No';
        const note = rule.note || '—';

        body.push([
          { text: ruleType, style: 'tableColumn2' },
          { text: planValue, style: 'tableColumn2' },
          { text: preOp, style: 'tableColumn2' },
          { text: min, style: 'tableColumn2' },
          { text: max, style: 'tableColumn2' },
          { text: flagged, style: 'tableColumn2' },
          { text: note, style: 'tableColumn2' },
        ]);
      }
    }

    return {
      style: 'table',
      table: {
        headerRows: 1,
        widths: [150, 30, 30, 30, 30, 40, '*'],
        body: body,
      },
    };
  }

  function drawCaseSegmentationTable() {
    type SegmentationTableCell = {
      text?: string;
      style?: string;
      colSpan?: number;
      rowSpan?: number;
    };
    type SegmentationTableCellTableRow = SegmentationTableCell[];

    const body: SegmentationTableCellTableRow[] = [
      [
        { text: 'SEGMENTATION GRADING', style: 'tableHeader3', colSpan: 2 },
        {},
        { text: 'If not segmented by a Qualified Operator:', style: 'tableHeader3' },
      ],
      [
        { text: 'Segmentation Type', style: 'tableColumn2' },
        {
          text: temp13Data.segmentationType
            ? format.formatSegmentationType(temp13Data.segmentationType)
            : 'N/A',
          style: 'tableColumn2',
        },
        {
          text: !temp13Data.segmentedByQualifiedOperator
            ? temp13Data.segmentationReviewedByUser
              ? `Segmentation Reviewer: ${format.formatName(temp13Data.segmentationReviewedByUser)}`
              : '—'
            : 'N/A',
          style: 'tableColumn2',
          rowSpan: 3,
        },
      ],
      [
        { text: 'Slice Thickness', style: 'tableColumn2' },
        {
          text: temp13Data.segmentationSliceThickness
            ? format.formatSegmentationSliceThicknessType(temp13Data.segmentationSliceThickness)
            : 'N/A',
          style: 'tableColumn2',
        },
        {},
      ],
      [
        { text: 'Did Segmentation Pass?', style: 'tableColumn2' },
        { text: temp13Data.isSegmentationPassed ? 'Yes' : 'No', style: 'tableColumn2' },
        {},
      ],
      [
        { text: 'Was segmentation performed by a Qualified Operator?', style: 'tableColumn2' },
        { text: temp13Data.segmentedByQualifiedOperator ? 'Yes' : 'No', style: 'tableColumn2' },
        {
          text: !temp13Data.segmentedByQualifiedOperator
            ? temp13Data.segmentationPerformedByUser
              ? `Segmentation Operator: ${format.formatName(
                  temp13Data.segmentationPerformedByUser,
                )}`
              : '—'
            : 'N/A',
          style: 'tableColumn2',
        },
      ],
    ];

    return {
      style: 'table',
      table: {
        headerRows: 1,
        widths: [150, 150, '*'],
        body: body,
      },
    };
  }

  function drawProposedCorrectionPlanTable(
    lumbarMeasurements: LumbarMeasurementType[],
    measurementType: 'PLAN' | 'PREOP',
    coronalImage?: string,
    lateralImage?: string,
  ) {
    type ProposedCorrectionPlanTableCell = {
      text?: string;
      image?: string;
      fit?: [number, number];
      style?: string;
      colSpan?: number;
      rowSpan?: number;
      alignment?: string;
      width?: number;
      border?: [boolean, boolean, boolean, boolean];
    };

    type ProposedCorrectionPlanTableRow = ProposedCorrectionPlanTableCell[];

    const body: ProposedCorrectionPlanTableRow[] = [
      [{ text: measurementType, style: 'tableHeader3', colSpan: 8 }, {}, {}, {}, {}, {}, {}, {}],
      [
        { text: 'CORONAL', style: 'tableHeader5' },
        { text: 'LATERAL', style: 'tableHeader5' },
        { text: 'LEVEL', style: 'tableHeader5' },
        { text: 'LORDOSIS (°)', style: 'tableHeader5' },
        { text: 'ANGLE TO S1 (°)', style: 'tableHeader5' },
        { text: 'CORONAL (°)', style: 'tableHeader5' },
        { text: 'H-ANT (mm)', style: 'tableHeader5' },
        { text: 'H-POST (mm)', style: 'tableHeader5' },
      ],
    ];

    const coronalImageCellBorder: [boolean, boolean, boolean, boolean] = [true, true, true, true];
    const lateralImageCellBorder: [boolean, boolean, boolean, boolean] = [true, true, true, true];
    lumbarMeasurements.forEach((measurement, index) => {
      let coronalImageColumn: ProposedCorrectionPlanTableCell;
      let leteralImageColumn: ProposedCorrectionPlanTableCell;

      if (index === 0) {
        coronalImageColumn = coronalImage
          ? {
              image: coronalImage,
              fit: [100, 100],
              rowSpan: lumbarMeasurements.length,
              alignment: 'center',
              border: coronalImageCellBorder,
            }
          : {
              text: 'Image not found',
              rowSpan: lumbarMeasurements.length,
              style: 'tableRow5',
              border: coronalImageCellBorder,
            };

        leteralImageColumn = lateralImage
          ? {
              image: lateralImage,
              fit: [100, 100],
              rowSpan: lumbarMeasurements.length,
              alignment: 'center',
              border: lateralImageCellBorder,
            }
          : {
              text: 'Image not found',
              rowSpan: lumbarMeasurements.length,
              style: 'tableRow5',
              border: lateralImageCellBorder,
            };
      } else {
        coronalImageColumn = {};
        leteralImageColumn = {};
      }
      const row: ProposedCorrectionPlanTableRow = [
        coronalImageColumn,
        leteralImageColumn,
        { text: format.formatLevelType(measurement.level), style: 'tableRow5' },
        { text: measurement.segmentalLumbarLordosis.toFixed(0), style: 'tableRow5' },
        { text: measurement.angleToS1.toFixed(0), style: 'tableRow5' },
        { text: measurement.segmentalCoronalAngle.toFixed(0), style: 'tableRow5' },
        { text: measurement.segmentalAnteriorHeight.toFixed(1), style: 'tableRow5' },
        { text: measurement.segmentalPosteriorHeight.toFixed(1), style: 'tableRow5' },
      ];
      body.push(row);
    });

    return {
      style: 'table',
      table: {
        headerRows: 2,
        widths: [105, 105, 30, 50, 60, 50, 50, 50],
        body: body,
      },
    };
  }

  function drawCaseImagesTable() {
    type TableCell = {
      text?: string;
      style?: string;
      colSpan?: number;
      rowSpan?: number;
      image?: string;
      alignment?: string;
      fit?: [number, number];
    };
    type TableRow = TableCell[];

    const body: TableRow[] = [
      [
        {
          text: 'Does cage footprint fit the patient anatomy, surgeon preferences or match standard stock sizes? Yes',
          style: 'tableColumn2',
        },
        { text: 'comments', style: 'tableColumn2' },
      ],
      [
        {
          text: 'Were vertebral bodies edited to enhance implant features during implant design? Yes',
          style: 'tableColumn2',
        },
        { text: 'comments', style: 'tableColumn2' },
      ],
      [
        { text: 'Image Before Editing', style: 'tableHeader3' },
        { text: 'Image After Editing', style: 'tableHeader3' },
      ],
      [
        {
          image: caseEncodedToBase64Images.encodedVertebraePreEditImage,
          fit: [200, 200],
          style: 'tableColumn2',
          alignment: 'center',
        },
        {
          image: caseEncodedToBase64Images.encodedVertebraePostEditImage,
          fit: [200, 200],
          style: 'tableColumn2',
          alignment: 'center',
        },
      ],
      [
        { text: 'Implant Images Before Editing', style: 'tableHeader3' },
        { text: 'Implant Images After Editing', style: 'tableHeader3' },
      ],
      [
        {
          image: caseEncodedToBase64Images.encodedImplantPreEditImage,
          fit: [200, 200],
          style: 'tableColumn2',
          alignment: 'center',
        },
        {
          image: caseEncodedToBase64Images.encodedImplantPostEditImage,
          fit: [200, 200],
          style: 'tableColumn2',
          alignment: 'center',
        },
      ],
    ];

    return {
      style: 'table',
      table: {
        headerRows: 1,
        widths: ['*', '*'],
        body: body,
      },
    };
  }

  function drawCaseLevelTables(
    validCaseLevels: ICaseLevel[],
    encodedAssetLevels: EncodedAssetLevelType[],
  ) {
    const sections: any[] = [];

    for (const caseLevel of validCaseLevels) {
      const { implantType } = caseLevel;

      if (['ALIF', 'LLIF', 'TLIF_O'].includes(implantType)) {
        sections.push(drawPageBreak());
        sections.push(drawTem13PageHeader());
        sections.push(
          printSectionTitle(
            '11. ALIF / LLIF / TLIF-O Implant Design [☐ N/A if not an ALIF / LLIF / TLIF-O case]',
          ),
        );

        sections.push(drawAlifLlifTlifOTable(caseLevel, encodedAssetLevels));
      } else if (implantType === 'TLIF_C') {
        sections.push(drawPageBreak());
        sections.push(drawTem13PageHeader());
        sections.push(printSectionTitle('12. TLIF-C IMPLANT DESIGN [☐ N/A if not a TLIF-C case]'));
        sections.push(drawTlifCTable(caseLevel, encodedAssetLevels));
      } else if (implantType === 'ALIF_X') {
        sections.push(drawAlifXTables(validCaseLevels, encodedAssetLevels));
      }
    }

    return sections;
  }

  function drawAlifLlifTlifOTable(
    caseLevel: ICaseLevel,
    encodedAssetLevels: EncodedAssetLevelType[],
  ) {
    const appImage = encodedAssetLevels.find(
      (asset) =>
        asset.level === caseLevel.level &&
        asset.assetType === `CASE_REPORT_${caseLevel.level}_IMPLANT_APP_IMAGE`,
    )?.encodedImage;

    const topViewImage = encodedAssetLevels.find(
      (asset) =>
        asset.level === caseLevel.level &&
        asset.assetType === `CASE_REPORT_${caseLevel.level}_IMPLANT_MINI_CHECK_TOP_IMAGE`,
    )?.encodedImage;

    const bottomViewImage = encodedAssetLevels.find(
      (asset) =>
        asset.level === caseLevel.level &&
        asset.assetType === `CASE_REPORT_${caseLevel.level}_IMPLANT_MINI_CHECK_BOTTOM_IMAGE`,
    )?.encodedImage;

    const formattedLevelPartType = `${format.formatLevelType(
      caseLevel.level,
    )} — ${format.formatPartType(caseLevel.partType)}`;

    const rows: any[] = [
      [
        { text: 'Implant', style: 'tableHeader4' },
        { text: 'App Image', style: 'tableHeader4', colSpan: 2 },
        {},
      ],
      [
        { text: formattedLevelPartType, style: 'tableColumn2', rowSpan: 3 },
        appImage
          ? {
              image: appImage,
              fit: [250, 250],
              style: 'tableColumn2',
              alignment: 'center',
              colSpan: 2,
            }
          : { text: 'N/A', style: 'tableColumn2', colSpan: 2 },
        {},
      ],
      [
        {},
        { text: 'Mini Check (Top View)', style: 'tableHeader3' },
        { text: 'Mini Check (Bottom View)', style: 'tableHeader3' },
      ],
      [
        {},
        topViewImage
          ? { image: topViewImage, fit: [130, 130], style: 'tableColumn2', alignment: 'center' }
          : { text: 'N/A', style: 'tableColumn2' },
        bottomViewImage
          ? { image: bottomViewImage, fit: [130, 130], style: 'tableColumn2', alignment: 'center' }
          : { text: 'N/A', style: 'tableColumn2' },
      ],
    ];

    return {
      style: 'table',
      table: {
        headerRows: 0,
        widths: [140, '*', '*'],
        body: rows,
      },
    };
  }

  function drawTlifCTable(caseLevel: ICaseLevel, encodedAssetLevels: EncodedAssetLevelType[]) {
    const appImage = encodedAssetLevels.find(
      (asset) =>
        asset.level === caseLevel.level &&
        asset.assetType === `CASE_REPORT_${caseLevel.level}_IMPLANT_APP_IMAGE`,
    )?.encodedImage;

    const positionImage = encodedAssetLevels.find(
      (asset) =>
        asset.level === caseLevel.level &&
        asset.assetType === `CASE_REPORT_${caseLevel.level}_IMPLANT_POSITION_IMAGE`,
    )?.encodedImage;

    const topViewImage = encodedAssetLevels.find(
      (asset) =>
        asset.level === caseLevel.level &&
        asset.assetType === `CASE_REPORT_${caseLevel.level}_IMPLANT_MINI_CHECK_TOP_IMAGE`,
    )?.encodedImage;

    const bottomViewImage = encodedAssetLevels.find(
      (asset) =>
        asset.level === caseLevel.level &&
        asset.assetType === `CASE_REPORT_${caseLevel.level}_IMPLANT_MINI_CHECK_BOTTOM_IMAGE`,
    )?.encodedImage;

    const formattedLevelPartType = `${format.formatLevelType(
      caseLevel.level,
    )} — ${format.formatPartType(caseLevel.partType)}`;

    const rows: any[] = [
      [
        { text: 'Implant', style: 'tableHeader4' },
        { text: 'App Image', style: 'tableHeader4', colSpan: 3 },
        {},
        {},
      ],
      [
        { text: formattedLevelPartType, style: 'tableColumn2', rowSpan: 3 },
        appImage
          ? {
              image: appImage,
              fit: [250, 250],
              style: 'tableColumn2',
              alignment: 'center',
              colSpan: 3,
            }
          : { text: 'N/A', style: 'tableColumn2', colSpan: 3 },
        {},
        {},
      ],
      [
        {},
        { text: 'Implant Position', style: 'tableHeader3' },
        { text: 'Mini Check (Top View)', style: 'tableHeader3' },
        { text: 'Mini Check (Bottom View)', style: 'tableHeader3' },
      ],
      [
        {},
        positionImage
          ? { image: positionImage, fit: [130, 130], style: 'tableColumn2', alignment: 'center' }
          : { text: 'N/A', style: 'tableColumn2' },
        topViewImage
          ? { image: topViewImage, fit: [130, 130], style: 'tableColumn2', alignment: 'center' }
          : { text: 'N/A', style: 'tableColumn2' },
        bottomViewImage
          ? { image: bottomViewImage, fit: [130, 130], style: 'tableColumn2', alignment: 'center' }
          : { text: 'N/A', style: 'tableColumn2' },
      ],
    ];

    return {
      style: 'table',
      table: {
        headerRows: 0,
        widths: [140, '*', '*', '*'],
        body: rows,
      },
    };
  }

  function drawAlifXTable(caseLevel: ICaseLevel, encodedAssetLevels: EncodedAssetLevelType[]) {
    const appImage = encodedAssetLevels.find(
      (asset) =>
        asset.level === caseLevel.level &&
        asset.assetType === `CASE_REPORT_${caseLevel.level}_IMPLANT_APP_IMAGE`,
    )?.encodedImage;

    const screwVerificationImage = encodedAssetLevels.find(
      (asset) =>
        asset.level === caseLevel.level &&
        asset.assetType === `CASE_REPORT_${caseLevel.level}_IMPLANT_SCREW_VERIFICATION_IMAGE`,
    )?.encodedImage;

    const topViewImage = encodedAssetLevels.find(
      (asset) =>
        asset.level === caseLevel.level &&
        asset.assetType === `CASE_REPORT_${caseLevel.level}_IMPLANT_MINI_CHECK_TOP_IMAGE`,
    )?.encodedImage;

    const bottomViewImage = encodedAssetLevels.find(
      (asset) =>
        asset.level === caseLevel.level &&
        asset.assetType === `CASE_REPORT_${caseLevel.level}_IMPLANT_MINI_CHECK_BOTTOM_IMAGE`,
    )?.encodedImage;

    const formattedLevelPartType = `${format.formatLevelType(
      caseLevel.level,
    )} — ${format.formatPartType(caseLevel.partType)}`;

    const rows: any[] = [
      [
        { text: 'Implant', style: 'tableHeader4' },
        { text: 'App Image', style: 'tableHeader4', colSpan: 3 },
        {},
        {},
      ],
      [
        { text: formattedLevelPartType, style: 'tableColumn2', rowSpan: 3 },
        appImage
          ? {
              image: appImage,
              fit: [250, 250],
              style: 'tableColumn2',
              alignment: 'center',
              colSpan: 3,
            }
          : { text: 'N/A', style: 'tableColumn2', colSpan: 3 },
        {},
        {},
      ],
      [
        {},
        { text: 'Screw Verification Image (Length, Placement, Trajectory)', style: 'tableHeader3' },
        { text: 'Mini Check (Top View)', style: 'tableHeader3' },
        { text: 'Mini Check (Bottom View)', style: 'tableHeader3' },
      ],
      [
        {},
        screwVerificationImage
          ? {
              image: screwVerificationImage,
              fit: [130, 130],
              style: 'tableColumn2',
              alignment: 'center',
            }
          : { text: 'N/A', style: 'tableColumn2' },
        topViewImage
          ? { image: topViewImage, fit: [130, 130], style: 'tableColumn2', alignment: 'center' }
          : { text: 'N/A', style: 'tableColumn2' },
        bottomViewImage
          ? { image: bottomViewImage, fit: [130, 130], style: 'tableColumn2', alignment: 'center' }
          : { text: 'N/A', style: 'tableColumn2' },
      ],
    ];

    return {
      style: 'table',
      table: {
        headerRows: 1,
        widths: [140, '*', '*', '*'],
        body: rows,
      },
    };
  }

  function drawAlifXEndTable(
    firstALIFXLevel: ICaseLevel,
    encodedAssetLevels: EncodedAssetLevelType[],
  ) {
    const anteriorPosteriorImage = encodedAssetLevels.find(
      (asset) =>
        asset.level === firstALIFXLevel.level &&
        asset.assetType ===
          `CASE_REPORT_${firstALIFXLevel.level}_IMPLANT_SCREW_ANTERIOR_POSTION_IMAGE`,
    )?.encodedImage;

    const lateralConstructImage = encodedAssetLevels.find(
      (asset) =>
        asset.level === firstALIFXLevel.level &&
        asset.assetType ===
          `CASE_REPORT_${firstALIFXLevel.level}_IMPLANT_SCREW_LATERAL_POSTION_IMAGE`,
    )?.encodedImage;

    const rows: any[] = [
      [
        {
          text: 'Anterior-Posterior Construct Image (all ALIF-X cages + previous hardware)',
          style: 'tableHeader3',
        },
        {
          text: 'Lateral Construct Image (all ALIF-X cages + previous hardware)',
          style: 'tableHeader3',
        },
      ],
      [
        anteriorPosteriorImage
          ? {
              image: anteriorPosteriorImage,
              fit: [200, 200],
              style: 'tableColumn2',
              alignment: 'center',
            }
          : { text: 'N/A', style: 'tableColumn2' },
        lateralConstructImage
          ? {
              image: lateralConstructImage,
              fit: [200, 200],
              style: 'tableColumn2',
              alignment: 'center',
            }
          : { text: 'N/A', style: 'tableColumn2' },
      ],
    ];

    return {
      style: 'table',
      table: {
        headerRows: 1,
        widths: ['*', '*'],
        body: rows,
      },
    };
  }

  function drawAlifXTables(
    validCaseLevels: ICaseLevel[],
    encodedAssetLevels: EncodedAssetLevelType[],
  ) {
    const content: any[] = [];

    const firstALIFXLevel = validCaseLevels.find(
      (level) => level.implantType === ImplantType.ALIFX,
    );

    if (firstALIFXLevel) {
      content.push(drawPageBreak());
      content.push(drawTem13PageHeader());
      content.push(printSectionTitle('13. ALIF-X IMPLANT DESIGN [☐ N/A if not an ALIF-X case]'));

      for (const caseLevel of validCaseLevels) {
        if (caseLevel.implantType === ImplantType.ALIFX) {
          content.push(drawAlifXTable(caseLevel, encodedAssetLevels));
        }
      }
      content.push(drawPageBreak());
      content.push(drawTem13PageHeader());
      content.push(drawAlifXEndTable(firstALIFXLevel, encodedAssetLevels));
    }

    return content;
  }

  function drawSignaturesTable(caseRiskAssessment: string) {
    const rows: any[] = [
      [
        { text: 'Action', style: 'tableHeader3' },
        { text: 'Name', style: 'tableHeader3' },
        { text: 'Date', style: 'tableHeader3' },
      ],
      [
        'Case Report Completed By (Author)',
        {
          text: tem13CaseData?.assignedUser ? format.formatName(tem13CaseData?.assignedUser) : '—',
          style: 'tableRow',
        },
        { text: new Date().toLocaleDateString('en-US'), alignment: 'center' },
      ],
      [
        'Case Review Completed By (Independent reviewer):',
        {
          text:
            caseRiskAssessment === 'QA_REVIEW_ONLY'
              ? 'N/A'
              : temp13Data?.reportReviewedByUser
              ? format.formatName(temp13Data?.reportReviewedByUser)
              : '—',
          style: 'tableRow',
        },
        caseRiskAssessment === 'QA_REVIEW_ONLY'
          ? 'N/A'
          : { text: new Date().toLocaleDateString('en-US'), alignment: 'center' },
      ],
    ];

    return {
      style: 'table',
      table: {
        headerRows: 1,
        widths: ['auto', '*', 'auto'],
        body: rows.map((row) => row.map((cell) => ({ text: cell, style: 'tableColumn2' }))),
      },
    };
  }

  const docDefinition: any = {
    header: { text: '4.0', alignment: 'right', margin: [0, 15, 20, 0] },
    footer: function (currentPage: number, pageCount: number) {
      return drawTem13PageFooter(currentPage, pageCount);
    },
    pageSize: 'LETTER',
    pageOrientation: 'portrait',
    pageMargins: [20, 50, 20, 50],
    content: [
      // { text: docTtitle, style: 'header' },
      // { text: docDescription, style: 'header2' },
      drawTem13PageHeader(),
      printSectionTitle('1.	CASE REPORT REVISION HISTORY'),
      getTem13RevisionHistoryTable(),
      printSectionTitle('2.	CASE INFORMATION'),
      drawTem13CaseHeaderTable(),
      drawTem13InterbodyLevelsTable(),
      drawPageBreak(),
      drawTem13PageHeader(),
      printSectionTitle('3.	CASE IMAGING INPUTS'),
      drawCaseImagingAssessmentTable(),
      printSectionTitle('4.	SURGEON PREFERENCES'),
      displayAlert(),
      drawSurgeonPreferencesMainTable(),
      drawSurgeonPreferencesImplantTable(preferences?.surgeonPreferenceImplants ?? []),
      drawPageBreak(),
      drawTem13PageHeader(),
      printSectionTitle('5.	SEGMENTATION'),
      drawCaseSegmentationTable(),
      printSectionTitle('6. PATIENT PATHOLOGIES PRESENT'),
      drawCasePathologiesTable(casePathologies),
      drawPageBreak(),
      drawTem13PageHeader(),
      printSectionTitle('7. PRE-OPERATIVE X-RAY MEASUREMENTS'),
      drawPreOperativeXrayMeasurementsTable(''),
      drawPageBreak(),
      drawTem13PageHeader(),
      printSectionTitle('8. CORRECTION GOALS'),
      drawCaseCorrectionGoalsTable(),
      drawPageBreak(),
      drawTem13PageHeader(),
      printSectionTitle('9. CORRECTION PLANNING'),
      drawCorrectionPlanningTable(),
      drawProposedCorrectionPlanTable(
        planLumbarMeasurements,
        'PLAN',
        planCoronalImage,
        planLateralImage,
      ),
      drawProposedCorrectionPlanTable(
        preOpLumbarMeasurements,
        'PREOP',
        preOpCoronalImage,
        preOpLateralImage,
      ),
      drawPageBreak(),
      drawTem13PageHeader(),
      drawCaseCorrectionPlanRulesTable(),
      drawPageBreak(),
      drawTem13PageHeader(),
      printSectionTitle('10. IMPLANT DESIGN'),
      drawCaseImagesTable(),
      drawCaseLevelTables(validCaseLevels, encodedAssetLevels),
      drawPageBreak(),
      drawTem13PageHeader(),
      printSectionTitle('14. CASE REPORT APPROVALS [Case Risk Assessment per SOP-033]'),
      drawSignaturesTable(temp13Data?.riskAssessment),
    ],
    styles: {
      header: {
        fontSize: 18,
        bold: true,
        margin: [0, 0, 0, 10],
        alignment: 'center',
      },
      header2: {
        fontSize: 14,
        bold: true,
        margin: [0, 0, 0, 10],
        alignment: 'center',
      },
      subheader: {
        fontSize: 16,
        bold: true,
        margin: [0, 10, 0, 5],
        alignment: 'center',
      },
      table: {
        margin: [0, 5, 0, 15],
      },
      tableOpacity: {
        margin: [0, 5, 0, 15],
        fillColor: colors.blue,
        fillOpacity: 0.3,
      },
      tableHeader: {
        bold: true,
        fontSize: 10,
        color: colors.black,
      },
      sectionTitle: {
        bold: true,
        fontSize: 10,
        color: colors.black,
        alignment: 'left',
      },
      tableHeader2: {
        bold: true,
        fontSize: 10,
        color: colors.black,
        fillColor: colors.lightYellow,
        alignment: 'center',
      },
      tableHeader3: {
        bold: true,
        fontSize: 10,
        color: colors.black,
        fillColor: colors.poloBlue,
        alignment: 'center',
      },
      tableHeader4: {
        bold: true,
        fontSize: 10,
        color: colors.black,
        fillColor: colors.lightGray,
        alignment: 'center',
      },
      tableHeader5: {
        bold: false,
        fontSize: 8,
        color: colors.black,
        fillColor: colors.poloBlue,
        alignment: 'center',
      },
      tableRow: {
        bold: false,
        fontSize: 10,
        color: colors.black,
        alignment: 'center',
      },
      tableRow2: {
        bold: false,
        fontSize: 9,
        color: colors.blue,
        fillColor: colors.lightGreen,
        alignment: 'right',
      },
      tableRow3: {
        bold: false,
        fontSize: 8,
        color: colors.black,
        alignment: 'left',
      },
      tableRow4: {
        bold: false,
        fontSize: 9,
        color: colors.black,
        alignment: 'left',
      },
      tableRow5: {
        bold: false,
        fontSize: 8,
        color: colors.black,
        alignment: 'center',
      },
      tableColumn1: {
        bold: true,
        fontSize: 10,
        color: colors.black,
        fillColor: colors.lightGray,
        alignment: 'left',
      },
      tableColumn2: {
        bold: false,
        fontSize: 10,
        color: colors.black,
        alignment: 'left',
      },
    },
    defaultStyle: {
      alignment: 'justify',
    },
  };

  return new Promise((resolve) => {
    const pdf = pdfMake.createPdf(
      docDefinition,
      undefined,
      undefined,
      vfsFonts.pdfMake ? vfsFonts.pdfMake.vfs : pdfMake.vfs,
    );
    pdf.getBlob((result: Blob) => {
      resolve(result);
    });
  });
};

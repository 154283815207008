import React from 'react';
import { StatementType, format } from '@workflow-nx/common';
import { Table, TableBody, TableHead, TableRow } from '@mui/material';
import { CaseReportStatementType } from './Tem13FillDialog.reducer';
import { TableHeadCell } from '../../../../../components/TableHeadCell';
import { CaseReportStatementTableRow } from './CaseReportStatementTableRow';

interface CaseReportStatementsTableProps {
  statementType: StatementType;
  caseReportStatements: CaseReportStatementType[];
  disabled: boolean;
}

export function CaseReportStatementsEditTable({
  statementType,
  caseReportStatements,
  disabled,
}: CaseReportStatementsTableProps) {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableHeadCell width={440}>Statement Response</TableHeadCell>
          <TableHeadCell width={460}>Note</TableHeadCell>
          <TableHeadCell width={240}></TableHeadCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {caseReportStatements?.map(
          (caseReportStatement: CaseReportStatementType, caseReportStatementIndex: number) => {
            return (
              caseReportStatement.statementType === statementType && (
                <CaseReportStatementTableRow
                  key={`${caseReportStatementIndex}`}
                  statementType={caseReportStatement?.statementType}
                  statement={caseReportStatement?.statement}
                  statementIndex={caseReportStatementIndex}
                  response={caseReportStatement?.response}
                  disabled={disabled}
                />
              )
            );
          },
        )}
      </TableBody>
    </Table>
  );
}
